<template>
    <div
        ref="button"
        class="pendo-slider__dot-wrapper"
        :class="{
            'is-hovering': isHovering,
            'is-dragging': isDragging,
            'pendo-slider__dot-wrapper--type-bar': isBarType
        }"
        :style="wrapperStyle"
        :tabindex="disabled ? -1 : 0"
        @mouseenter="onMouseenter"
        @mouseleave="onMouseleave"
        @mousedown="onMousedown"
        @keydown="onKeydown"
        @focus="onMouseenter"
        @blur="onMouseleave">
        <div
            v-pendo-tooltip="
                showTooltip && {
                    trigger: 'manual',
                    offset: 4,
                    arrow: true,
                    show: isTooltipVisible,
                    content: tooltipContent
                }
            "
            class="pendo-slider__dot"
            :class="{
                'is-hovering': isHovering,
                'is-dragging': isDragging,
                'pendo-slider__dot--type-bar': isBarType
            }">
            <div
                v-if="isBarType"
                class="pendo-slider__grip">
                <div class="pendo-slider__grip-mid" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import { keyCodes } from '@/utils/utils';

export default {
    name: 'SliderDot',
    directives: {
        PendoTooltip
    },
    props: {
        value: {
            type: null,
            required: true
        },
        disabled: {
            type: Boolean
        },
        formatTooltip: {
            type: Function
        },
        max: {
            type: Number
        },
        min: {
            type: Number
        },
        precision: {
            type: Number
        },
        step: {
            type: Number
        },
        showStops: {
            type: Boolean
        },
        showTooltip: {
            type: Boolean
        },
        sliderSize: {
            type: Number
        },
        vertical: {
            type: Boolean
        },
        type: {
            type: String
        }
    },
    data () {
        return {
            isTooltipVisible: false,
            isHovering: false,
            isDragging: false,
            isClick: false,
            startX: 0,
            currentX: 0,
            startY: 0,
            currentY: 0,
            startPosition: 0,
            newPosition: null,
            oldValue: this.value
        };
    },
    computed: {
        currentPosition () {
            return `${((this.value - this.min) / (this.max - this.min)) * 100}%`;
        },
        lengthPerStep () {
            return 100 / ((this.max - this.min) / this.step);
        },
        tooltipContent () {
            if (!this.showTooltip || this.value == null) {
                return false;
            }

            if (this.formatTooltip instanceof Function) {
                return this.formatTooltip(this.value).toString();
            }

            return parseFloat(this.value.toFixed(this.precision)).toString();
        },
        wrapperStyle () {
            return this.vertical
                ? {
                    bottom: this.currentPosition
                }
                : {
                    left: this.currentPosition
                };
        },
        isBarType () {
            return this.type === 'bar';
        }
    },
    methods: {
        onMouseenter () {
            this.isHovering = true;
            this.isTooltipVisible = true;
        },
        onMouseleave () {
            this.isHovering = false;
            this.isTooltipVisible = false;
        },
        onMousedown (event) {
            if (this.disabled) {
                return;
            }

            event.preventDefault();
            window.addEventListener('mouseup', this.onDragEnd);
            window.addEventListener('mouseleave', this.onDragEnd);
            window.addEventListener('mousemove', this.onDrag);

            this.onDragStart(event);
        },
        onKeydown (event) {
            if (this.disabled) {
                return;
            }

            const { end, home, left, up, right, down } = keyCodes;
            if ([end, home, left, up, right, down].includes(event.keyCode)) {
                event.preventDefault();
                this.startPosition = parseFloat(this.currentPosition);

                // set position relative to slider
                switch (event.keyCode) {
                    case up:
                    case right:
                        this.newPosition = this.startPosition + this.lengthPerStep;
                        break;
                    case down:
                    case left:
                        this.newPosition = this.startPosition - this.lengthPerStep;
                        break;
                    case home:
                        this.newPosition = 0;
                        break;
                    case end:
                        this.newPosition = 100;
                        break;
                }

                this.setPosition('keydown', this.newPosition);
            }
        },
        onDragStart (event) {
            this.isDragging = true;
            this.isClick = true;

            if (this.vertical) {
                this.startY = event.clientY;
            } else {
                this.startX = event.clientX;
            }

            this.startPosition = parseFloat(this.currentPosition);
            this.newPosition = this.startPosition;
            this.$emit('drag-start', this.startPosition);
        },
        onDrag (event) {
            if (this.isDragging) {
                this.isClick = false;
                this.isTooltipVisible = true;

                let diff = 0;

                if (this.vertical) {
                    this.currentY = event.clientY;
                    diff = ((this.startY - this.currentY) / this.sliderSize) * 100;
                } else {
                    this.currentX = event.clientX;
                    diff = ((this.currentX - this.startX) / this.sliderSize) * 100;
                }
                this.newPosition = this.startPosition + diff;
                this.setPosition('drag', this.newPosition);
            }
        },
        onDragEnd () {
            if (this.isDragging) {
                requestAnimationFrame(() => {
                    this.isDragging = false;
                    this.isTooltipVisible = false;
                    this.$refs.button.focus();
                    if (!this.isClick) {
                        this.setPosition('drag-end', this.newPosition);
                    } else {
                        this.$emit('drag-cancel');
                    }
                });

                window.removeEventListener('mousemove', this.onDrag);
                window.removeEventListener('mouseup', this.onDragEnd);
                window.removeEventListener('mouseleave', this.onDragEnd);
            }
        },
        setPosition (event, newPosition) {
            if (newPosition === null || isNaN(newPosition)) {
                return;
            }
            if (newPosition < 0) {
                newPosition = 0;
            } else if (newPosition > 100) {
                newPosition = 100;
            }
            let steps = newPosition / this.lengthPerStep;
            let value = steps * this.lengthPerStep * (this.max - this.min) * 0.01 + this.min;

            if (this.showStops) {
                steps = Math.round(newPosition / this.lengthPerStep);
                value = steps * this.lengthPerStep * (this.max - this.min) * 0.01 + this.min;
                value = parseFloat(value.toFixed(this.precision));
            }

            this.$emit(event, value);

            if (!this.isDragging && this.value !== this.oldValue) {
                this.oldValue = this.value;
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-slider) {
    @include element(dot-wrapper) {
        position: absolute;
        will-change: transform;
        transition: all 0s;
        z-index: 5;
        width: 14px;
        height: 14px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 0%;
        outline: none;

        &:hover,
        &.is-hovering {
            cursor: ew-resize;
        }

        &:focus-visible {
            @include element(dot) {
                @include focus-ring($style: 'focused');
            }
        }

        @include modifier(type-bar) {
            width: 18px;
            height: 40px;
            top: calc(50% + 1px);
        }

        @include is(dragging) {
            cursor: ew-resize;
        }
    }

    @include element(dot) {
        border: 1px solid $slider-border-color;
        position: absolute;
        cursor: ew-resize;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $color-white;
        box-sizing: border-box;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.19);
        @include focus-ring($style: 'base');

        &:hover,
        &.is-hovering {
            cursor: ew-resize;
        }

        @include is(dragging) {
            cursor: ew-resize;
        }

        @include modifier(type-bar) {
            .pendo-slider__dot-wrapper & {
                border-radius: 2px;
                box-shadow: none;
            }
        }
    }

    @include element(grip) {
        border-top: 2px solid $border-color-base;
        border-bottom: 2px solid $border-color-base;
        position: relative;
        height: 6px;
        width: 9px;
        top: 13px;
        left: 25%;
    }

    @include element(grip-mid) {
        border-top: 2px solid $border-color-base;
        margin-top: 2px;
        height: 1px;
    }
}
</style>
