<template>
    <div class="pendo-multiselect-group">
        <div
            v-for="(item, index) in model"
            :key="index"
            class="pendo-multiselect-group__item">
            <slot
                name="multiselect"
                v-bind="{
                    model,
                    index,
                    select,
                    availableOptions
                }">
                <pendo-multiselect
                    :value="model[index]"
                    full-width
                    :popper-options="{
                        class: `pendo-multiselect-group__item-menu-${index}`
                    }"
                    :disabled="disabled"
                    :options="availableOptions"
                    :labels="{ top: index === 0 && optionsLabel }"
                    v-bind="$attrs"
                    @select="select($event, index)">
                    <template #noData>
                        {{ allSelectedLabel }}
                    </template>
                </pendo-multiselect>
            </slot>
            <div class="pendo-multiselect-group__remove">
                <pendo-icon-button
                    tooltip="Remove"
                    icon="trash-2"
                    :disabled="index === 0 && disableRemove"
                    @click="remove(index)" />
            </div>
        </div>
        <slot
            name="add"
            v-bind="{
                disableAdd,
                add
            }">
            <pendo-button
                v-pendo-tooltip="{ content: allOptionsSelected ? allSelectedLabel : null, arrow: true }"
                theme="app"
                type="link"
                prefix-icon="plus"
                class="pendo-multiselect-group__add"
                :disabled="disableAdd"
                :label="addLabel"
                @click="add" />
        </slot>
    </div>
</template>

<script>
import PendoIconButton from '@/components/icon-button/pendo-icon-button';
import PendoButton from '@/components/button/pendo-button';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy';

export default {
    name: 'PendoMultiselectGroup',
    components: {
        PendoIconButton,
        PendoButton,
        PendoMultiselect
    },
    directives: {
        PendoTooltip
    },
    props: {
        /**
         * initial value of options to show as selected
         */
        value: {
            type: Array,
            default: () => []
        },
        /**
         * Array of all available options the user can select from
         */
        options: {
            type: Array,
            default: () => []
        },
        /**
         * disables all interactions
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         *  text/label to use above the first dropdown
         */
        optionsLabel: {
            type: String,
            default: null
        },
        /**
         *  text to use for the 'Add option' button
         */
        addLabel: {
            type: String,
            default: 'Add Option'
        },
        /**
         *  text to use for the 'all options selected' dropdown text and tooltip content
         */
        allSelectedLabel: {
            type: String,
            default: 'All available options selected.'
        }
    },
    data () {
        return {
            model: []
        };
    },
    computed: {
        allOptionsSelected () {
            return !this.availableOptions.length || this.model.length === this.options.length;
        },
        availableOptions () {
            return differenceBy(this.options, this.model, this.$attrs.valueKey || 'id');
        },
        disableRemove () {
            return isEmpty(this.model[0]);
        },
        disableAdd () {
            return this.disabled || this.allOptionsSelected || this.model.length === this.options.length;
        }
    },
    created () {
        this.initialize();
    },
    methods: {
        initialize () {
            this.model = [...new Set([...this.value])];
            if (!this.model.length) {
                this.add();
            }
        },
        add () {
            if (this.disableAdd) {
                return;
            }

            this.model.push({});
            this.onModelChange();
        },
        remove (index) {
            if (index === 0 && this.model.length === 1) {
                this.model = [{}];
            } else {
                this.model = this.model.slice(0, index).concat(this.model.slice(index + 1));
            }
            this.onModelChange();
        },
        select (option, index) {
            this.$set(this.model, index, option);
            this.onModelChange();
        },
        onModelChange () {
            const model = this.model.filter((option) => !isEmpty(option));
            this.$emit('change', model);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-group) {
    @include element(item) {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 36px;
        grid-gap: 8px;
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 8px;
        }
    }

    @include element(add) {
        justify-content: start;
    }

    @include element(remove) {
        align-self: end;
    }
}
</style>
