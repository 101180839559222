<template>
    <div
        class="pendo-color-picker-input"
        :class="`pendo-color-picker-input--${valueKey}`">
        <input
            ref="input"
            :value="value"
            :type="type"
            :maxlength="maxlength"
            :min="min"
            :max="max"
            :disabled="disabled"
            :aria-label="ariaLabel"
            @focus="onFocus"
            @input="onInput"
            @blur="onBlur"
            @keydown.esc="onEscKeydown">
        <label :for="valueKey">
            {{ label || valueKey }}
        </label>
    </div>
</template>

<script>
import { isValidHex } from '@/utils/color';

export default {
    name: 'ColorPickerInput',
    props: {
        value: {
            type: [String, Number, Object],
            default: null
        },
        valueKey: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'text'
        },
        max: {
            type: Number,
            default: 255
        },
        min: {
            type: Number,
            default: 0
        },
        maxlength: {
            type: Number,
            default: Infinity
        },
        disabled: {
            type: Boolean,
            default: false
        },
        ariaLabel: {
            type: String,
            default: null
        }
    },
    methods: {
        onEscKeydown () {
            this.$refs.input.blur();
        },
        onInput (event) {
            const { value } = event.target;

            this.$emit('change', {
                [this.valueKey]: value
            });

            this.$refs.input.value = value;
        },
        onBlur (event) {
            if (this.disabled) {
                return;
            }

            if (this.valueKey === 'hex') {
                this.onInput(event);
                if (!isValidHex(event.target.value)) {
                    this.$refs.input.value = this.value;
                }
            }
            this.$emit('blur', event);
        },
        onFocus (event) {
            if (this.disabled) {
                return;
            }

            this.$emit('focus', event);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-color-picker-input) {
    input {
        position: relative;
        border: none;
        width: 100%;
        padding: 0;
        border-radius: $border-radius-3;
        box-shadow: inset 0 0 0 1px $color-gray-40;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        @include font-family;

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 1px $color-gray-100;
        }
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    label {
        display: block;
        text-align: center;
        font-size: 11px;
        color: $color-text-primary;
        text-transform: capitalize;
        margin-bottom: 0;
        font-weight: 400;
    }
}
</style>
