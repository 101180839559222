<template>
    <pendo-modal
        :visible="showWarning"
        class="pendo-inactivity-modal"
        title="Session Timeout"
        height="auto"
        :show-close="false"
        :action-buttons="[
            {
                label: 'Log out',
                type: 'secondary',
                handler: onLogout
            },
            {
                label: 'Continue',
                type: 'primary',
                handler: onContinue
            }
        ]">
        <div class="pendo-inactivity-modal__body">
            <div class="pendo-inactivity-modal__timer">
                <pendo-icon
                    type="clock"
                    size="24" />
                {{ formattedTimeRemaining }}
            </div>
            <div class="pendo-inactivity-modal__warning">
                Your Pendo session is about to expire due to inactivity. Please click 'Continue' to stay logged in.
            </div>
        </div>
    </pendo-modal>
</template>

<script>
import PendoModal from '@/components/modal/pendo-modal';
import PendoIcon from '@/components/icon/pendo-icon';

import moment from 'moment';

export default {
    name: 'PendoInactivityModal',
    components: {
        PendoModal,
        PendoIcon
    },
    props: {
        showWarning: {
            type: Boolean,
            default: false
        },
        /**
         * Time remaining in seconds
         */
        timeRemaining: {
            type: Number,
            default: 0
        },
        onContinue: {
            type: Function,
            default: () => {}
        },
        onLogout: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        formattedTimeRemaining () {
            const seconds = this.timeRemaining > 0 ? this.timeRemaining : 0;

            return moment(seconds * 1000).format('mm:ss');
        }
    }
};
</script>

<style lang="scss" scoped>
@include block(pendo-inactivity-modal) {
    @include element(body) {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    @include element(timer) {
        display: flex;
        align-items: center;
        gap: 8px;

        font-size: 24px;
    }
}
</style>
