<template>
    <pendo-table
        ref="table"
        condensed
        :columns="columns"
        :title="title"
        :loading="loading"
        :loading-text="loadingText"
        :height="height"
        :max-height="maxHeight"
        :data="guideItems"
        :status="status">
        <template #status>
            <slot name="status" />
        </template>
        <template #group="{ row }">
            <pendo-group-cell :row="row" />
        </template>
        <template #segment="{ row }">
            {{ row.segment.name }}
        </template>
        <template #state="{ row }">
            <pendo-guide-status-cell
                :row="row"
                :interactive="false" />
        </template>
    </pendo-table>
</template>
<script>
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import PendoTable from '@/components/table/pendo-table';
import PendoGroupCell from '@/components/table/cell-types/pendo-group-cell';
import PendoGuideStatusCell from '@/components/table/cell-types/pendo-guide-status-cell';

export default {
    name: 'PendoGuidesTargetingEntity',
    components: {
        PendoTable,
        PendoGuideStatusCell,
        PendoGroupCell
    },
    props: {
        entityName: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default: null
        },
        baseUrl: {
            type: String,
            required: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        maxHeight: {
            type: [Number, String],
            default: null
        },
        height: {
            type: [Number, String],
            default: null
        },
        status: {
            type: String,
            default: undefined
        },
        guideItems: {
            type: Array,
            default: () => [],
            validator: (guideItems) => {
                try {
                    if (!(guideItems instanceof Array)) {
                        return false;
                    }

                    const requiredGuideKeys = ['name', 'id', 'state', 'group', 'segment'].sort();
                    const requiredGroupKeys = ['name', 'color'].sort();
                    const requiredSegmentKeys = ['name'].sort();

                    for (let i = 0; i < guideItems.length; i++) {
                        const guide = guideItems[i];
                        if (!(guide instanceof Object)) {
                            return false;
                        }

                        const guideKeys = Object.keys(guide).sort();
                        const groupKeys = Object.keys(guide.group).sort();
                        const segmentKeys = Object.keys(guide.segment).sort();

                        if (!isEqual(guideKeys, requiredGuideKeys)) {
                            // eslint-disable-next-line no-console
                            console.error(
                                'Validation error in pendo-guides-targeting-entity on guide',
                                guideKeys,
                                requiredGuideKeys
                            );

                            return false;
                        }

                        if (!isEqual(groupKeys, requiredGroupKeys)) {
                            // eslint-disable-next-line no-console
                            console.error(
                                'Validation error in pendo-guides-targeting-entity on guide group',
                                groupKeys,
                                requiredGroupKeys
                            );

                            return false;
                        }

                        if (!isEqual(segmentKeys, requiredSegmentKeys)) {
                            // eslint-disable-next-line no-console
                            console.error(
                                'Validation error in pendo-guides-targeting-entity on guide segment',
                                segmentKeys,
                                requiredSegmentKeys
                            );

                            return false;
                        }
                    }

                    return true;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.error(
                        'Validation error from pendo-guides-targeting-entity for prop `guideItems`',
                        err,
                        guideItems
                    );

                    return false;
                }
            }
        }
    },
    data () {
        return {
            columns: [
                {
                    prop: 'name',
                    label: 'Guide Name',
                    type: 'link',
                    href: (row) => `${this.baseUrl}/${row.id}`,
                    sortable: true,
                    fixed: true
                },
                {
                    prop: 'group.name',
                    label: 'Product Area',
                    slot: 'group',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'segment.name',
                    label: 'Segment',
                    slot: 'segment',
                    sortable: true,
                    visible: true
                },
                {
                    prop: 'state',
                    label: 'Guide Status',
                    slot: 'state',
                    sortable: true,
                    visible: true,
                    minWidth: 132,
                    showOverflow: false
                }
            ]
        };
    },
    computed: {
        title () {
            const formattedEntityName = startCase(this.entityName);

            return this.showTitle ? `Guides targeting this ${formattedEntityName}` : '';
        }
    }
};
</script>
