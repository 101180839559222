<template>
    <div
        class="pendo-table__star-header"
        :class="{
            'is-sorted': isSorted
        }"
        @click.stop="toggleSort">
        <svg
            aria-label="starred rows"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16">
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M8 1.3l2 4.2 4.7.7-3.4 3.2.8 4.6L8 11.8 3.9 14l.8-4.6-3.4-3.2L6 5.5 8 1.3z" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PendoStarHeader',
    inject: ['$table'],
    props: {
        column: {
            type: Object,
            required: true
        }
    },
    computed: {
        isSorted () {
            if (this.$table.sortStore.column) {
                return (
                    this.$table.sortStore.column.property === this.column.property &&
                    this.$table.sortStore.activeSort === 'descending'
                );
            }

            return false;
        }
    },
    methods: {
        toggleSort () {
            const order = this.isSorted ? 'none' : 'descending';
            this.$table.setActiveSort(this.column.property, order);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(star-header) {
        height: 16px;
        width: 16px;

        svg {
            fill: $color-white;
            stroke: $color-gray-100;
            transition: stroke 0.2s, fill 0.2s;
        }

        @include is(sorted) {
            svg {
                fill: $color-gray-100;
                stroke: $color-gray-100;
            }
        }

        &:hover {
            cursor: pointer;

            svg {
                transition: stroke 0.2s, fill 0.2s;
                fill: $color-gray-100;
                stroke: $color-gray-100;
            }
        }
    }
}
</style>
