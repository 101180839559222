<template>
    <div
        class="pendo-collapse"
        :class="[
            theme,
            {
                'has-multiple-panels': formattedPanels.length > 1
            }
        ]">
        <!-- @slot default slot used as alternative to `panels` prop -->
        <slot v-if="!panels.length" />
        <slot name="panels">
            <pendo-collapse-item
                v-for="panel in formattedPanels"
                :id="panel.name"
                :key="panel.name"
                v-pendo-analytics="{ type: 'components:collapse', value: panel.name }"
                v-bind="panel">
                <template #title>
                    <slot :name="panel.titleSlotName" />
                </template>
                <template #default="{ content }">
                    <slot
                        :name="panel.name"
                        :pane="content">
                        <component
                            :is="panel.component"
                            v-if="panel.component"
                            v-bind="panel.componentProps" />
                    </slot>
                </template>
            </pendo-collapse-item>
        </slot>
    </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import PendoCollapseItem from '@/components/collapse/pendo-collapse-item';
import { getScrollParent } from '@/utils/dom';
import { PendoAnalytics } from '@/directives/analytics/pendo-analytics';

export default {
    name: 'PendoCollapse',
    components: {
        PendoCollapseItem
    },
    directives: {
        PendoAnalytics
    },
    provide () {
        return {
            $collapse: this
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        /**
         * currrent expanded panel(s)
         */
        value: {
            type: [Array, String, Number],
            default: () => []
        },
        /**
         * @ignore
         */
        theme: {
            type: String,
            default: 'app'
        },
        /**
         * when true, only one panel can be expanded at any given time. selecting a different panel will close the current expanded panel
         */
        accordion: {
            type: Boolean,
            default: false
        },
        /**
         * pass panels as an array
         */
        panels: {
            type: [Array, Object],
            default: () => []
        },
        /**
         * pass panel prefix + suffix icon props as an object to apply those settings to *all* panels
         */
        panelHeaderProps: {
            type: Object,
            default: () => ({})
        },
        /**
         * Configuration object for [vue-scrollto](https://github.com/rigor789/vue-scrollto)
         */
        panelScrollConfig: {
            type: [Boolean, Object],
            default: false
        },
        /**
         * Array of collapse items that should always remain open
         */
        forceAlwaysOpen: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            expandedPanels: [...new Set([].concat(...[this.forceAlwaysOpen, this.value]))]
        };
    },
    computed: {
        model: {
            get () {
                return this.expandedPanels;
            },
            set (val) {
                // this.forceAlwaysOpen can be an array of collapse items that should always remain open
                // on change of acive panels, merge the current expanded panels that element emits with
                // the array of forceAlwaysOpen
                this.expandedPanels = [...new Set([].concat(...[this.forceAlwaysOpen, val]))];
                /**
                 * Emitted when the expanded panel value changes
                 *
                 * @event change
                 * @property Any Current expanded panels
                 */
                this.$emit('change', this.expandedPanels);
            }
        },
        formattedPanels () {
            // prefix + suffix icon props can be passed in using an object passed a few different ways for convinience factors
            // not passing it applies the defaults (in the case of prefix, nothing)
            // passing it via the headerProps.[prefix || suffix]IconProps object will apply settings to ALL panel prefix || suffix icons
            // finally, passing via the individual panel will override everything for that specific panel ONLY
            return this.panels.map((panel) => ({
                ...panel,
                prefixIcon: {
                    ...this.panelHeaderProps.prefixIcon,
                    ...panel.prefixIcon
                },
                suffixIcon: {
                    ...(panel.suffixIcon !== false && {
                        type: 'chevron-down',
                        size: '14px',
                        stroke: '#6a6c75'
                    }),
                    ...this.panelHeaderProps.suffixIcon,
                    ...panel.suffixIcon
                }
            }));
        }
    },
    watch: {
        value (value) {
            this.expandedPanels = [...new Set([].concat(...[this.forceAlwaysOpen, value]))];
        }
    },
    methods: {
        scrollPanelIntoView (name) {
            if (!this.panelScrollConfig) {
                return;
            }

            const element = `#${name}`;
            const container = this.panelScrollConfig.container || getScrollParent(document.querySelector(element));

            VueScrollTo.scrollTo(container, 300, {
                easing: 'ease-in-out',
                offset: this.panelScrollConfig.offset || 0
            });
        },
        handlePanelClick (name) {
            let expandedPanels = this.model.slice(0);
            const index = expandedPanels.indexOf(name);

            if (this.accordion) {
                if (index > -1) {
                    expandedPanels = [];
                } else {
                    this.scrollPanelIntoView(name);
                    expandedPanels = [].concat(name);
                }

                this.model = expandedPanels;

                return;
            }

            if (index > -1) {
                expandedPanels.splice(index, 1);
            } else {
                this.scrollPanelIntoView(name);
                expandedPanels.push(name);
            }

            this.model = expandedPanels;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-card) {
    .pendo-card__body > .pendo-collapse {
        border-top: 0;

        > .pendo-collapse-item:first-child {
            border-top: 0;
        }
    }
}

@include block(pendo-collapse) {
    border-top: 1px solid $collapse-border-color;
    border-bottom: 1px solid $collapse-border-color;
    width: 100%;

    > .pendo-collapse-item:last-child {
        margin-bottom: -1px;
    }

    &.p2-light {
        border-top-color: transparent;
        border-bottom-color: transparent;

        .pendo-collapse-item {
            border-bottom-color: transparent;

            .pendo-collapse-item__header {
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid $color-gray-30;
                font-size: 12px;
                font-weight: 600;
                color: $color-gray-60;
                letter-spacing: 0.75px;
                text-transform: uppercase;
                padding: 0 16px;

                .pendo-collapse-item__suffix-icon {
                    height: 18px;
                    width: 18px;
                    margin: auto;
                    display: grid;
                    align-items: center;
                    justify-content: center;

                    .pendo-icon__content {
                        height: 14px;
                        width: 14px;
                    }
                }

                &:hover {
                    .pendo-collapse-item__suffix-icon {
                        transition: all 0.3s;
                        background-color: $color-gray-30;
                        border-radius: $border-radius-2;
                    }
                }

                @include is(expanded) {
                    border-bottom-color: transparent;
                }
            }

            .pendo-collapse-item__content {
                padding: 0 16px 24px;
            }
        }

        &.has-multiple-panels {
            border-bottom-color: $color-gray-30;

            .pendo-collapse-item:not(:last-child) {
                &.is-expanded {
                    border-bottom: 1px solid $color-gray-30;
                }
            }
        }
    }
}
</style>
