<template>
    <button
        class="pendo-table__tree-expand"
        :class="{
            'is-expanded': expanded
        }"
        aria-label="tree expansion"
        :aria-expanded="`${expanded}`"
        @click="handleExpand">
        <i class="pendo-icon">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
                display="block">
                <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    ry="2" />
                <line
                    v-if="!expanded"
                    x1="12"
                    y1="8"
                    x2="12"
                    y2="16" />
                <line
                    x1="8"
                    y1="12"
                    x2="16"
                    y2="12" />
            </svg>
        </i>
    </button>
</template>

<script>
export default {
    inject: ['$table'],
    props: {
        row: {
            type: Object,
            default: null
        }
    },
    computed: {
        expanded () {
            if (this.row) {
                return Boolean(this.$table.treeExpandedMap[this.row[this.$table.rowKey]]);
            }

            return this.$table.isAllTreeExpanded;
        }
    },
    methods: {
        handleExpand () {
            if (this.row) {
                this.$table.toggleRowTreeExpand(this.row);
            } else {
                this.$table.toggleAllRowsTreeExpand();
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(tree-expand) {
        border: none;
        width: auto;
        overflow: visible;
        background: transparent;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: $color-gray-70;
        transition: color 0.2s ease;

        .pendo-icon {
            border-radius: 2px;
            @include focus-ring($style: 'base');
        }

        &:focus-visible {
            @include no-focus-ring();

            .pendo-icon {
                @include focus-ring($style: 'focused');
            }
        }

        &:hover {
            color: $color-gray-100;
        }

        @include is(expanded) {
            color: $color-gray-100;
        }
    }
}
</style>
