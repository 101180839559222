<template>
    <div>
        <div
            v-if="readOnly"
            :class="['pendo-multiselect__trigger--readonly', `pendo-multiselect__trigger--${size}`]">
            <pendo-status-option
                :option="selectedValue"
                :status-color-map="statusColorMap" />
        </div>
        <button
            v-if="!readOnly"
            ref="trigger"
            type="button"
            class="pendo-multiselect__trigger"
            aria-haspopup
            :disabled="multiselect.disabled"
            :aria-expanded="`${multiselect.isOpen}`"
            :class="[
                `pendo-multiselect__trigger--${size}`,
                `pendo-multiselect__trigger--status-dropdown`,
                `pendo-multiselect__trigger--${color}`,
                {
                    'is-active': multiselect.isFocused || multiselect.isOpen,
                    'is-open': multiselect.isOpen,
                    'is-disabled': multiselect.disabled
                }
            ]"
            @focus="multiselect.handleFocus"
            @blur="multiselect.handleBlur"
            @click="onClick"
            @keyup.esc="multiselect.deactivate"
            @keydown.tab="multiselect.handleKeydown('tab')"
            @keydown.up.prevent.self="multiselect.handleKeydown('up')"
            @keydown.enter="multiselect.handleKeydown('enter', $event)"
            @keydown.down.prevent.self="multiselect.handleKeydown('down')"
            @keyup.space.prevent.self.stop="multiselect.handleKeydown('space', $event)">
            <div
                :class="[
                    'pendo-multiselect__prefix',
                    {
                        'pendo-multiselect__prefix--fill': useDefaultFill
                    }
                ]">
                <pendo-icon
                    v-bind="icon"
                    aria-hidden="true" />
            </div>
            <pendo-multiselect-value
                v-bind="{
                    getOptionLabel: multiselect.getOptionLabel,
                    isPlaceholderVisible: multiselect.isPlaceholderVisible,
                    isSelectedLabelVisible: isSelectedLabelVisible,
                    placeholder: multiselect.placeholder,
                    selectedLabel: selectedLabel,
                    selectedValue: multiselect.selectedValue
                }"
                :class="{
                    'is-disabled': multiselect.disabled
                }" />
            <div class="pendo-multiselect__suffix">
                <pendo-icon
                    v-if="!multiselect.loading"
                    type="chevron-down"
                    size="14"
                    aria-hidden="true"
                    class="pendo-multiselect__caret"
                    :class="{
                        'is-active': multiselect.isOpen
                    }" />
            </div>
        </button>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoMultiselectValue from '@/components/multiselect/pendo-multiselect-value';
import PendoStatusOption from '@/components/multiselect/option-types/pendo-status-option';
import { setStyles } from '@/utils/dom';
import { STATUS_COLOR_MAP } from '@/utils/color';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

export default {
    name: 'PendoStatusTrigger',
    components: {
        PendoIcon,
        PendoMultiselectValue,
        PendoStatusOption
    },
    inject: {
        multiselect: {
            default: {
                handleFocus: () => {},
                handleBlur: () => {},
                handleKeydown: () => {},
                deactivate: () => {},
                getOptionLabel: () => {},
                labelKey: 'label',
                valueKey: 'state'
            }
        }
    },
    props: {
        size: {
            type: String,
            default: 'mini',
            validator: (size) => ['medium', 'mini'].includes(size)
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        statusColorMap: {
            type: Object,
            default: () => STATUS_COLOR_MAP
        },
        /**
         * @ignore
         * Used by `status-cell` to set the trigger's value,
         * even outside the context of a `pendo-multiselect`.
         */
        value: {
            type: Object,
            default: null
        }
    },
    computed: {
        selectedValue () {
            return this.value || this.multiselect.selectedValue || {};
        },
        selectedLabel () {
            if (!isUndefined(this.multiselect.selectedLabel)) {
                return this.multiselect.selectedLabel;
            }
            const key = this.multiselect.labelKey;

            return this.selectedValue[key];
        },
        isSelectedLabelVisible () {
            return isUndefined(this.multiselect.isSelectedLabelVisible)
                ? !isEmpty(this.selectedLabel)
                : this.multiselect.isSelectedLabelVisible;
        },
        color () {
            const key = this.multiselect.valueKey;
            const valueId = this.selectedValue[key];

            return this.statusColorMap[valueId] || 'gray';
        },
        icon () {
            return (
                this.selectedValue.icon || {
                    type: 'circle',
                    size: 8
                }
            );
        },
        useDefaultFill () {
            return this.icon.fill === undefined;
        }
    },
    watch: {
        'multiselect.isOpen': function (isOpen) {
            if (isOpen) {
                this.$el.focus();
            }
        },
        'multiselect.minTriggerWidth': function () {
            const minWidth = 92;
            setStyles(this.$refs.trigger, {
                minWidth: this.multiselect.isFullWidth ? '100%' : minWidth
            });
        },
        'multiselect.maxTriggerWidth': function () {
            setStyles(this.$refs.trigger, { maxWidth: this.multiselect.maxTriggerWidth });
        }
    },
    mounted () {
        const minWidth = 92;
        setStyles(this.$refs.trigger, {
            minWidth: this.multiselect.isFullWidth ? '100%' : minWidth,
            maxWidth: this.multiselect.maxTriggerWidth
        });
    },
    methods: {
        onClick ($event) {
            if (this.multiselect.handleTriggerClick) {
                this.multiselect.handleTriggerClick($event);
            } else {
                this.$emit('click', $event);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(trigger) {
        @include modifier(readonly) {
            display: flex;
            align-items: center;
            padding-left: 8px;
            text-transform: capitalize;
        }

        @include modifier(status-dropdown) {
            transition: color 150ms, background-color 150ms, box-shadow 150ms, opacity 150ms;
            background: $color-white;
            border: none;
            padding-left: 8px;
            text-transform: capitalize;

            &:hover,
            &.is-active,
            &.is-open {
                border: none;
            }

            @include element(prefix) {
                width: 16px;
                margin-right: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @each $color-key in map-keys($status-color-map) {
                $color: map-fetch($status-color-map, $color-key color);
                $color-disabled: map-fetch($status-color-map, $color-key color-disabled);
                $background: map-fetch($status-color-map, $color-key background);
                $background-disabled: map-fetch($status-color-map, $color-key background-disabled);
                $primary: map-fetch($status-color-map, $color-key primary);
                $primary-disabled: map-fetch($status-color-map, $color-key primary-disabled);
                $pressed: map-fetch($status-color-map, $color-key pressed);
                $hover: map-fetch($status-color-map, $color-key hover);

                &.pendo-multiselect__trigger--#{$color-key} {
                    color: $color;
                    background-color: $background;
                    box-shadow: inset 0 0 0 1px $primary;

                    .pendo-multiselect__prefix svg {
                        stroke: $primary;
                    }

                    .pendo-multiselect__prefix--fill svg {
                        fill: $primary;
                    }

                    &.is-disabled {
                        color: $color-disabled;
                        background-color: $background-disabled;
                        box-shadow: inset 0 0 0 1px $primary-disabled;

                        .pendo-multiselect__prefix svg {
                            stroke: $primary-disabled;
                        }

                        .pendo-multiselect__prefix--fill svg {
                            fill: $primary-disabled;
                        }
                    }

                    &:not(.is-disabled):hover {
                        background-color: $hover;
                    }

                    &:not(.is-disabled):active {
                        background-color: $pressed;
                    }
                }
            }
        }
    }
}
</style>
