<template>
    <div
        :class="[
            'pendo-input-number',
            `pendo-input-number--${size}`,
            {
                'always-show-arrows': alwaysShowArrows,
                'is-focused': isFocused,
                'is-disabled': isDisabled,
                'is-without-controls': !controls
            }
        ]"
        @dragstart.prevent>
        <pendo-input
            ref="input"
            role="spinbutton"
            class="pendo-input-number__input-field"
            :value="displayValue"
            :disabled="isDisabled"
            :size="size"
            :max="max"
            :min="min"
            :aria-label="ariaLabel"
            :aria-valuemax="ariaValueMax"
            :aria-valuemin="ariaValueMin"
            :aria-valuenow="currentValue"
            aria-live="polite"
            :name="name"
            :label="label"
            :labels="labels"
            :label-position="labelPosition"
            :width="width"
            @keydown="handleKeydown"
            @blur="handleBlur"
            @focus="handleFocus"
            @input="handleTypedInput"
            @change="handleTypedInputChange">
            <template
                v-if="$slots.prepend"
                #prepend>
                <slot name="prepend" />
            </template>
            <template #suffix>
                <span
                    v-if="suffixLabel"
                    class="pendo-input-number__suffix-label">
                    {{ suffixLabel }}
                </span>
                <div
                    v-if="controls"
                    class="pendo-input-number__suffix-controls">
                    <button
                        class="pendo-input-number__increase"
                        type="button"
                        :tabindex="-1"
                        :disabled="maxDisabled"
                        :class="{ 'is-disabled': maxDisabled }"
                        @mouseleave="onControlsMouseup('increase')"
                        @mouseup.prevent="onControlsMouseup('increase')"
                        @mousedown.prevent="onControlsMousedown('increase')"
                        @click.prevent>
                        <pendo-icon
                            size="13"
                            type="chevron-up" />
                    </button>
                    <button
                        class="pendo-input-number__decrease"
                        type="button"
                        :tabindex="-1"
                        :disabled="minDisabled"
                        :class="{ 'is-disabled': minDisabled }"
                        @mouseleave="onControlsMouseup('decrease')"
                        @mouseup.prevent="onControlsMouseup('decrease')"
                        @mousedown.prevent="onControlsMousedown('decrease')"
                        @click.prevent>
                        <pendo-icon
                            size="13"
                            type="chevron-down" />
                    </button>
                </div>
            </template>
            <template
                v-if="$slots.topLabel"
                #topLabel>
                <slot name="topLabel" />
            </template>
            <template
                v-if="$slots.bottomLabel"
                #bottomLabel>
                <slot name="bottomLabel" />
            </template>
            <template
                v-if="$slots.append"
                #append>
                <slot name="append" />
            </template>
        </pendo-input>
    </div>
</template>

<script>
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import PendoInput from '@/components/input/pendo-input';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import {
    parseIncomingValue,
    parseIncomingUnit,
    formatOutputValue,
    getPrecision,
    increase,
    decrease,
    toPrecision
} from '@/components/input-number/utils';
import { clamp, keyCodes } from '@/utils/utils';
import labelsMixin from '@/mixins/labels';

export default {
    name: 'PendoInputNumber',
    components: {
        PendoInput,
        PendoIcon
    },
    mixins: [labelsMixin],
    inject: {
        $form: {
            default: ''
        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        /**
         * same as `name` in native input
         */
        name: {
            type: String,
            default: ''
        },
        /**
         * incremental step
         */
        step: {
            type: Number,
            default: 1
        },
        /**
         * whether to enable the control buttons
         */
        controls: {
            type: Boolean,
            default: true
        },
        /**
         * disables user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * the minimum allowed value
         */
        min: {
            type: Number,
            default: -Infinity
        },
        /**
         * the maximum allowed value
         */
        max: {
            type: Number,
            default: Infinity
        },
        /**
         * bound value
         */
        value: {
            type: [Number, String],
            default: undefined
        },
        /**
         * size of the component
         * @values medium, small, mini
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['mini', 'small', 'medium'].includes(size)
        },
        /**
         * precision of decimal places in input value.
         */
        precision: {
            type: Number,
            default: 0,
            validator (val) {
                return val >= 0 && val === parseInt(val, 10);
            }
        },
        /**
         * width of input, same accepted values as the underlying pendo-input supports
         * @values medium, small, mini, 100%
         */
        width: {
            type: String,
            default: null
        },
        /**
         * by default, arrows appear on hover. this forces the arrows to always show
         */
        alwaysShowArrows: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isFocused: false,
            userInput: null,
            currentValue: 0,
            unit: null
        };
    },
    computed: {
        // value can be a number or string with a optional unit on it e.g. '1px'
        // we strip off the `px` and convert to number in order to leverage throughout this component
        // currentValue is the last valid value that the component registered and stored internally
        parsedValue () {
            return parseIncomingValue({ value: this.value, precision: this.precision });
        },
        isDisabled () {
            const isFormDisabled = get(this, '$form.disabled', false);

            return this.disabled || isFormDisabled;
        },
        minDisabled () {
            return decrease(this.parsedValue, this.step, this.numPrecision, this.currentValue) < this.min;
        },
        maxDisabled () {
            return increase(this.parsedValue, this.step, this.numPrecision, this.currentValue) > this.max;
        },
        numPrecision () {
            const { parsedValue, step, precision } = this;

            if (!isUndefined(precision)) {
                return precision;
            }
            const stepPrecision = getPrecision(step);
            const valuePrecision = getPrecision(parsedValue);

            return Math.max(valuePrecision, stepPrecision);
        },
        displayValue () {
            if (this.userInput !== null) {
                return this.userInput;
            }
            const { currentValue } = this;
            if (typeof currentValue === 'number' && !isUndefined(this.precision)) {
                return currentValue.toFixed(this.precision);
            }

            return currentValue;
        },
        ariaLabel () {
            if (this.$attrs && this.$attrs['aria-label']) {
                return this.$attrs['aria-label'];
            }

            return undefined;
        },
        ariaValueMax () {
            if (this.max === Infinity) {
                return Number.MAX_SAFE_INTEGER;
            }

            return this.max;
        },
        ariaValueMin () {
            if (this.min === -Infinity) {
                return Number.MIN_SAFE_INTEGER;
            }

            return this.min;
        }
    },
    watch: {
        parsedValue: 'onParsedValueChange'
    },
    created () {
        this.unit = parseIncomingUnit({ value: this.value });
        this.onParsedValueChange(this.parsedValue);
    },
    methods: {
        onParsedValueChange (value) {
            let newVal = isUndefined(value) ? value : Number(value);
            if (!isUndefined(newVal)) {
                if (isNaN(newVal)) {
                    return;
                }

                if (!isUndefined(this.precision)) {
                    newVal = toPrecision(newVal, this.precision);
                }
            }

            if (newVal > this.max || newVal < this.min) {
                newVal = clamp(newVal, this.min, this.max);
                this.handleChange(newVal);
            }

            this.currentValue = newVal;
            this.userInput = null;
        },
        handleFocus (event) {
            this.isFocused = true;
            this.$emit('focus', event);
        },
        handleBlur (event) {
            this.isFocused = false;
            this.$emit('blur', event);
        },
        handleChange (value) {
            let newVal = value;
            if (typeof newVal === 'number' && !isUndefined(this.precision)) {
                newVal = toPrecision(newVal, this.precision);
            }

            if (newVal > this.max || newVal < this.min) {
                newVal = clamp(newVal, this.min, this.max);
            }

            if (newVal === this.currentValue) {
                return;
            }

            this.userInput = null;

            if (this.unit) {
                const { unit, precision } = this;
                // this.unit is parsed from bound value. In the case of 'dp' unit, we need
                // to format it before sending it back out

                this.$emit('change', formatOutputValue({ value: newVal, precision, unit }));
            } else {
                this.$emit('change', newVal);
            }

            this.currentValue = newVal;
        },
        onControlsMouseup (handler) {
            if (new Date() - this.startTime < 100) {
                this[handler]();
            }
            this.interval = clearInterval(this.interval);
        },
        onControlsMousedown (handler) {
            this.startTime = new Date();
            clearInterval(this.interval);
            this.interval = setInterval(this[handler], 100);
        },
        async increase () {
            if (this.isDisabled || this.maxDisabled) {
                this.interval = clearInterval(this.interval);

                return;
            }
            // handle when a user types a number and does not hit "enter" before increasing
            if (this.userInput) {
                this.handleTypedInputChange(this.userInput);
                // wait for onParsedValueChange to update
                await this.$nextTick();
            }

            const value = this.parsedValue || 0;
            const newVal = increase(value, this.step, this.numPrecision, this.currentValue);
            this.handleChange(newVal);
        },
        async decrease () {
            if (this.isDisabled || this.minDisabled) {
                this.interval = clearInterval(this.interval);

                return;
            }
            // handle when a user types a number and does not hit "enter" before decreasing
            if (this.userInput) {
                this.handleTypedInputChange(this.userInput);
                // wait for onParsedValueChange to update
                await this.$nextTick();
            }

            const value = this.parsedValue || 0;
            const newVal = decrease(value, this.step, this.numPrecision, this.currentValue);
            this.handleChange(newVal);
        },
        handleTypedInput (value) {
            // handle the incoming events while the user is typing
            this.userInput = value;
        },
        handleTypedInputChange (value) {
            // handle the incoming event when the user finishes typing
            const newVal = value === '' ? undefined : Number(value);
            if (!isNaN(newVal) || value === '') {
                this.handleChange(newVal);
            }

            this.userInput = null;
        },
        handleKeydown (event) {
            if (event.keyCode === keyCodes.up) {
                event.preventDefault();
                this.increase();
            }

            if (event.keyCode === keyCodes.down) {
                event.preventDefault();
                this.decrease();
            }

            this.$emit('keydown', event);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-input-number) {
    position: relative;

    @include element(input-field) {
        .pendo-input__field {
            position: relative;

            &.is-focused,
            &:hover {
                @include element((increase, decrease)) {
                    opacity: 1;
                    visibility: visible;
                }

                @include element(suffix-label) {
                    opacity: 0;
                    visibility: hidden;
                    user-select: none;
                }
            }
        }

        .pendo-input__suffix {
            height: 100%;
            flex-basis: 29px;
        }
    }

    @include element((increase, decrease, suffix-label)) {
        z-index: 1;
        transition: visibility 0s, opacity 0.2s;
        opacity: 0;

        @include is(disabled) {
            color: $color-gray-40;
            cursor: not-allowed;
        }
    }

    @include element((increase, decrease)) {
        @include button-reset;
        display: grid;
        cursor: pointer;
        visibility: hidden;
    }

    @include element(suffix-controls) {
        position: absolute;
        right: 8px;
    }

    @include element(suffix-label) {
        user-select: none;
        visibility: visible;
        opacity: 1;
    }

    &.always-show-arrows {
        .pendo-input-number__increase,
        .pendo-input-number__decrease {
            opacity: 1;
            visibility: visible;
        }

        .pendo-input-number__suffix-label {
            opacity: 0;
            visibility: hidden;
        }
    }

    @include modifier(small) {
        @include element((increase, decrease)) {
            svg {
                height: 11px;
                width: 11px;
            }
        }
    }

    @include modifier(mini) {
        @include element((increase, decrease)) {
            svg {
                height: 10px;
                width: 10px;
            }
        }
    }

    @include is(disabled) {
        @include element((increase, decrease)) {
            display: none;
        }
    }
}
</style>
