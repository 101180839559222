<template>
    <button
        ref="trigger"
        type="button"
        class="pendo-multiselect__trigger"
        aria-haspopup
        :disabled="multiselect.disabled"
        :aria-expanded="`${multiselect.isOpen}`"
        :class="[
            `pendo-multiselect__trigger--${size}`,
            `pendo-multiselect__trigger--${type}`,
            `pendo-multiselect__trigger--status`,
            `pendo-multiselect__trigger--${status}`,
            {
                'pendo-multiselect__trigger--fill': status === 'public',
                'is-active': multiselect.isFocused || multiselect.isOpen,
                'is-open': multiselect.isOpen,
                'is-disabled': multiselect.disabled
            }
        ]"
        @focus="multiselect.handleFocus"
        @blur="multiselect.handleBlur"
        @click="multiselect.handleTriggerClick"
        @keyup.esc="multiselect.deactivate"
        @keydown.tab="multiselect.handleKeydown('tab')"
        @keydown.up.prevent.self="multiselect.handleKeydown('up')"
        @keydown.enter="multiselect.handleKeydown('enter', $event)"
        @keydown.down.prevent.self="multiselect.handleKeydown('down')"
        @keyup.space.prevent.self.stop="multiselect.handleKeydown('space', $event)">
        <pendo-multiselect-value
            v-bind="{
                getOptionLabel: multiselect.getOptionLabel,
                isPlaceholderVisible: multiselect.isPlaceholderVisible,
                isSelectedLabelVisible: multiselect.isSelectedLabelVisible,
                placeholder: multiselect.placeholder,
                selectedLabel: multiselect.selectedLabel,
                selectedValue: multiselect.selectedValue
            }"
            :class="{
                'is-disabled': multiselect.disabled
            }" />
        <div class="pendo-multiselect__suffix">
            <pendo-icon
                v-if="!multiselect.loading"
                type="chevron-down"
                size="14"
                aria-hidden="true"
                class="pendo-multiselect__caret"
                :class="{
                    'is-active': multiselect.isOpen
                }" />
        </div>
    </button>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoMultiselectValue from '@/components/multiselect/pendo-multiselect-value';
import { setStyles } from '@/utils/dom';

export default {
    name: 'PendoGuideStatusTrigger',
    components: {
        PendoIcon,
        PendoMultiselectValue
    },
    inject: ['multiselect'],
    props: {
        type: {
            type: String,
            default: 'outline',
            validator: (type) => ['fill', 'outline'].includes(type)
        },
        size: {
            type: String,
            default: 'mini',
            validator: (size) => ['medium', 'mini'].includes(size)
        }
    },
    computed: {
        status () {
            const key = this.multiselect.valueKey;

            return this.multiselect.selectedValue && this.multiselect.selectedValue[key];
        }
    },
    watch: {
        'multiselect.isOpen': function (isOpen) {
            if (isOpen) {
                this.$el.focus();
            }
        },
        'multiselect.minTriggerWidth': function () {
            const minWidth = 92;
            setStyles(this.$refs.trigger, {
                minWidth: this.multiselect.isFullWidth ? '100%' : minWidth
            });
        },
        'multiselect.maxTriggerWidth': function () {
            setStyles(this.$refs.trigger, { maxWidth: this.multiselect.maxTriggerWidth });
        }
    },
    mounted () {
        const minWidth = 92;
        setStyles(this.$refs.trigger, {
            minWidth: this.multiselect.isFullWidth ? '100%' : minWidth,
            maxWidth: this.multiselect.maxTriggerWidth
        });
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(trigger) {
        @include modifier(status) {
            transition: background-color 150ms, box-shadow 150ms;
            background: $color-white;
            border: none;
            padding-left: 10px;
            text-transform: capitalize;

            &:hover,
            &.is-active,
            &.is-open {
                border: none;
            }

            @include is(disabled) {
                opacity: $disabled-opacity;
            }

            @each $status in map-keys($guide-status-map) {
                $color: map-fetch($guide-status-map, $status color);
                $background: map-fetch($guide-status-map, $status background);

                &.pendo-multiselect__trigger--outline {
                    @include element(suffix) {
                        width: 26px;
                    }

                    @include element(caret) {
                        > svg {
                            stroke-width: 3;
                        }
                    }

                    @include element((placeholder, selected)) {
                        font-size: 12px;
                    }

                    &.pendo-multiselect__trigger--#{$status} {
                        font-weight: 700;
                        box-shadow: inset 0 0 0 1px $background;

                        &.is-active,
                        &.is-open {
                            background-color: $background;
                            color: $color;
                            box-shadow: inset 0 0 0 1px $background;
                        }
                    }
                }

                &.pendo-multiselect__trigger--fill {
                    &.pendo-multiselect__trigger--#{$status} {
                        background-color: $background;
                        color: $color;
                    }
                }
            }
        }
    }
}
</style>
