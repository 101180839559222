<template>
    <div
        id="pendo-slider"
        class="pendo-slider"
        :class="[
            {
                'is-vertical': vertical,
                'pendo-slider--with-input': showInput,
                'pendo-slider--inline-label': inlineLabel,
                'is-dragging': isDragging
            },
            `pendo-slider--${type}`
        ]">
        <div
            v-if="!!topLabel"
            class="pendo-slider__label pendo-slider__label--top">
            {{ topLabel }}
        </div>
        <div
            class="pendo-slider__controls"
            role="slider"
            :aria-label="ariaLabel"
            :aria-valuemin="min"
            :aria-valuemax="max"
            :aria-valuenow="value"
            :aria-orientation="vertical ? 'vertical' : 'horizontal'"
            :aria-disabled="disabled"
            @click="onSliderClick">
            <div
                ref="slider"
                class="pendo-slider__rail"
                :class="{
                    'show-input': showInput,
                    'disabled': disabled
                }"
                :style="vertical ? height : undefined">
                <div
                    v-if="showProcess"
                    class="pendo-slider__process"
                    :style="processStyle" />
                <slider-dot
                    :value="model[0]"
                    :disabled="disabled"
                    :format-tooltip="formatTooltip"
                    :max="max"
                    :min="min"
                    :precision="precision"
                    :step="step"
                    :show-stops="showStops"
                    :show-tooltip="showTooltip"
                    :slider-size="sliderSize"
                    :vertical="vertical"
                    :type="type"
                    @drag-start="onDragStart"
                    @drag="onDrag($event, 0)"
                    @drag-end="onDragEnd($event, 0)"
                    @drag-cancel="isDragging = false"
                    @keydown="updateModel($event, 0)" />
                <slider-dot
                    v-if="range"
                    :value="model[1]"
                    :disabled="disabled"
                    :format-tooltip="formatTooltip"
                    :max="max"
                    :min="min"
                    :precision="precision"
                    :step="step"
                    :show-stops="showStops"
                    :show-tooltip="showTooltip"
                    :slider-size="sliderSize"
                    :vertical="vertical"
                    :type="type"
                    @drag-start="onDragStart"
                    @drag="onDrag($event, 1)"
                    @drag-end="onDragEnd($event, 1)"
                    @drag-cancel="isDragging = false"
                    @keydown="updateModel($event, 1)" />
                <template v-if="showStops">
                    <div
                        v-for="(item, key) in stops"
                        :key="key"
                        class="pendo-slider__stop"
                        :style="getStopStyle(item)" />
                </template>
                <template v-if="markList.length > 0">
                    <div>
                        <div
                            v-for="(item, key) in markList"
                            :key="key"
                            :style="getMarkStyle(item)"
                            class="pendo-slider__stop pendo-slider__marks-stop" />
                    </div>
                    <div class="pendo-slider__marks">
                        <slider-mark
                            v-for="(item, key) in markList"
                            :key="key"
                            :mark="item.mark"
                            :style="getStopStyle(item.position)" />
                    </div>
                </template>
            </div>
        </div>
        <slot
            name="input"
            v-bind="{
                value: model[0],
                min,
                max,
                inputSize,
                disabled,
                step
            }">
            <pendo-input-number
                v-if="showInput && !range"
                ref="input"
                v-model="inputValue"
                :aria-label="ariaLabel"
                :labels="{ suffix: 'px' }"
                :max="max"
                :min="min"
                :step="step"
                :precision="precision"
                :disabled="disabled"
                :size="inputSize" />
        </slot>
        <div
            v-if="!!bottomLabel"
            class="pendo-slider__label pendo-slider__label--bottom">
            {{ bottomLabel }}
        </div>
    </div>
</template>

<script>
import isArray from 'lodash/isArray';
import clamp from 'lodash/clamp';
import PendoInputNumber from '@/components/input-number/pendo-input-number';
import Dot from '@/components/slider/dot';
import Mark from '@/components/slider/mark';
import labelsMixin from '@/mixins/labels';

export default {
    name: 'PendoSlider',
    components: {
        PendoInputNumber,
        SliderMark: Mark,
        SliderDot: Dot
    },
    mixins: [labelsMixin],
    props: {
        /**
         * The value of the slider.
         * When the value is an array type, it corresponds to multiple sliders.
         */
        value: {
            type: [Number, Array],
            default: 0
        },
        /**
         * Minimum value
         */
        min: {
            type: Number,
            default: 0
        },
        /**
         * Maximum value
         */
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1,
            validator: (step) => step > 0
        },
        /**
         * reveals a built-in number input for modifying the value
         */
        showInput: {
            type: Boolean,
            default: false
        },
        /**
         * modify the size of the number input when `showInput` is `true`
         * @values medium, small, mini
         */
        inputSize: {
            type: String,
            default: 'medium',
            validator: (inputSize) => ['mini', 'small', 'medium'].includes(inputSize)
        },
        showStops: {
            type: Boolean,
            default: false
        },
        /**
         * Show a tooltip with the current value while the user is hovering and/or dragging the slider
         */
        showTooltip: {
            type: Boolean,
            default: false
        },
        /**
         * Format the value of the Tooltip
         */
        formatTooltip: {
            type: Function,
            default: null
        },
        /**
         * Whether to disable the component
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * enable range mode when using an array for the `value`
         */
        range: {
            type: Boolean,
            default: false
        },
        /**
         * renders a vertical slider instead of the default horizontal slider
         */
        vertical: {
            type: Boolean,
            default: false
        },
        /**
         * The height of the component (unit px), which defaults to 4 in the horizontal direction.
         */
        height: {
            type: String,
            default: '0px'
        },
        /**
         * Used to control the Mark of the display.
         */
        marks: {
            type: [Object, Array],
            default: null
        },
        /**
         * Control the display of the filled progress state
         */
        showProcess: {
            type: Boolean,
            default: false
        },
        /**
         * The style of the progress bar.
         */
        processColor: {
            type: Object,
            default: null
        },
        /**
         * only emit `@input` event when the user has stopped dragging
         */
        lazy: {
            type: Boolean,
            default: false
        },
        /**
         * style of the slider bar
         * @values line, bar
         */
        type: {
            type: String,
            default: 'line',
            validator: (type) => ['line', 'bar'].includes(type)
        }
    },
    data () {
        return {
            isDragging: false,
            dragValue: null,
            sliderSize: 1,
            defaultBorderColor: '#dadce5'
        };
    },
    computed: {
        stops () {
            if (!this.showStops || this.min > this.max) {
                return [];
            }
            const stopCount = (this.max - this.min) / this.step;
            const stepWidth = (100 * this.step) / (this.max - this.min);
            const result = [];

            for (let i = 1; i < stopCount; i++) {
                result.push(i * stepWidth);
            }

            if (this.range) {
                return result.filter(
                    (step) =>
                        step < (100 * (Math.min(...this.model) - this.min)) / (this.max - this.min) ||
                        step > (100 * (Math.max(...this.model) - this.min)) / (this.max - this.min)
                );
            }

            return result.filter((step) => step > (100 * (this.model[0] - this.min)) / (this.max - this.min));
        },
        markList () {
            if (!this.marks) {
                return [];
            }
            const isMarksAnArray = isArray(this.marks);
            const marksKeys = isMarksAnArray ? this.marks : Object.keys(this.marks);

            return marksKeys
                .map(parseFloat)
                .sort((a, b) => a - b)
                .filter((point) => point <= this.max && point >= this.min)
                .map((point, index) => {
                    return {
                        point,
                        position: ((point - this.min) * 100) / (this.max - this.min),
                        mark: isMarksAnArray ? this.marks[index] : this.marks[point]
                    };
                });
        },
        precision () {
            const precisions = [this.min, this.max, this.step].map((item) => {
                const decimal = `${item}`.split('.')[1];

                return decimal ? decimal.length : 0;
            });

            return Math.max.apply(null, precisions);
        },
        processColors () {
            const colorDarkBlue = '#128297';
            const colorLightBlue = '#95dffb';
            const barDefault = {
                borderColor: colorDarkBlue,
                backgroundColor: colorLightBlue
            };
            const lineDefault = {
                backgroundColor: colorDarkBlue
            };
            const defaultStyle = this.type === 'bar' ? barDefault : lineDefault;

            return Object.assign({}, defaultStyle, this.processColor ? this.processColor : null);
        },
        processStyle () {
            let barSize = `${(100 * (this.model[0] - this.min)) / (this.max - this.min)}%`;
            let barStart = '0%';

            if (this.range) {
                const minValue = Math.min(...this.model);
                const maxValue = Math.max(...this.model);
                barSize = `${(100 * (maxValue - minValue)) / (this.max - this.min)}%`;
                barStart = `${(100 * (minValue - this.min)) / (this.max - this.min)}%`;
            }

            if (this.vertical) {
                return {
                    ...this.processColors,
                    height: barSize,
                    bottom: barStart
                };
            }

            return {
                ...this.processColors,
                width: barSize,
                left: barStart
            };
        },
        inputValue: {
            get () {
                if (this.isDragging) {
                    return this.model[0];
                }

                return this.value;
            },
            set (val) {
                this.updateModel(val, 0);
            }
        },
        model: {
            get () {
                if (this.isDragging) {
                    return [].concat(this.dragValue);
                }

                return [].concat(this.value).reduce((prev, curr) => {
                    if (typeof curr !== 'number' || isNaN(curr)) {
                        return prev.concat(this.min);
                    }

                    return prev.concat(clamp(curr, this.min, this.max));
                }, []);
            },
            set (value) {
                const normalized = this.normalizeValues([].concat(value));

                if (this.isDragging) {
                    // set the drag value to the non-normalized value for smoother interaction
                    this.dragValue = value;

                    if (this.lazy) {
                        return;
                    }
                }

                if (this.range) {
                    this.$emit(
                        'input',
                        normalized.sort((a, b) => a - b)
                    );

                    return;
                }

                this.$emit('input', ...normalized);
            }
        },
        ariaLabel () {
            if (this.$attrs && this.$attrs['aria-label']) {
                return this.$attrs['aria-label'];
            }

            return this.topLabel || this.bottomLabel || this.inlineLabel || undefined;
        }
    },
    mounted () {
        this.resetSize();
        window.addEventListener('resize', this.resetSize);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.resetSize);
    },
    methods: {
        normalizeValues (arr) {
            return arr.map((value) => parseFloat(value.toFixed(this.precision)));
        },
        setDotPositionFromClick (percent) {
            const targetValue = this.min + (percent * (this.max - this.min)) / 100;
            if (!this.range) {
                this.updateModel(targetValue, 0);

                return;
            }
            const closestIndex = this.model
                .sort((a, b) => a - b)
                .reduce((prev, curr) => {
                    return Math.abs(curr - targetValue) < Math.abs(prev - targetValue) ? 1 : 0;
                });

            this.updateModel(targetValue, closestIndex);
        },
        onSliderClick ({ clientX, clientY }) {
            if (this.disabled || this.isDragging) {
                return;
            }

            this.resetSize();

            const { left, bottom } = this.$refs.slider.getBoundingClientRect();

            if (this.vertical) {
                this.setDotPositionFromClick(((bottom - clientY) / this.sliderSize) * 100);

                return;
            }

            this.setDotPositionFromClick(((clientX - left) / this.sliderSize) * 100);
        },
        resetSize () {
            if (this.$refs.slider) {
                this.sliderSize = this.$refs.slider[`client${this.vertical ? 'Height' : 'Width'}`];
            }
        },
        getStopStyle (position) {
            if (this.vertical) {
                return {
                    bottom: `${position}%`
                };
            }

            return {
                left: `${position}%`
            };
        },
        getMarkStyle (mark) {
            const style = this.getStopStyle(mark.position);
            const processColor = this.showProcess ? this.processColors.borderColor : this.defaultBorderColor;
            style['background-color'] = mark.position < this.model[0] ? processColor : this.defaultBorderColor;

            return style;
        },
        updateModel (value, index) {
            if (this.range) {
                // avoid letting dots cross
                const rangeLimit = this.getRangeLimit(index);
                const isOutsideRange = index === 1 ? value < rangeLimit : value > rangeLimit;

                if (isOutsideRange) {
                    value = rangeLimit;
                }
            }

            const model = this.model.slice();
            model[index] = value;
            this.model = model;
        },
        onDragStart () {
            this.dragValue = this.model;
            this.isDragging = true;
            document.body.style.cursor = this.vertical ? 'ns-resize' : 'ew-resize';
            this.$emit('drag-start');
        },
        onDrag (value, index) {
            this.updateModel(value, index);
            this.$emit('drag');
            this.resetSize();
        },
        onDragEnd (value, index) {
            this.isDragging = false;
            document.body.style.cursor = 'unset';
            this.updateModel(value, index);
            this.$emit('drag-end');
        },
        getRangeLimit (index) {
            if (index === 0) {
                return this.model[1] - this.step;
            }

            return this.model[0] + this.step;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-slider) {
    min-height: 36px;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;

    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-gray-110;

        @include modifier(top) {
            font-weight: 600;
            align-items: start;
        }

        @include modifier(bottom) {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    @include modifier(inline-label) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: max-content 1fr;
        align-items: center;
        grid-gap: 8px;

        .pendo-slider__label {
            display: grid;
            align-items: center;
        }

        &.pendo-slider--with-input {
            grid-template-columns: max-content 1fr 80px;

            .pendo-slider__label--top {
                grid-column: initial;
            }
        }
    }

    @include modifier(with-input) {
        grid-template-columns: 1fr 80px;
        grid-column-gap: 16px;
        grid-row-gap: 0;

        .pendo-slider__label--top {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }

    @include modifier(bar) {
        @include element(controls) {
            height: 35px;
        }
        @include element(rail) {
            border: 1px solid $color-gray-40;
            background: $color-white;
        }
        @include element(process) {
            border: 1px solid;
        }

        @include element(stop) {
            background-color: $color-gray-40;
            width: 1px;
            height: 50%;
            top: 25%;
        }
        @include element(marks-stop) {
            width: 1px;
            height: 50%;
            top: 25%;
        }
        @include element(marks-text) {
            margin-top: 35px;
        }
    }

    &.is-dragging {
        cursor: ew-resize;
    }

    @include element(controls) {
        padding: 6.5px 0px;
        width: auto;
        height: 3px;
        position: relative;
        box-sizing: content-box;
        user-select: none;
        display: block;
    }

    @include element(rail) {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: $slider-rail-background-color;
        border-radius: $slider-border-radius;

        &.disabled {
            cursor: default;

            .pendo-slider__dot {
                border-color: $slider-disable-color;
                outline: transparent;
            }

            .pendo-slider__dot-wrapper {
                &:hover,
                &.hover {
                    cursor: not-allowed;
                }

                &.is-dragging {
                    cursor: not-allowed;
                }
            }

            .pendo-slider__dot {
                &:hover,
                &.hover {
                    cursor: not-allowed;
                }

                &.is-dragging {
                    cursor: not-allowed;
                }
            }
        }
    }

    @include element(process) {
        position: absolute;
        z-index: 1;
        background-color: $slider-main-background-color;
        border-radius: 3px;
        height: 100%;
        top: 0px;
        left: 0%;
        width: 50%;
    }

    @include element(input) {
        float: right;
        width: 75px;
    }

    @include element(bar) {
        height: $slider-height;
        background-color: $slider-main-background-color;
        border-top-left-radius: $slider-border-radius;
        border-bottom-left-radius: $slider-border-radius;
        position: absolute;
    }

    @include element(stop) {
        position: absolute;
        height: $slider-height;
        width: $slider-height;
        border-radius: $border-radius-circle;
        background-color: $slider-stop-background-color;
        transform: translateX(-50%);
        z-index: 1;
    }

    @include element(marks) {
        top: 0;
        left: 12px;
        width: 18px;
        height: 100%;

        @include element(marks-text) {
            position: absolute;
            transform: translateX(-50%);
            font-size: 14px;
            color: $color-black;
            margin-top: 15px;
        }
    }

    @include is(vertical) {
        position: relative;

        @include element(process) {
            width: 100%;
            bottom: 0;
            top: unset;
        }

        @include element(rail) {
            width: $slider-height;
            height: 100%;
            margin: 0 16px;
        }

        @include element(bar) {
            width: $slider-height;
            height: auto;
            border-radius: 0 0 3px 3px;
        }

        @include element(dot-wrapper) {
            top: auto;
            left: $slider-button-wrapper-offset;
            transform: translateY(50%);
        }

        @include element(stop) {
            transform: translateY(50%);
        }

        @include element(marks-text) {
            margin-top: 0;
            left: 15px;
            transform: translateY(50%);
        }
    }
}
</style>
