<template>
    <pendo-card
        class="pendo-support-access"
        body-min-height="0">
        <template #title>
            <div class="pendo-support-access-header">
                <h3 class="pendo-support-access-header__title">
                    Support access
                </h3>
                <pendo-icon v-bind="headerIconOptions.headerIcon" />
                <span class="pendo-support-access-header__icon-text">{{ headerIconOptions.headerIconText }}</span>
            </div>
        </template>
        <template #headerRight>
            <div class="pendo-support-access-header__button">
                <pendo-button
                    v-if="showAccessButton"
                    theme="app"
                    type="link"
                    size="mini"
                    label="Turn off access"
                    @click="accessButtonClick" />
            </div>
        </template>
        <div class="pendo-support-access-body">
            <p class="pendo-support-access-body__body-text">
                {{ bodyText }}
            </p>
            <div class="pendo-support-access-body-calendar-section">
                <span class="pendo-support-access-body-calendar-section__calendar-text">{{ calendarText }}</span>
                <pendo-date-picker
                    :value="datePickerValue"
                    :loading="datePickerLoading"
                    :min-date="minAvailableDay"
                    :max-date="maxAvailableDay"
                    @change="changeDate" />
                <span class="pendo-support-access-body-calendar-section__limit-text">{{ calendarLimitText }}</span>
            </div>
        </div>
    </pendo-card>
</template>

<script>
import PendoCard from '@/components/card/pendo-card';
import PendoIcon from '@/components/icon/pendo-icon';
import PendoButton from '@/components/button/pendo-button.vue';
import PendoDatePicker from '@/components/date-picker/pendo-date-picker';
import { addDays } from './utils/helpers';

export default {
    name: 'PendoSupportAccess',
    components: {
        PendoCard,
        PendoIcon,
        PendoButton,
        PendoDatePicker
    },
    props: {
        /**
         * Defines the text to be displayed on the body section.
         */
        bodyText: {
            type: String,
            default:
                'This gives Pendo Technical Support representatives access to the subscription. If enabled, all support tickets from users in this subscription can be addressed without additional action from an Admin. Select a future date to enable or extend access until.'
        },
        /**
         * Defines the text to be displayed before the calendar.
         */
        calendarText: {
            type: String,
            default: 'Access enabled through:'
        },
        /**
         * Defines date picker date value.
         */
        datePickerValue: {
            type: null,
            default: 0
        },
        /**
         * Defines the loading state for the date picker.
         */
        datePickerLoading: {
            type: Boolean,
            default: false
        },
        /**
         * Defines the available start day on the date picker calendar. If available start date is not provided, it is calculated to be current date.
         */
        availableStartDate: {
            type: null,
            default: undefined
        },
        /**
         * Defines the available end day on the date picker calendar. If available end date is not provided, it is calculated to be 90 days from current date.
         */
        availableEndDate: {
            type: null,
            default: undefined
        },
        /**
         * Defines the text for the available date range on the calendar.
         */
        calendarLimitText: {
            type: String,
            default: 'Select up to 90 days (3 months) from today’s date.'
        },
        /**
         * Defines the expiration date. If expiration date is not provided, it is calculated to be a day before current date by end of day.
         */
        expirationDate: {
            type: null,
            default: null
        },
        /**
         * Defines the current date as today or now.
         */
        currentDate: {
            type: null,
            default: () => new Date()
        }
    },
    computed: {
        headerIconOptions () {
            if (!this.datePickerValue) {
                return {
                    headerIcon: { type: 'minus-circle-solid', stroke: '#fff', fill: '#2A2C35' },
                    headerIconText: 'Off'
                };
            }

            const pickerDate = new Date(this.datePickerValue);
            if (this._expirationDate.getTime() < pickerDate.getTime()) {
                return {
                    headerIcon: { type: 'check-circle-2-solid', stroke: '#fff', fill: '#00BA6D' },
                    headerIconText: 'On'
                };
            }

            return {
                headerIcon: { type: 'alert-circle-solid', stroke: '#fff', fill: '#DBA211' },
                headerIconText: 'Expired'
            };
        },
        showAccessButton () {
            return this.headerIconOptions.headerIconText === 'On';
        },
        _expirationDate () {
            if (!this.expirationDate) {
                const currentDate = new Date(this.currentDate);
                const expiration = addDays(currentDate, -1); // A day before the current date
                expiration.setHours(23, 59, 0, 0); // set to end of day

                return expiration;
            }

            return new Date(this.expirationDate);
        },
        minAvailableDay () {
            if (this.availableStartDate === null) {
                return null;
            }
            if (!this.availableStartDate) {
                const current = new Date(this.currentDate);

                return addDays(current, 0);
            }

            return this.availableStartDate;
        },
        maxAvailableDay () {
            if (this.availableEndDate === null) {
                return null;
            }

            if (!this.availableEndDate) {
                const current = new Date(this.currentDate);

                return addDays(current, 89);
            }

            return this.availableEndDate;
        }
    },
    methods: {
        changeDate (date) {
            if (!date) {
                return;
            }
            this.$emit('change-date', date);
        },
        accessButtonClick () {
            this.$emit('access-button-click', this._expirationDate);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-support-access-header) {
    display: flex;
    align-items: center;
    gap: 8px;

    @include element(icon-text) {
        font-weight: 600;
        font-size: 16px;
        color: $color-text-primary;
    }

    @include element(button) {
        display: flex;
        flex: auto;
        justify-content: right;
        margin-right: 8px;
    }
}

@include block(pendo-support-access-body) {
    @include element(body-text) {
        font-size: 14px;
        line-height: 1.4;
        margin-top: 0;
    }
}

@include block(pendo-support-access-body-calendar-section) {
    display: flex;
    align-items: center;
    gap: 8px;

    @include element(calendar-text) {
        font-weight: 600;
        font-size: 14px;
        color: $color-text-primary;
    }

    @include element(limit-text) {
        font-weight: 400;
        font-size: 14px;
        color: $color-text-primary;
    }
}
</style>
