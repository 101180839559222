<template>
    <button
        ref="trigger"
        type="button"
        class="pendo-date-picker__trigger"
        :aria-label="state.isPopoverVisible ? 'close picker' : 'open picker'"
        aria-haspopup
        :class="[
            `pendo-date-picker__trigger--mini`,
            `pendo-date-picker__trigger--data-measure`,
            {
                'is-active': state.focused || state.isPopoverVisible,
                'is-open': state.isPopoverVisible,
                'is-disabled': state.disabled || state.loading,
                'is-error': state.invalid
            }
        ]"
        :disabled="state.disabled"
        @click="state.togglePopover"
        @keyup.esc="state.hidePopover"
        @focus="state.focused = true"
        @blur="state.focused = false">
        <div class="pendo-date-picker__prefix">
            <pendo-icon
                type="calendar"
                size="14" />
        </div>
        <div class="pendo-date-picker__value">
            <span
                class="pendo-date-picker__selected-value"
                v-text="state.inputValue || state.placeholder" />
        </div>
        <div
            class="pendo-date-picker__suffix"
            @click.stop="state.togglePopover">
            <pendo-icon
                v-if="!state.loading"
                type="chevron-down"
                size="14"
                class="pendo-date-picker__caret"
                :stroke-width="2.5"
                :class="{
                    'is-active': state.isPopoverVisible
                }" />
            <pendo-loading-indicator
                v-if="state.loading"
                type="material"
                size="small" />
        </div>
    </button>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';
import { setStyles } from '@/utils/dom';

export default {
    name: 'PendoDatePickerDataMeasureTrigger',
    components: {
        PendoIcon,
        PendoLoadingIndicator
    },
    inject: ['state'],
    watch: {
        'state.maxTriggerWidth': function () {
            setStyles(this.$refs.trigger, { maxWidth: this.state.maxTriggerWidth });
        }
    },
    mounted () {
        setStyles(this.$refs.trigger, {
            minWidth: this.state.fullWidth ? '100%' : 0,
            maxWidth: this.state.maxTriggerWidth
        });
    }
};
</script>

<style lang="scss">
@include block(pendo-date-picker) {
    @include element(trigger) {
        @include modifier(data-measure) {
            border: none;
            background: $color-white;
            padding: 0;
            color: $color-link;
            font-weight: 600;
            margin-left: 1px;

            @include is(disabled) {
                cursor: not-allowed;
                opacity: $disabled-opacity;
                color: $disabled-color;
                background: none;
            }

            &:after {
                content: '';
                border-bottom: 1px solid transparent;
                transition: border 100ms;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            &:hover,
            &.is-active,
            &.is-open {
                border: none;
                color: $color-link-hover;

                &:after {
                    border-color: $color-link-hover;
                }
            }
        }
    }
}
</style>
