<template>
    <button
        ref="trigger"
        type="button"
        class="pendo-multiselect__trigger"
        aria-haspopup
        :disabled="multiselect.disabled"
        :aria-expanded="`${multiselect.isOpen}`"
        :class="[
            `pendo-multiselect__trigger--small`,
            `pendo-multiselect__trigger--chart`,
            {
                'is-active': multiselect.isFocused || multiselect.isOpen,
                'is-open': multiselect.isOpen,
                'is-disabled': multiselect.disabled
            }
        ]"
        @focus="multiselect.handleFocus"
        @blur="multiselect.handleBlur"
        @click="multiselect.handleTriggerClick"
        @keyup.esc="multiselect.deactivate"
        @keydown.tab="multiselect.handleKeydown('tab')"
        @keydown.up.prevent.self="multiselect.handleKeydown('up')"
        @keydown.enter="multiselect.handleKeydown('enter', $event)"
        @keydown.down.prevent.self="multiselect.handleKeydown('down')"
        @keyup.space.prevent.self.stop="multiselect.handleKeydown('space', $event)">
        <pendo-multiselect-value
            v-bind="{
                getOptionLabel: multiselect.getOptionLabel,
                isPlaceholderVisible: multiselect.isPlaceholderVisible,
                isSelectedLabelVisible: multiselect.isSelectedLabelVisible,
                placeholder: multiselect.placeholder,
                selectedLabel: multiselect.selectedLabel,
                selectedValue: multiselect.selectedValue
            }"
            :class="{
                'is-disabled': multiselect.disabled
            }" />
        <div class="pendo-multiselect__suffix">
            <pendo-icon
                v-if="!multiselect.loading"
                type="chevron-down"
                size="18"
                aria-hidden="true"
                class="pendo-multiselect__caret"
                :class="{
                    'is-active': multiselect.isOpen
                }" />
        </div>
    </button>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoMultiselectValue from '@/components/multiselect/pendo-multiselect-value';
import { setStyles } from '@/utils/dom';

export default {
    name: 'PendoChartTrigger',
    components: {
        PendoIcon,
        PendoMultiselectValue
    },
    inject: ['multiselect'],
    props: {
        theme: {
            type: String,
            default: 'app'
        }
    },
    watch: {
        'multiselect.isOpen': function (isOpen) {
            if (isOpen) {
                this.$el.focus();
            }
        },
        'multiselect.maxTriggerWidth': function () {
            setStyles(this.$refs.trigger, { maxWidth: this.multiselect.maxTriggerWidth });
        }
    },
    mounted () {
        setStyles(this.$refs.trigger, {
            minWidth: this.multiselect.isFullWidth ? '100%' : 'auto',
            maxWidth: this.multiselect.maxTriggerWidth,
            borderBottomColor: this.theme === 'app' ? '#128297' : '#2A2C35'
        });
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(trigger) {
        @include modifier(chart) {
            border: none;
            background: $color-white;
            padding: 0;
            font-size: 18px;

            &:after {
                content: '';
                border-color: inherit;
                border-bottom-width: 1px;
                border-bottom-style: solid;
                transition: border 100ms, transform 100ms;

                position: absolute;
                transform: translateY(0px);
                bottom: 3px;
                right: 2px;
                left: 1px;
            }

            &:hover,
            &.is-active,
            &.is-open {
                border: none;

                &:after {
                    transform: translateY(3px);
                    border-bottom-width: 2px;
                }
            }

            @include is(disabled) {
                cursor: not-allowed;
                opacity: 0.7;
                color: $color-gray-70;
                background: none;
            }

            @include element((placeholder, selected)) {
                line-height: 26px;
                font-size: 18px;
                font-weight: 600;
                user-select: none;
                height: 100%;
                display: grid;
                grid-gap: 8px;
                grid-auto-columns: auto;
                grid-auto-flow: column;
                align-items: center;
                overflow: hidden;

                @include element(label) {
                    @include ellipsis;
                }
            }

            @include element(caret) {
                @include is(active) {
                    transform: rotateZ(180deg);
                }
            }

            @include element(suffix) {
                width: 24px;
            }
        }
    }
}
</style>
