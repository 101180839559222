<template>
    <div class="expansion-filters-container">
        <div class="filters">
            <pendo-multiselect-filter
                :value="errorTypes"
                data-cy="alert-type-filter"
                :options="filterOptions.errorTypes"
                entity-name="Alert Types"
                @input="updateErrorTypeFilter" />
            <pendo-multiselect-filter
                :value="alertStatuses"
                data-cy="alert-status-filter"
                :options="filterOptions.alertStatuses"
                label-key="name"
                entity-name="Alert Statuses"
                @input="updateAlertFilter" />
        </div>
        <div class="clear-filters">
            <pendo-button
                v-if="areFiltersSet"
                data-cy="clear-filters"
                theme="app"
                type="link"
                label="Clear Filters"
                @click="clearFilters" />
        </div>
    </div>
</template>
<script>
import PendoMultiselectFilter from '@/composites/multiselect-filter/pendo-multiselect-filter.vue';
import PendoButton from '@/components/button/pendo-button.vue';
import get from 'lodash/get';

export default {
    name: 'PendoGuideAlertsFilterBar',
    components: {
        PendoMultiselectFilter,
        PendoButton
    },
    props: {
        filterConfig: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            filterOptions: {
                alertStatuses: [
                    {
                        id: 'NotReviewed',
                        name: 'Not Reviewed'
                    },
                    {
                        id: 'Triage',
                        name: 'Triage'
                    },
                    {
                        id: 'Resolved',
                        name: 'Resolved'
                    }
                ],
                errorTypes: ['Page Mismatch', 'Element Missing', 'Other']
            },
            errorTypes: [],
            alertStatuses: []
        };
    },
    computed: {
        filters () {
            const { errorTypes, alertStatuses } = this;

            const filters = [
                {
                    prop: 'errorType',
                    value: errorTypes
                },
                {
                    prop: 'alertStatus',
                    value: alertStatuses.map((status) => status.id)
                }
            ];

            return filters;
        },
        areFiltersSet () {
            return !!this.errorTypes.length || !!this.alertStatuses.length;
        }
    },
    watch: {
        filters: {
            immediate: true,
            handler () {
                this.$emit('on-update-filters', this.filters);
            }
        }
    },
    created () {
        const filters = get(this.filterConfig, 'value', {});
        this.errorTypes = get(filters, 'errorTypes', []);
        this.alertStatuses = get(filters, 'alertStatuses', []);
    },
    methods: {
        updateErrorTypeFilter ($event) {
            this.errorTypes = $event;
            this.emitExpansionFiltersUpdate();
        },
        updateAlertFilter ($event) {
            this.alertStatuses = $event;
            this.emitExpansionFiltersUpdate();
        },
        emitExpansionFiltersUpdate () {
            const filters = {
                alertStatuses: this.alertStatuses,
                errorTypes: this.errorTypes
            };

            const settings = {
                name: 'displayAlertExpansionFilters',
                namespaced: true,
                value: filters,
                force: true
            };

            this.$emit('update-user-settings', settings);
        },
        clearFilters () {
            this.errorTypes = [];
            this.alertStatuses = [];

            this.emitExpansionFiltersUpdate();
        }
    }
};
</script>
<style lang="scss" scoped>
.filters {
    display: flex;
    width: 100%;
    gap: 16px;
    margin: 4px auto;
}
.clear-filters {
    margin-left: auto;
    margin-right: 4px;
}
.expansion-filters-container {
    display: flex;
    width: 100%;
}
</style>
