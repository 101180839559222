<template>
    <div class="pendo-table__group">
        <svg
            v-if="color"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="12"
            height="12">
            <rect
                width="12"
                height="12"
                x="0"
                y="0"
                :fill="color"
                rx="2" />
        </svg>
        <div class="pendo-table__group-name">
            {{ name }}
        </div>
    </div>
</template>

<script>
import { PRODUCT_AREA_COLORS } from '@/utils/color';

export default {
    name: 'PendoGroupCell',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        name () {
            return this.row.group.name || 'No Product Area';
        },
        color () {
            if (this.row.group && this.row.group.color) {
                let { color } = this.row.group;
                if (color.includes('.')) {
                    color = color.substr(1);
                }

                return PRODUCT_AREA_COLORS[color];
            }

            return PRODUCT_AREA_COLORS.groupColorUngrouped;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(group) {
        cursor: default;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 12px 1fr;
        align-items: center;
    }

    @include element(group-name) {
        @include ellipsis;
    }
}
</style>
