<template>
    <div
        class="pendo-metric"
        :class="[`pendo-metric--${size}`, `pendo-metric--${alignment}`, { 'is-loading': loading }]">
        <div
            v-if="topLabel || $slots.topLabel || icon"
            class="pendo-metric__top-label">
            <div
                v-if="icon"
                class="pendo-metric__icon-container"
                :class="{ 'is-circle': isCircleIcon }"
                :style="iconContainerStyles">
                <pendo-icon v-bind="iconConfig" />
            </div>
            <slot name="topLabel">
                <span
                    ref="topLabel"
                    v-pendo-tooltip="{ content: topLabel, trigger: 'manual', show: showOverflowTooltip('topLabel') }"
                    class="pendo-metric__top-label--text"
                    @mouseenter="onOptionMouseenter('topLabel')"
                    @focus="onOptionMouseenter('topLabel')"
                    @mouseleave="onOptionMouseleave"
                    @blur="onOptionMouseleave">
                    {{ topLabel }}
                </span>
            </slot>
        </div>
        <div
            v-if="topSublabel || $slots.topSublabel"
            class="pendo-metric__top-sublabel">
            <slot name="topSublabel">
                <span
                    ref="topSublabel"
                    v-pendo-tooltip="{
                        content: topSublabel,
                        trigger: 'manual',
                        show: showOverflowTooltip('topSublabel')
                    }"
                    class="pendo-metric__top-sublabel--text"
                    @mouseenter="onOptionMouseenter('topSublabel')"
                    @focus="onOptionMouseenter('topSublabel')"
                    @mouseleave="onOptionMouseleave"
                    @blur="onOptionMouseleave">
                    {{ topSublabel }}
                </span>
            </slot>
        </div>
        <div class="pendo-metric__container">
            <div
                v-pendo-loading="{
                    loading,
                    skeletonProps: { height: loadingOverlayHeight }
                }"
                :style="{ color }"
                class="pendo-metric__value">
                <slot
                    name="value"
                    :formatted-value="formattedValue">
                    {{ formattedValue }}
                </slot>
            </div>
            <div
                v-if="showTrending && alignment === 'left'"
                class="pendo-metric__trending">
                <div
                    v-pendo-loading="{
                        loading,
                        skeletonProps: { height: trendingLoadingOverlayHeight }
                    }"
                    class="pendo-metric__trending-label"
                    :class="[`trending-${trendingColor}`]">
                    {{ trendingLabel }}
                </div>
            </div>
        </div>
        <div
            v-if="label || $slots.label"
            class="pendo-metric__label">
            <slot name="label">
                <span
                    ref="label"
                    v-pendo-tooltip="{ content: label, trigger: 'manual', show: showOverflowTooltip('label') }"
                    class="pendo-metric__label--text"
                    @mouseenter="onOptionMouseenter('label')"
                    @focus="onOptionMouseenter('label')"
                    @mouseleave="onOptionMouseleave"
                    @blur="onOptionMouseleave">
                    {{ label }}
                </span>
            </slot>
        </div>
        <div
            v-if="sublabel || $slots.sublabel"
            class="pendo-metric__sublabel">
            <slot name="sublabel">
                <span
                    ref="sublabel"
                    v-pendo-tooltip="{ content: sublabel, trigger: 'manual', show: showOverflowTooltip('sublabel') }"
                    class="pendo-metric__sublabel--text"
                    @mouseenter="onOptionMouseenter('sublabel')"
                    @focus="onOptionMouseenter('sublabel')"
                    @mouseleave="onOptionMouseleave"
                    @blur="onOptionMouseleave">
                    {{ sublabel }}
                </span>
            </slot>
        </div>
        <div
            v-if="showTrending && alignment === 'center'"
            class="pendo-metric__trending">
            <div
                v-pendo-loading="{
                    loading,
                    skeletonProps: { height: trendingLoadingOverlayHeight }
                }"
                class="pendo-metric__trending-label"
                :class="[`trending-${trendingColor}`]">
                {{ trendingLabel }}
            </div>
        </div>
    </div>
</template>

<script>
import numeral from 'numeral';
import PendoLoading from '@/directives/loading/pendo-loading';
import PendoIcon from '@/components/icon/pendo-icon.vue';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import { isValidHex, getContrastingColor } from '@/utils/color';
import { shouldAttachOverflowTooltip } from '@/utils/dom';

export default {
    name: 'PendoMetric',
    components: {
        PendoIcon
    },
    directives: {
        PendoLoading,
        PendoTooltip
    },
    props: {
        /**
         * Determines metric size
         * @values large, medium, small, mini
         */
        size: {
            type: String,
            default: 'medium',
            validator: (size) => ['large', 'medium', 'small', 'mini'].includes(size)
        },
        /**
         * value to display
         */
        value: {
            type: [Number, String],
            default: null
        },
        /**
         * optionally leverage [numeral](http://numeraljs.com) to format passed in value
         */
        format: {
            type: String,
            default: null
        },
        /**
         * label text that appears underneath metric
         */
        label: {
            type: String,
            default: null
        },
        /**
         * Secondary label to display below value or primary label
         */
        sublabel: {
            type: String,
            default: null
        },
        /**
         * Top label to display above value
         */
        topLabel: {
            type: String,
            default: null
        },
        /**
         * Top sublabel to display below top label
         */
        topSublabel: {
            type: String,
            default: null
        },
        /**
         * Trending value
         */
        trendingLabel: {
            type: String,
            default: null
        },
        /**
         * color of trending label
         * @values green, orange, gray
         */
        trendingColor: {
            type: String,
            default: null,
            validator: (trendingColor) => ['green', 'orange', 'gray'].includes(trendingColor)
        },
        /**
         * color of primary text
         */
        color: {
            type: String,
            default: '#2a2c35'
        },
        /**
         * toggles skeleton loading state
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * Determines metric alignment.
         * @values center, left, inline
         */
        alignment: {
            type: String,
            default: 'center',
            validator: (alignment) => ['center', 'left', 'inline'].includes(alignment)
        },
        /**
         * icon type to display next to top label
         */
        icon: {
            type: String,
            default: null
        },
        /**
         * sets color of icon background. When icon is set to 'circle', this will be the fill color
         */
        iconBackgroundColor: {
            type: String,
            default: null,
            validator: (color) => isValidHex(color)
        }
    },
    data () {
        return {
            tooltipTimeout: null,
            activeTooltip: null
        };
    },
    computed: {
        formattedValue () {
            return this.format ? numeral(this.value).format(this.format) : this.value;
        },
        isCircleIcon () {
            return this.icon === 'circle' || this.icon === 'circle-striped';
        },
        iconConfig () {
            if (this.isCircleIcon) {
                return {
                    type: this.icon,
                    fill: this.iconBackgroundColor,
                    stroke: this.iconBackgroundColor,
                    size: 10
                };
            }

            return {
                type: this.icon,
                stroke: this.iconBackgroundColor ? getContrastingColor(this.iconBackgroundColor) : '#2A2C35',
                strokeWidth: 2.5,
                size: 12
            };
        },
        iconContainerStyles () {
            if (!this.isCircleIcon && this.iconBackgroundColor) {
                return { backgroundColor: this.iconBackgroundColor };
            }

            return {};
        },
        showTrending () {
            return this.trendingLabel && this.trendingColor;
        },
        trendingLoadingOverlayHeight () {
            switch (this.size) {
                case 'large':
                    return 32;
                case 'medium':
                    return 30;
                case 'small':
                    return 24;
                default:
                    return 18;
            }
        },
        loadingOverlayHeight () {
            switch (this.size) {
                case 'large':
                    return 36;
                case 'medium':
                    return 32;
                case 'small':
                    return 28;
                default:
                    return 20;
            }
        }
    },
    methods: {
        onOptionMouseenter (labelType) {
            const showOverflowTooltip = shouldAttachOverflowTooltip(this[labelType], this.$refs[labelType]);

            if (showOverflowTooltip) {
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = setTimeout(() => {
                    this.activeTooltip = labelType;
                }, 300);
            }
        },
        onOptionMouseleave () {
            clearTimeout(this.tooltipTimeout);
            this.activeTooltip = null;
        },
        showOverflowTooltip (labelType) {
            return this.activeTooltip === labelType;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-metric) {
    display: grid;
    align-items: center;
    justify-items: center;
    width: fit-content;
    @include font-family;

    @include element(container) {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    @include element(icon-container) {
        margin-right: 8px;
    }

    &__icon-container:not(.is-circle) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin-right: 4px;
    }

    @include element(value) {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 26px;
        min-height: 36px;
        gap: 4px;
    }

    @include is(loading) {
        @include element(value) {
            min-width: 60px;
        }
    }

    @include element((label, sublabel, trending-label, top-label, top-sublabel)) {
        font-size: 14px;
        line-height: 22px;
    }

    @include element((value, label, top-label)) {
        color: $color-text-primary;
    }

    @include element((sublabel, top-sublabel)) {
        color: $color-text-secondary;
    }

    @include element(trending-label) {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border-radius: 3px;
        padding: 4px 8px;

        &.trending-gray {
            background-color: $color-gray-30;
            color: $color-gray-100;
        }

        &.trending-orange {
            background-color: $color-orange-20;
            color: $color-orange-110;
        }

        &.trending-green {
            background-color: $color-green-10;
            color: $color-green-110;
        }
    }

    @include element(top-label) {
        display: flex;
        align-items: center;
        @include modifier(text) {
            @include lineClamp(1);
        }
    }

    @include element(top-sublabel) {
        @include modifier(text) {
            @include lineClamp(1);
        }
    }

    @include element(label) {
        @include modifier(text) {
            @include lineClamp(1);
        }
    }

    @include element(sublabel) {
        @include modifier(text) {
            @include lineClamp(1);
        }
    }

    @include element((top-label, top-sublabel)) {
        font-weight: 600;
    }

    @include modifier(large) {
        @include element(value) {
            font-size: 32px;
            min-height: 40px;
        }

        @include is(loading) {
            @include element(value) {
                min-width: 68px;
            }
        }
    }

    @include modifier(small) {
        @include element(value) {
            font-size: 22px;
            min-height: 30px;
        }

        @include element((label, sublabel, trending-label, top-label, top-sublabel)) {
            line-height: 20px;
        }

        @include is(loading) {
            @include element(value) {
                min-width: 48px;
            }
        }

        @include element(trending-label) {
            font-size: 12px;
            padding: 2px 6px;
        }
    }

    @include modifier(mini) {
        @include element(container) {
            gap: 8px;
        }

        @include element(value) {
            font-size: 16px;
            min-height: 24px;
        }

        @include element((label, sublabel, top-label, top-sublabel)) {
            font-size: 12px;
            line-height: 18px;
        }

        @include is(loading) {
            @include element(value) {
                min-width: 36px;
            }
        }

        @include element(trending-label) {
            font-size: 10px;
            height: 18px;
            padding: 0 6px;
        }
    }

    @include modifier(center) {
        @include element(trending) {
            padding-top: 8px;
        }
    }

    @include modifier(inline) {
        display: inline-flex;

        @include element((label, sublabel, value)) {
            margin-left: 4px;
        }
    }

    @include modifier(left) {
        justify-items: start;

        @include element(value) {
            justify-content: start;
        }
    }

    .pendo-loading {
        align-items: center;
    }
}
</style>
