<template>
    <div
        class="pendo-table__status"
        :class="{
            'pendo-table__status--interactive': interactive
        }">
        <pendo-status-trigger
            v-if="!isOpen"
            :value="status"
            :status-color-map="statusColorMap"
            :read-only="!interactive"
            @[clickListener]="toggleMultiselect" />
        <pendo-multiselect
            v-if="isOpen"
            ref="multiselect"
            full-width
            :extra-label-width="61"
            :value="status"
            :allow-empty="false"
            :min-menu-width="110"
            value-key="state"
            :options="options"
            @select="handleSelect"
            @closed="isOpen = false">
            <template #trigger>
                <pendo-status-trigger :status-color-map="statusColorMap" />
            </template>
            <template #option="{ option }">
                <pendo-status-option
                    :option="option"
                    :status-color-map="statusColorMap" />
            </template>
        </pendo-multiselect>
    </div>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoStatusOption from '@/components/multiselect/option-types/pendo-status-option';
import PendoStatusTrigger from '@/components/multiselect/trigger-types/pendo-status-trigger';
import { STATUS_COLOR_MAP } from '@/utils/color';

export default {
    name: 'PendoStatusCell',
    components: {
        PendoMultiselect,
        PendoStatusTrigger,
        PendoStatusOption
    },
    inject: ['$table'],
    props: {
        row: {
            type: Object,
            required: true
        },
        options: {
            type: Array,
            default: () => []
        },
        statusColorMap: {
            type: Object,
            default: () => STATUS_COLOR_MAP
        },
        interactive: {
            type: Boolean,
            default: true
        }
    },
    emits: ['status-change'],
    data () {
        return {
            isOpen: false
        };
    },
    computed: {
        status () {
            return this.options.find(({ state }) => state === this.row.state) || {};
        },
        clickListener () {
            if (this.interactive) {
                return 'click';
            }

            return null;
        }
    },
    watch: {
        '$table.scrollStore.scrollActive': function (value) {
            if (value) {
                this.isOpen = false;
            }
        }
    },
    methods: {
        handleSelect ({ state }) {
            this.$emit('status-change', { row: this.row, value: state });
        },
        async toggleMultiselect () {
            if (!this.interactive) {
                return;
            }

            this.isOpen = true;
            await this.$nextTick();

            if (this.$refs.multiselect) {
                this.$refs.multiselect.toggleMenu();
            }
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-table) {
    @include element(status) {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        min-width: 70px;
        max-width: 100%;
        min-height: 26px;
        text-transform: capitalize;

        .pendo-multiselect__trigger {
            cursor: default;
            justify-content: space-between;
            will-change: contents;
        }

        .pendo-multiselect__trigger--status {
            transition: none;
        }

        .pendo-multiselect__trigger--readonly {
            padding-left: 0;
        }

        @include modifier(interactive) {
            display: inline-block;
            user-select: none;
            min-width: 92px;

            .pendo-multiselect__trigger {
                cursor: pointer;
            }
        }
    }
}
</style>
