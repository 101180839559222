<template>
    <div
        class="pendo-slider__marks-text"
        :style="mark.style">
        {{ label }}
    </div>
</template>

<script>
export default {
    name: 'SliderMark',
    props: {
        mark: {
            type: [String, Number, Object],
            default: null
        }
    },
    computed: {
        label () {
            if (typeof this.mark === 'string') {
                return this.mark;
            }

            return this.mark.label;
        }
    }
};
</script>
