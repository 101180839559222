<template>
    <div
        class="pendo-multiselect-option--status"
        :class="{
            [`pendo-multiselect-option--${color}`]: color
        }"
        v-bind="$attrs"
        v-on="$listeners">
        <div
            v-pendo-tooltip="{
                content: tooltipContent,
                delay: { show: 300, hide: 0 }
            }"
            :class="[
                'pendo-multiselect-option__prefix',
                {
                    'pendo-multiselect-option__prefix--fill': useDefaultFill
                }
            ]">
            <pendo-icon
                v-bind="icon"
                aria-hidden="true" />
        </div>
        <span
            ref="label"
            v-pendo-tooltip="{
                content: label,
                multiline: true,
                trigger: 'manual',
                offset: 0,
                show: showOverflowTooltip
            }"
            class="pendo-multiselect-option__label"
            @mouseenter="onOptionMouseenter"
            @focus="onOptionMouseenter"
            @mouseleave="onOptionMouseleave"
            @blur="onOptionMouseleave">
            {{ label }}
        </span>
    </div>
</template>

<script>
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIcon from '@/components/icon/pendo-icon';
import { shouldAttachOverflowTooltip } from '@/utils/dom';
import { STATUS_COLOR_MAP } from '@/utils/color';

export default {
    name: 'PendoStatusOption',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inject: {
        multiselect: {
            default: {
                getOptionLabel: () => {},
                labelKey: 'label',
                valueKey: 'state'
            }
        }
    },
    props: {
        option: {
            type: [Object, String],
            required: true
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        statusColorMap: {
            type: Object,
            default: () => STATUS_COLOR_MAP
        }
    },
    data () {
        return {
            showOverflowTooltip: false,
            tooltipTimeout: null
        };
    },
    computed: {
        label () {
            return this.multiselect.getOptionLabel(this.option) || this.option[this.multiselect.labelKey];
        },
        value () {
            return this.option.state || this.option[this.multiselect.valueKey];
        },
        color () {
            return this.statusColorMap[this.value] || 'gray';
        },
        icon () {
            return (
                this.option.icon || {
                    type: 'circle',
                    size: 8
                }
            );
        },
        useDefaultFill () {
            return this.icon.fill === undefined;
        },
        tooltipContent () {
            if (this.label && this.tooltip) {
                return this.label;
            }

            return null;
        }
    },
    methods: {
        onOptionMouseenter () {
            const showOverflowTooltip = shouldAttachOverflowTooltip(this.label, this.$refs.label);

            if (showOverflowTooltip) {
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = setTimeout(() => {
                    this.showOverflowTooltip = true;
                }, 300);
            }
        },
        onOptionMouseleave () {
            clearTimeout(this.tooltipTimeout);
            this.showOverflowTooltip = false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(status) {
        @include font-base;
        line-height: 26px;
        user-select: none;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 4px;
        align-items: center;
        overflow: hidden;

        @include element(prefix) {
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @include element(label) {
            @include ellipsis;
        }

        @each $color-key in map-keys($status-color-map) {
            $primary: map-fetch($status-color-map, $color-key primary);

            &.pendo-multiselect-option--#{$color-key} {
                .pendo-multiselect-option__prefix svg {
                    stroke: $primary;
                }

                .pendo-multiselect-option__prefix--fill svg {
                    fill: $primary;
                }
            }
        }
    }
}
</style>
