<template>
    <pendo-button-group>
        <pendo-button
            :type="type"
            :theme="theme"
            :size="size"
            :label="label"
            :prefix-icon="prefixIcon"
            :loading="loading"
            :disabled="multiselect.disabled"
            @click="onButtonClick" />
        <pendo-button
            ref="trigger"
            aria-haspopup
            :type="type"
            :theme="theme"
            :size="size"
            :disabled="multiselect.disabled"
            :aria-expanded="`${multiselect.isOpen}`"
            class="pendo-multiselect__trigger"
            :class="[
                `pendo-multiselect__trigger--split-button`,
                {
                    'is-active': multiselect.isFocused || multiselect.isOpen,
                    'is-open': multiselect.isOpen
                }
            ]"
            @focus="multiselect.handleFocus"
            @blur="multiselect.handleBlur"
            @click="multiselect.handleTriggerClick"
            @keydown.up.prevent.self="multiselect.handleKeydown('up')"
            @keydown.down.prevent.self="multiselect.handleKeydown('down')"
            @keydown.tab="multiselect.handleKeydown('tab')"
            @keydown.enter="multiselect.handleKeydown('enter', $event)"
            @keyup.esc="multiselect.deactivate"
            @keyup.space.prevent.self.stop="multiselect.handleKeydown('space', $event)">
            <div class="pendo-multiselect__suffix">
                <pendo-icon
                    v-if="!multiselect.loading"
                    type="chevron-down"
                    size="14"
                    aria-hidden="true"
                    class="pendo-multiselect__caret"
                    :class="{
                        'is-active': multiselect.isOpen
                    }" />
            </div>
        </pendo-button>
    </pendo-button-group>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import PendoButtonGroup from '@/components/button-group/pendo-button-group';
import PendoButton from '@/components/button/pendo-button';
import PendoIcon from '@/components/icon/pendo-icon';
import { setStyles } from '@/utils/dom';

export default {
    name: 'PendoSplitButtonTrigger',
    components: {
        PendoButton,
        PendoButtonGroup,
        PendoIcon
    },
    inject: ['multiselect'],
    props: {
        /**
         * Type of button
         * @values primary, secondary
         */
        type: {
            type: String
        },
        /**
         * Theme to apply
         * @values app, p2-dark
         */
        theme: {
            type: String
        },
        /**
         * Size of button
         * @values medium, small, mini
         */
        size: {
            type: String
        },
        /**
         * Text to display inside of the button
         */
        label: {
            type: String
        },
        /**
         * places icon to the left of the passed in label prop
         */
        prefixIcon: {
            type: String
        },
        /**
         * when true, shows a loading indicator inside of the button
         */
        loading: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        'multiselect.isOpen': function (isOpen) {
            if (isOpen && this.$refs.trigger && this.$refs.trigger.$el) {
                this.$refs.trigger.$el.focus();
            }
        }
    },
    async mounted () {
        await this.$nextTick();
        if (this.$refs.trigger && this.$refs.trigger.$el) {
            setStyles(this.$refs.trigger.$el, {
                minWidth: '0px',
                width: '26px'
            });
        }
    },
    methods: {
        onButtonClick (event) {
            if (this.multiselect.isOpen) {
                this.multiselect.toggleMenu();
            }

            this.$emit('click', event);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(trigger) {
        @include modifier(split-button) {
            &:focus-visible {
                @include focus-ring($style: 'focused');
            }

            &:hover,
            &.is-active,
            &.is-open {
                transition-property: all;
                transition-duration: 0.2s;
            }

            @include element(suffix) {
                width: 24px;
            }
        }
    }
}
</style>
