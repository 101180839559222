<template>
    <div class="pendo-drawer__close">
        <pendo-button
            theme="app"
            type="tertiary"
            aria-label="close"
            icon="x"
            icon-size="20"
            v-bind="$attrs"
            @click="$drawer.close" />
    </div>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';

export default {
    name: 'PendoDrawerClose',
    components: {
        PendoButton
    },
    inject: ['$drawer']
};
</script>

<style lang="scss">
@include block(pendo-drawer) {
    @include element(close) {
        cursor: pointer;

        .app.pendo-button--tertiary {
            color: $close-icon-color;

            &:hover,
            &:focus {
                color: $close-icon-hover-color;
            }
        }
    }
}
</style>
