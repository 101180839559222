<script>
export default {
    inject: ['$table'],
    props: {
        column: {
            type: Object,
            required: true
        },
        // Content is either a primitive (String/Number), or an array of VNodes
        content: {
            type: [String, Number, Array],
            required: true
        },
        row: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleClick () {
            this.$table.$emit('link-click', { row: this.row, column: this.column });
        },
        generateRouteLink () {
            let tag = 'a';
            const data = {
                attrs: {
                    tabindex: this.$attrs.tabindex
                },
                class: 'pendo-table__link',
                props: {},
                [this.column.to ? 'nativeOn' : 'on']: {
                    click: this.handleClick
                },
                ref: 'link'
            };

            if (this.column.href) {
                let { href } = this.column;
                if (typeof href === 'function') {
                    href = this.column.href(this.row);
                }

                if (!href) {
                    tag = 'div';
                }

                data.attrs.href = href;
            }

            if (this.column.to) {
                tag = 'router-link';
                const type = typeof this.column.to;
                let to;
                let exact;
                if (['object', 'string'].includes(type)) {
                    to = this.column.to;
                } else if (type === 'function') {
                    to = this.column.to(this.row);
                }

                if (typeof this.column.exact === 'undefined') {
                    exact =
                        this.column.to === '/' ||
                        (this.column.to === Object(this.column.to) && this.column.to.path === '/');
                }

                if (!to) {
                    tag = 'div';
                }

                Object.assign(data.props, {
                    to,
                    exact,
                    append: this.column.append,
                    replace: this.column.replace
                });
            }

            if (this.column.target) {
                data.attrs.target = this.column.target;
            }

            return { tag, data };
        }
    },
    render (createElement) {
        const { tag, data } = this.generateRouteLink();

        return createElement(tag, tag === 'div' ? null : data, this.content);
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(link) {
        display: block;
        font-weight: 600;
        text-decoration: none;
        color: $color-link;
        @include ellipsis;

        &:hover {
            color: $color-link-hover;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
</style>
