<template>
    <div
        :class="{ 'pendo-swatches__container--condensed': condensed }"
        class="pendo-swatches__container">
        <div
            v-for="(color, i) in swatch"
            :key="`${color}-${i}`"
            :style="{ backgroundColor: color }"
            class="pendo-swatches__swatch" />
    </div>
</template>

<script>
export default {
    name: 'PendoThemeSwatch',
    props: {
        swatch: {
            type: Array,
            required: true
        },
        condensed: {
            default: false,
            type: Boolean
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-swatches) {
    @include element(container) {
        border-radius: 3px;
        border: 1px solid $border-color-base;
        display: flex;
        height: 36px;
        align-items: center;
        overflow: hidden;
        @include modifier(condensed) {
            height: 24px;
        }
    }
    @include element(swatch) {
        width: 100%;
        height: 100%;
    }
}
</style>
