<template>
    <div
        class="pendo-table__star"
        role="button"
        :aria-label="ariaLabel"
        :class="{
            'is-starred': row.starred
        }"
        @click="handleStarClick">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16">
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M8 1.3l2 4.2 4.7.7-3.4 3.2.8 4.6L8 11.8 3.9 14l.8-4.6-3.4-3.2L6 5.5 8 1.3z" />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'PendoStarCell',
    props: {
        row: {
            type: Object,
            required: true
        }
    },
    computed: {
        ariaLabel () {
            return this.row.starred ? 'starred' : 'not starred';
        }
    },
    methods: {
        handleStarClick () {
            this.$emit('star-change', { row: this.row, value: !this.row.starred });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(star) {
        height: 16px;
        width: 16px;

        svg {
            fill: $color-stars-inactive;
            stroke: $color-stars-stroke-inactive;
        }

        @include is(starred) {
            svg {
                fill: $color-stars-active;
                stroke: $color-stars-stroke-active;
            }
        }

        &:hover {
            cursor: pointer;

            svg {
                transition: stroke 0.2s, fill 0.2s;
                fill: $color-stars-hover;
                stroke: $color-stars-hover;
            }
        }
    }
}
</style>
