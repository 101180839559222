<template>
    <div
        class="pendo-multiselect-option--image"
        v-bind="$attrs"
        v-on="$listeners">
        <img
            v-if="option.img || option.src"
            class="pendo-multiselect-option__image"
            :src="option.img || option.src"
            :alt="option.alt || ''">
        <span
            v-if="label"
            class="pendo-multiselect-option__label">
            {{ label }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'PendoImageOption',
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String],
            required: true
        }
    },
    computed: {
        label () {
            return this.multiselect.getOptionLabel(this.option);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(image) {
        @include font-base;
        line-height: 26px;
        user-select: none;
        height: 100%;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: auto 1fr;
        align-items: center;
        overflow: hidden;

        @include element(label) {
            @include ellipsis;
        }

        @include element(image) {
            max-height: 22px;
        }
    }
}
</style>
