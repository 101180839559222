<template>
    <pendo-collapse-transition appear>
        <div
            v-show="isVisible"
            v-bind="$attrs"
            class="pendo-card-section"
            :class="{
                'pendo-card-section--closable': closable
            }">
            <div class="pendo-card-section__content">
                <slot />
            </div>
            <pendo-button
                v-if="isVisible && closable"
                class="pendo-card-section__close-button"
                theme="app"
                type="tertiary"
                icon="x"
                icon-size="18"
                @click="removeSelf" />
        </div>
    </pendo-collapse-transition>
</template>

<script>
import PendoButton from '@/components/button/pendo-button';
import PendoCollapseTransition from '@/utils/pendo-collapse-transition';

export default {
    name: 'PendoCardSection',
    compatConfig: {
        INSTANCE_ATTRS_CLASS_STYLE: false
    },
    components: {
        PendoButton,
        PendoCollapseTransition
    },
    inheritAttrs: false,
    props: {
        closable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isVisible: false
        };
    },
    mounted () {
        // trigger animation on show
        this.isVisible = true;

        // override the default 16px padding found in card-body
        if (this.$el.parentNode && this.$el.parentNode.classList[0] === 'pendo-card__body') {
            this.$el.parentNode.style.padding = 0;
        }
    },
    methods: {
        removeSelf () {
            this.isVisible = false;
            setTimeout(() => {
                this.$emit('close');

                if (this.$el.parentNode) {
                    this.$el.parentNode.removeChild(this.$el);
                }

                this.$destroy();
            }, 250);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-card) {
    .pendo-card__body .pendo-card-section:last-child {
        border-bottom: 0;
    }
}

@include block(pendo-card-section) {
    user-select: none;
    position: relative;
    border-bottom: $card-border;
    display: grid;
    height: 100%;

    @include modifier(closable) {
        grid-template-columns: 1fr 48px;
        grid-template-rows: repeat(2, 1fr);

        @include element(content) {
            grid-row: span 2;
        }
    }

    @include element(close-button) {
        cursor: pointer;
        display: grid;
        grid-row: 1;
        grid-column: 2;
        align-items: center;
        justify-content: center;
        transition: color 0.2s;

        &.pendo-button.app {
            height: 48px;
            color: $color-gray-60;

            &:hover {
                color: $color-gray-70;
            }
        }
    }

    &.pendo-transition-collapse-leave-active {
        transition: opacity 150ms ease-in-out 0ms, height 150ms ease-in-out 50ms;
        opacity: 1;
        height: 100%;
    }

    &.pendo-transition-collapse-leave-to {
        opacity: 0;
        height: 0;
    }
}
</style>
