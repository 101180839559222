<template>
    <div
        class="pendo-divider"
        :role="usesSlot ? undefined : 'separator'"
        :aria-orientation="usesSlot ? undefined : direction"
        :class="{
            'pendo-divider--horizontal': direction === 'horizontal',
            'pendo-divider--vertical': direction === 'vertical'
        }"
        :style="{ height, width }">
        <slot name="start" />
        <svg>
            <line
                v-bind="line"
                :stroke="stroke"
                :stroke-width="strokeWidth"
                :stroke-dasharray="strokeDasharray"
                shape-rendering="crispEdges" />
        </svg>
        <slot name="middle" />
        <svg v-if="$slots.middle">
            <line
                v-bind="line"
                :stroke="stroke"
                :stroke-width="strokeWidth"
                :stroke-dasharray="strokeDasharray"
                shape-rendering="crispEdges" />
        </svg>
        <slot name="end" />
    </div>
</template>

<script>
export default {
    name: 'PendoDivider',
    props: {
        /**
         * color of divider line
         */
        stroke: {
            type: String,
            default: '#EAECF1'
        },
        /**
         * thickness of divider line
         */
        strokeWidth: {
            type: Number,
            default: 1
        },
        /**
         * native stroke-dasharray attribute
         */
        strokeDasharray: {
            type: String,
            default: null
        },
        /**
         * height of divider container
         */
        height: {
            type: String,
            default: '32px'
        },
        /**
         * width of divider container
         */
        width: {
            type: String,
            default: '100%'
        },
        /**
         * @values horizontal, vertical
         */
        direction: {
            type: String,
            default: 'horizontal',
            validator: (direction) => ['horizontal', 'vertical'].includes(direction)
        },
        /**
         * placement of divider line within divider container
         */
        placement: {
            type: String,
            default: null
        }
    },
    computed: {
        line () {
            if (this.direction === 'vertical') {
                const placement = this.placement || '50%';

                return {
                    x1: placement,
                    y1: '100%',
                    x2: placement,
                    y2: '0%'
                };
            }

            const placement = this.placement || '50%';

            return {
                x1: '100%',
                y1: placement,
                x2: '0%',
                y2: placement
            };
        },
        usesSlot () {
            return ['start', 'middle', 'end'].some((slotName) => this.$slots[slotName]);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-divider) {
    display: flex;
    justify-content: center;
    align-items: center;

    @include modifier(horizontal) {
        flex-direction: row;
        margin: auto;

        > :first-child:not(:only-child) {
            margin-right: 4px;
        }

        > :last-child:not(:only-child) {
            margin-left: 4px;
        }

        > :not(:first-child):not(:last-child) {
            margin-left: 4px;
            margin-right: 4px;
        }
    }

    @include modifier(vertical) {
        flex-direction: column;

        > :first-child:not(:only-child) {
            margin-bottom: 4px;
        }

        > :last-child:not(:only-child) {
            margin-top: 4px;
        }

        > :not(:first-child):not(:last-child) {
            margin-top: 4px;
            margin-bottom: 4px;
        }
    }

    > svg {
        height: 100%;
        width: 100%;
    }
}
</style>
