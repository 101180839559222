<template>
    <div
        id="pendo-multiselect-list"
        ref="list"
        tabindex="0"
        role="listbox"
        :aria-multiselectable="multiple"
        :data-optioncount="listData.length"
        class="pendo-multiselect__content-wrapper"
        @scroll.passive="handleScroll">
        <div
            ref="beforeList"
            class="pendo-multiselect__before-list">
            <slot
                name="_beforeList"
                v-bind="$props" />
        </div>
        <div
            v-if="showStateContainer"
            :style="{
                width: listWidth
            }"
            class="pendo-multiselect__element">
            <span
                v-if="showMaxElementsSlot"
                class="pendo-multiselect__max-elements">
                <slot name="_maxElements">
                    Maximum of {{ max }} options selected. First remove a selected option to select another.
                </slot>
            </span>
            <span
                v-if="showAllSelectedSlot"
                class="pendo-multiselect__all-selected">
                <slot name="_allSelected">
                    {{ allSelectedText }}
                </slot>
            </span>
            <span
                v-if="showNoResultsSlot"
                class="pendo-multiselect__no-results">
                <slot name="_noResult">
                    {{ noResultsText }}
                </slot>
            </span>
            <span
                v-if="showNoDataSlot"
                class="pendo-multiselect__no-data">
                <slot name="_noData" />
            </span>
        </div>
        <template v-if="showOptionsList">
            <div
                v-if="scrollConfig && scrollConfig.enabled"
                :style="{
                    width: '0px',
                    float: 'left',
                    height: `${scrollHeight}px`
                }" />
            <div
                class="pendo-multiselect__scroll-container"
                :style="{
                    transform: `translate3d(0px, ${scrollTop}px, 0px)`
                }"
                @mouseleave="pointerSet(-1)">
                <pendo-multiselect-option
                    v-for="option of listOptions"
                    :key="option.nr.id"
                    :index="option.nr.index"
                    :scroll-active="scrollActive"
                    :option="option.data">
                    <template #optionGroup>
                        <slot
                            name="_optionGroup"
                            :option="option.data"
                            :index="option.nr.index"
                            :input-value="inputValue" />
                    </template>
                    <template #option>
                        <slot
                            name="_option"
                            :option="option.data"
                            :index="option.nr.index" />
                    </template>
                </pendo-multiselect-option>
            </div>
        </template>
        <slot
            name="_afterList"
            v-bind="$props" />
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop, vue/no-unused-properties */
import { markRaw } from 'vue';
import debounce from 'lodash/debounce';
import computeScrollIntoView from 'compute-scroll-into-view';

import PendoMultiselectOption from '@/components/multiselect/pendo-multiselect-option';
import { isPx, setStyles, raf } from '@/utils/dom';

let uid = 0;

export default {
    components: {
        PendoMultiselectOption
    },
    props: {
        activate: {
            type: Function
        },
        allowEmptyOptions: {
            type: Boolean
        },
        allOptionsSelected: {
            type: Boolean
        },
        allSelectedText: {
            type: String
        },
        computedMenuWidth: {
            type: Number
        },
        deactivate: {
            type: Function
        },
        disabled: {
            type: Boolean
        },
        filteredOptions: {
            type: Array
        },
        flattenedOptions: {
            type: Array
        },
        formatOptionLabel: {
            type: Function
        },
        getOptionLabel: {
            type: Function
        },
        groupDividers: {
            type: Boolean
        },
        groupLabelKey: {
            type: String
        },
        groupOptionsKey: {
            type: String
        },
        groupSelect: {
            type: Boolean
        },
        handleKeydown: {
            type: Function
        },
        hasGroups: {
            type: Boolean
        },
        hideSelectedOptions: {
            type: Boolean
        },
        inputValue: {
            type: String
        },
        isEntireGroupSelected: {
            type: Function
        },
        isFullWidth: {
            type: Boolean
        },
        isOpen: {
            type: Boolean
        },
        isOptionSelected: {
            type: Function
        },
        isPlaceholderVisible: {
            type: Boolean
        },
        isSelectedLabelVisible: {
            type: Boolean
        },
        labelKey: {
            type: String
        },
        limit: {
            type: Number
        },
        limitText: {
            type: Function
        },
        loading: {
            type: Boolean
        },
        max: {
            type: [Number, Boolean]
        },
        maxMenuHeight: {
            type: [String, Number]
        },
        maxMenuWidth: {
            type: [String, Number]
        },
        minMenuWidth: {
            type: [String, Number]
        },
        model: {
            type: Array
        },
        multiple: {
            type: Boolean
        },
        name: {
            type: String
        },
        noResultsText: {
            type: String
        },
        optionHeight: {
            type: Number
        },
        options: {
            type: null
        },
        placeholder: {
            type: [String, Number]
        },
        pointer: {
            type: Number
        },
        pointerSet: {
            type: Function
        },
        removeSelection: {
            type: Function
        },
        scrollConfig: {
            type: Object
        },
        select: {
            type: Function
        },
        selectedLabel: {
            type: [String, Number]
        },
        selectedValue: {
            type: [Object, Number, String, Array]
        },
        selectGroup: {
            type: Function
        },
        shouldScrollToFocusedOption: {
            type: Boolean
        },
        showMaxElements: {
            type: Boolean
        },
        showNoResults: {
            type: Boolean
        },
        showPointer: {
            type: Boolean
        },
        taggable: {
            type: Boolean
        },
        toggleMenu: {
            type: Function
        },
        updateInputValue: {
            type: Function
        },
        valueKey: {
            type: String
        },
        visibleSelectedValues: {
            type: Array
        }
    },
    data () {
        return {
            listOptions: [],
            scrollTop: 0,
            scrollHeight: 0,
            scrollActive: false,
            buffer: 300,
            scheduleOptionsUpdate: null,
            menuHeight: 0
        };
    },
    computed: {
        listWidth () {
            if (this.showStateContainer && this.$el && this.$el.clientWidth > 0) {
                return `${this.$el.clientWidth}px`;
            }

            return '100%';
        },
        listData () {
            return this.hasGroups ? this.flattenedOptions : this.filteredOptions;
        },
        showNoDataSlot () {
            if (this.taggable && (this.listData.length || this.allowEmptyOptions)) {
                return false;
            }

            return !Array.isArray(this.options) || !this.options.length;
        },
        showNoResultsSlot () {
            if (this.showNoDataSlot) {
                return false;
            }

            if (this.showAllSelectedSlot) {
                return false;
            }

            if (this.showMaxElementsSlot) {
                return false;
            }

            if (this.showNoResults) {
                const hasInputValue = this.inputValue.length;
                const noMatchingOptions = this.listData.length === 0;

                return Boolean(hasInputValue && noMatchingOptions);
            }

            return false;
        },
        showAllSelectedSlot () {
            if (this.multiple && this.hideSelectedOptions) {
                if (this.taggable) {
                    return !this.allowEmptyOptions && !this.inputValue.length && this.allOptionsSelected;
                }

                return this.allOptionsSelected;
            }

            return false;
        },
        showMaxElementsSlot () {
            if (this.showMaxElements && this.multiple && this.max) {
                return this.model.length === this.max;
            }

            return false;
        },
        showStateContainer () {
            if (this.loading) {
                return false;
            }

            return (
                this.showMaxElementsSlot || this.showAllSelectedSlot || this.showNoResultsSlot || this.showNoDataSlot
            );
        },
        showOptionsList () {
            if (this.showStateContainer) {
                return false;
            }

            return true;
        },
        optionHeights () {
            const { listData } = this;
            const sizes = {
                '-1': 0
            };

            let total = 0;
            let current;
            let groupIndex = 0;
            for (let i = 0, l = listData.length; i < l; i++) {
                if (listData[i].type === 'group') {
                    if (groupIndex === 0) {
                        groupIndex++;
                        current = this.optionHeight;
                    } else {
                        current = this.optionHeight + 16;
                    }
                } else if (listData[i].divided) {
                    current = this.optionHeight + 16;
                } else {
                    current = this.optionHeight;
                }

                total += current;
                sizes[i] = total;
            }

            return sizes;
        }
    },
    watch: {
        pointer (index) {
            if (!this.shouldScrollToFocusedOption) {
                return;
            }

            if (this.hasGroups && index < 2) {
                this.$el.scrollTop = 0;
            } else {
                this.scrollHighlightedOptionIntoView(index);
            }
        },
        model: 'setMenuHeight',
        minMenuWidth: 'setMenuWidth',
        computedMenuWidth: 'setMenuWidth',
        maxMenuWidth: 'setMenuWidth',
        maxMenuHeight: 'setMenuHeight',
        listData () {
            this.updateVisibleOptions();
        }
    },
    created () {
        this.updateScrollStatus = debounce(this.updateScrollStatus, 125, {
            leading: true,
            trailing: true
        });
    },
    async mounted () {
        await this.$nextTick();

        this.setMenuHeight();
        this.setMenuWidth();

        this.scheduleOptionsUpdate = raf(this.updateVisibleOptions);
        this.updateVisibleOptions();
        this.ready = true;
    },
    methods: {
        setMenuWidth () {
            const styles = {};
            if (this.isFullWidth) {
                styles.minWidth = '100%';
                styles.maxWidth = '100%';
            } else {
                styles.minWidth = this.computedMenuWidth || this.minMenuWidth;
                styles.maxWidth = this.maxMenuWidth - 2; // 2px for panel border

                const aside = document.querySelector('.pendo-multiselect__aside');
                if (aside) {
                    const maxAvailableOptionsWidth = styles.maxWidth - aside.clientWidth;
                    if (maxAvailableOptionsWidth < styles.minWidth) {
                        styles.minWidth = maxAvailableOptionsWidth;
                    }
                }
            }

            setStyles(this.$el, styles);
        },
        setMenuHeight () {
            const header = document.querySelector('.pendo-multiselect__header');
            const footer = document.querySelector('.pendo-multiselect__footer');
            let maxHeight = isPx(this.maxMenuHeight) ? parseInt(this.maxMenuHeight) : this.maxMenuHeight;
            if (header) {
                maxHeight -= header.clientHeight;
            }

            if (footer) {
                maxHeight -= footer.clientHeight;
            }

            this.menuHeight = maxHeight;

            setStyles(this.$el, { maxHeight });
        },
        scrollHighlightedOptionIntoView (index) {
            const elem = this.$el.querySelector(`[data-uid='${index}']`);

            if (!elem) {
                // try to scroll to element based on position index
                this.$el.scrollTop = this.optionHeights[index - 1];

                return;
            }

            const actions = computeScrollIntoView(elem, {
                scrollMode: 'if-needed',
                block: 'nearest',
                inline: 'nearest',
                boundary: this.$el
            });

            actions.forEach(({ top }) => {
                if (top > this.$el.scrollTop) {
                    // scrolling down
                    this.$el.scrollTop = top + 8;
                } else if (this.$el.scrollTop > top) {
                    // scrolling up
                    this.$el.scrollTop = top - 8;
                }
            });
        },
        updateScrollStatus () {
            this.scrollActive = false;
        },
        handleScroll (event) {
            const { clientHeight, scrollTop } = event.target;

            // vertical scroll
            if (this.scrollTop !== scrollTop) {
                this.scheduleOptionsUpdate({
                    start: scrollTop,
                    end: scrollTop + clientHeight
                });

                this.scrollActive = true;
                this.updateScrollStatus();
            }
        },
        async updateVisibleOptions (scroll) {
            let startIndex = 0;
            let endIndex = 0;
            let option;
            let data;

            const { listData, listOptions, optionHeight, buffer, hasGroups, optionHeights, scrollConfig } = this;
            const { enabled } = scrollConfig;

            const count = listData.length;

            if (!scroll) {
                const { scrollTop } = this.$el;

                scroll = {
                    start: scrollTop,
                    end: scrollTop + this.menuHeight
                };
            }

            const beforeListHeight = this.$refs.beforeList ? this.$refs.beforeList.offsetHeight : 0;
            scroll.start -= beforeListHeight;

            if (count && enabled) {
                scroll.start -= buffer;
                scroll.end += buffer;

                // group headers have different size headers than regular options
                if (hasGroups) {
                    let scrollStart;
                    let a = 0;
                    let b = count - 1;
                    let currIndex = Math.floor(count / 2);
                    let lastIndex;

                    // Searching for startIndex
                    do {
                        lastIndex = currIndex;
                        scrollStart = optionHeights[currIndex];
                        if (scrollStart < scroll.start) {
                            a = currIndex;
                        } else if (currIndex < count - 1 && optionHeights[currIndex + 1] > scroll.start) {
                            b = currIndex;
                        }
                        currIndex = Math.floor((a + b) / 2);
                    } while (currIndex !== lastIndex);

                    startIndex = Math.max(currIndex, 0);
                    // For container style
                    this.scrollHeight = optionHeights[count - 1];
                    this.scrollTop = Math.floor(optionHeights[startIndex - 1]);

                    for (endIndex = currIndex; endIndex < count && optionHeights[endIndex] < scroll.end; endIndex++) {
                        if (endIndex === -1) {
                            endIndex = count - 1;
                        } else {
                            endIndex++;
                            endIndex = Math.min(endIndex, count);
                        }
                    }
                } else {
                    // Fixed size mode
                    endIndex = Math.min(Math.ceil(scroll.end / optionHeight), count);
                    startIndex = Math.max(Math.floor(scroll.start / optionHeight), 0);
                    this.scrollHeight = count * optionHeight;
                    this.scrollTop = Math.floor(startIndex * optionHeight);
                }
            } else {
                // scroll config is disabled, or there are no items
                // When scroll config is disabled, just render all the elements
                this.scrollTop = 0;
                this.scrollHeight = 0;
                startIndex = 0;
                endIndex = count;
            }

            // eslint-disable-next-line id-length
            for (let o = 0, i = startIndex; i < endIndex; o++, i++) {
                data = listData[i];

                // use existing row if available
                if (listOptions[o]) {
                    option = listOptions[o];
                    option.nr.index = i;
                    option.data = data;
                } else {
                    // create a new row
                    option = {
                        data,
                        nr: markRaw({
                        id: uid++,
                        index: i
                        })
                    };

                    listOptions.push(option);
                }
            }

            if (listOptions.length + startIndex > count) {
                listOptions.length = Math.max(count - startIndex, 0);
            }
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    @include element(scroll-container) {
        will-change: transform;
    }

    @include element(content-wrapper) {
        box-sizing: border-box;
        background: $color-white;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;
        padding: 8px 0;
    }

    @include element(content) {
        list-style: none;
        padding: 8px 0;
        margin: 0;
        min-width: 100%;
        max-width: 100%;
        vertical-align: top;
    }

    @include element((all-selected, max-elements, no-data, no-results, no-data)) {
        padding: 3px 24px 3px 16px;
        line-height: 20px;
    }
}
</style>
