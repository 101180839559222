<template>
    <component
        :is="tag"
        v-bind="gridAttrs"
        v-on="$listeners">
        <slot />
    </component>
</template>

<script>
const JUSTIFY_ALIGN_CONTENT_KEYS = [
    'start',
    'end',
    'center',
    'stretch',
    'space-around',
    'space-between',
    'space-evenly'
];
const JUSTIFY_ALIGN_ITEM_SELF_KEYS = ['start', 'end', 'center', 'stretch', 'baseline'];
const AUTO_FLOW_KEYS = ['dense', 'row', 'column'];

export default {
    name: 'PendoGrid',
    props: {
        columns: {
            type: [String, Number, Array],
            default: 1
        },
        rows: {
            type: [String, Number, Array],
            default: 1
        },
        areas: {
            type: Array,
            default: () => []
        },
        tag: {
            type: String,
            default: 'div'
        },
        gap: {
            type: [String, Array, Number],
            default: null
        },
        autoFlow: {
            type: String,
            default: null,
            validator: (autoFlow) => AUTO_FLOW_KEYS.includes(autoFlow)
        },
        autoRows: {
            type: String,
            default: null
        },
        autoColumns: {
            type: String,
            default: null
        },
        inline: {
            type: Boolean,
            default: false
        },
        justifyContent: {
            type: String,
            default: null,
            validator: (justifyContent) => JUSTIFY_ALIGN_CONTENT_KEYS.includes(justifyContent)
        },
        justifyItems: {
            type: String,
            default: null,
            validator: (justifyItems) => JUSTIFY_ALIGN_ITEM_SELF_KEYS.includes(justifyItems)
        },
        justifySelf: {
            type: String,
            default: null,
            validator: (justifySelf) => JUSTIFY_ALIGN_ITEM_SELF_KEYS.includes(justifySelf)
        },
        alignContent: {
            type: String,
            default: null,
            validator: (alignContent) => JUSTIFY_ALIGN_CONTENT_KEYS.includes(alignContent)
        },
        alignItems: {
            type: String,
            default: null,
            validator: (alignItems) => JUSTIFY_ALIGN_ITEM_SELF_KEYS.includes(alignItems)
        },
        alignSelf: {
            type: String,
            default: null,
            validator: (alignSelf) => JUSTIFY_ALIGN_ITEM_SELF_KEYS.includes(alignSelf)
        }
    },
    computed: {
        gridAttrs () {
            const templateProps = ['gridTemplateColumns', 'gridTemplateRows', 'gridGap'];
            const justifyAlignProps = [
                'justifyContent',
                'justifyItems',
                'justifySelf',
                'alignContent',
                'alignItems',
                'alignSelf'
            ];

            const styles = {};
            const classes = [];

            if (this.inline) {
                classes.push('pendo-inline-grid');
            } else {
                classes.push('pendo-grid');
            }

            if (this.autoFlow) {
                classes.push(`pendo-grid-auto-flow-${this.autoFlow}`);
            }

            templateProps.forEach((prop) => {
                if (!this[prop]) {
                    return;
                }

                const { type, value } = this[prop];

                if (type === 'style') {
                    styles[prop] = value;
                } else {
                    classes.push(value);
                }
            });

            justifyAlignProps.forEach((prop) => {
                if (this[prop]) {
                    classes.push(`pendo-grid-${this.kebabCase(prop)}-${this[prop]}`);
                }
            });

            return {
                style: {
                    gridTemplateAreas: this.gridAreas,
                    gridAutoRows: this.autoRows,
                    gridAutoColumns: this.autoColumns,
                    ...styles
                },
                class: classes
            };
        },
        gridTemplateColumns () {
            if (Array.isArray(this.columns)) {
                return {
                    type: 'style',
                    value: this.columns.reduce(this.convertPropToCss, '')
                };
            }

            if (this.autoColumns) {
                return null;
            }
            // we generate util classes up to 8 columns, opt to use those before inline styles
            if (Number(this.columns) <= 8) {
                return {
                    type: 'class',
                    value: `pendo-grid-columns-${this.columns}`
                };
            }

            return {
                type: 'style',
                value: `repeat(${this.columns}, 1fr)`
            };
        },
        gridTemplateRows () {
            if (Array.isArray(this.rows)) {
                return {
                    type: 'style',
                    value: this.rows.reduce(this.convertPropToCss, '')
                };
            }

            if (this.autoRows) {
                return null;
            }
            // we generate util classes up to 8 rows, opt to use those before inline styles
            if (Number(this.rows) <= 8) {
                return {
                    type: 'class',
                    value: `pendo-grid-rows-${this.rows}`
                };
            }

            return {
                type: 'style',
                value: `repeat(${this.rows}, 1fr)`
            };
        },
        gridGap () {
            if (!this.gap) {
                return null;
            }

            if (Array.isArray(this.gap)) {
                const [x, y] = this.gap;

                return {
                    type: 'class',
                    value: `pendo-grid-gap-${x}-x pendo-grid-gap-${y}-y`
                };
            }

            // we generate gap util classes up to 64, opt to use those before inline styles
            if (Number(this.gap) <= 64) {
                return {
                    type: 'class',
                    value: `pendo-grid-gap-${this.gap}`
                };
            }

            return {
                type: 'style',
                value: `${this.gap}px`
            };
        },
        gridAreas () {
            if (!this.areas) {
                return null;
            }

            return this.areas
                .map((row) => {
                    return `"${row.join(' ')}"`;
                })
                .join(' ');
        }
    },
    methods: {
        kebabCase (val) {
            return val.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        },
        convertPropToCss (acum, item) {
            if (typeof item === 'string') {
                // if not a number print as is
                return `${acum} ${item}`;
            }

            // if number use px
            return `${acum} ${item}px`;
        }
    }
};
</script>

<style lang="scss">
// It's not really practical to outline EVERY possible
// grid scenario BUT in order to prevent a bunch of bloated
// inline styles using the JS above, we can define a basic
// set of util classes that should cover most use cases while
// still allowing the inline styles to handle custom stuff
.pendo-grid {
    display: grid;
}

.pendo-inline-grid {
    display: inline-grid;
}

.pendo-grid-auto-flow-dense {
    grid-auto-flow: dense;
}

.pendo-grid-auto-flow-column {
    grid-auto-flow: column;
}

.pendo-grid-auto-flow-row {
    grid-auto-flow: row;
}

// ===========================
// grid-template-columns 1-8
// grid-template-rows 1-8
// ===========================
@each $type in (columns, rows) {
    @for $i from 1 through 8 {
        .pendo-grid-#{$type}-#{$i} {
            grid-template-#{$type}: repeat(#{$i}, 1fr);
        }
    }
}

// ============================
// grid-gaps 4-64 on 8pt grid
// =============================
$grid-gaps: (4, 8, 12, 16, 24, 32, 40, 48, 56, 64);
@each $gap in $grid-gaps {
    .pendo-grid-gap-#{$gap} {
        grid-gap: #{$gap}px;

        &-x {
            grid-column-gap: #{$gap}px;
        }

        &-y {
            grid-row-gap: #{$gap}px;
        }
    }
}

// ===========================
// grid-column-span 1-8
// grid-row-span 1-8
// ===========================
@each $type in (column, row) {
    @for $i from 1 through 8 {
        .pendo-grid-#{$type}-span-#{$i} {
            grid-#{$type}-start: span #{$i};
        }
    }
}
// ===========================
// grid-column-start 1-9
// grid-column-end 1-9
// grid-row-start 1-9
// grid-row-end 1-8
// ===========================
@each $type in (column, row) {
    @for $i from 1 through 9 {
        .pendo-grid-#{$type}-start-#{$i} {
            grid-#{$type}-start: $i;
        }
        .pendo-grid-#{$type}-end-#{$i} {
            grid-#{$type}-end: $i;
        }
    }
}

// =========================
// justify and align utils
// =========================
$grid-justify-align-properties: (
    content: (
        'start',
        'end',
        'center',
        'stretch',
        'space-around',
        'space-between',
        'space-evenly'
    ),
    items: (
        'start',
        'end',
        'center',
        'stretch',
        'baseline'
    ),
    self: (
        'start',
        'end',
        'center',
        'stretch',
        'baseline'
    )
);

@each $key, $val in $grid-justify-align-properties {
    @each $type in $val {
        .pendo-grid-align-#{$key}-#{$type} {
            align-#{$key}: $type;
        }
        .pendo-grid-justify-#{$key}-#{$type} {
            justify-#{$key}: $type;
        }
    }
}
</style>
