<template>
    <pendo-toggle
        class="pendo-table__toggle"
        :class="{
            'pendo-table__toggle--prevent-animations': !allowAnimations || $table.scrollStore.scrollActive
        }"
        :disabled="disabled"
        :value="value"
        @change="handleToggleChange"
        @mouseenter.native="allowAnimations = true"
        @mouseleave.native="allowAnimations = false" />
</template>

<script>
import PendoToggle from '@/components/toggle/pendo-toggle';

export default {
    name: 'PendoToggleCell',
    components: {
        PendoToggle
    },
    inject: ['$table'],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        row: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            allowAnimations: false
        };
    },
    methods: {
        handleToggleChange () {
            this.$emit('toggle-change', { row: this.row, value: !this.value });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(toggle) {
        display: block;

        .pendo-icon {
            box-sizing: content-box;
        }

        @include modifier(prevent-animations) {
            .pendo-toggle__slide,
            .pendo-toggle__inner,
            .pendo-toggle__handle {
                transition: none !important;
            }
        }
    }
}
</style>
