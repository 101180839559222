<template>
    <div class="pendo-product-area__inline-multiselect">
        <multiselect-cell
            :row="row"
            :value="selectedProductArea"
            :options="options"
            :loading="loading"
            trigger-size="mini"
            @select="$emit('select', $event)">
            <template #trigger>
                <pendo-group-cell :row="row" />
            </template>
            <template #selectedLabel="{ option }">
                <pendo-group-option :option="option" />
            </template>
            <template
                v-if="emptyOption"
                #stickyOption="{ select }">
                <div
                    :class="[
                        'pendo-multiselect__option',
                        'pendo-product-area__sticky-option',
                        {
                            'is-selected': selectedProductArea.label === emptyOption.label
                        }
                    ]">
                    <pendo-group-option
                        :option="emptyOption"
                        @click="select(emptyOption)" />
                </div>
            </template>
            <template #option="{ option }">
                <slot
                    name="option"
                    :option="option">
                    <pendo-group-option :option="option" />
                </slot>
            </template>
            <template
                v-if="showCreateProductArea"
                #footer="{ toggleMenu }">
                <div class="pendo-product-area__create-button">
                    <pendo-button
                        theme="app"
                        type="link"
                        prefix-icon="plus"
                        label="Create Product Area"
                        @click="$emit('create-product-area', { row, toggleMenu })" />
                </div>
            </template>
        </multiselect-cell>
    </div>
</template>

<script>
import MultiselectCell from '@/components/table/cell-types/multiselect-cell';
import PendoGroupCell from '@/components/table/cell-types/pendo-group-cell';
import PendoGroupOption from '@/components/multiselect/option-types/pendo-group-option';
import PendoButton from '@/components/button/pendo-button';

export default {
    name: 'PendoProductAreaInlineMultiselect',
    components: {
        MultiselectCell,
        PendoGroupCell,
        PendoGroupOption,
        PendoButton
    },
    props: {
        /**
         * the entity/item on which the group/product are lives
         */
        row: {
            type: Object,
            required: true
        },
        /**
         * list of options
         */
        options: {
            type: Array,
            required: true
        },
        /**
         * flag for managing loading state
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * the selected product area
         */
        selectedProductArea: {
            type: Object,
            default: null
        },
        /**
         * option used to populate the sticky-option at the
         * top of the list
         */
        emptyOption: {
            type: Object,
            default: null
        },
        /**
         * whether or not to show the ability to create a product area
         */
        showCreateProductArea: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-product-area) {
    @include element(inline-multiselect) {
        .pendo-table__group {
            cursor: pointer;
        }
    }

    @include element(sticky-option) {
        height: 36px;
        border-bottom: 1px solid $color-gray-30;

        &:hover {
            background-color: $color-gray-20;
        }

        @include is(selected) {
            background-color: $color-gray-30;
        }
    }

    @include element(create-button) {
        padding-left: 16px;
        border-top: 1px solid $color-gray-30;
    }
}
</style>
