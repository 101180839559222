<template>
    <pendo-multiselect-core
        ref="core"
        v-bind="$props"
        :internal-search="internalSearch && !groupSelect"
        v-on="$listeners">
        <template
            #default="{
                activate,
                selectedValuePointer,
                allOptionsSelected,
                allSelectedText,
                allowEmptyOptions,
                appendToBody,
                computedMenuWidth,
                computedPlaceholder,
                deactivate,
                filteredOptions,
                flattenedOptions,
                getOptionLabel,
                handleBlur,
                handleClickOutside,
                handleFocus,
                handleHide,
                handleKeydown,
                handleTriggerClick,
                hasGroups,
                hideSelectedOptions,
                highlightedOption,
                id,
                inputValue,
                invalid,
                isDisabled,
                isEntireGroupSelected,
                isFocused,
                isFullWidth,
                isOpen,
                isOptionSelected,
                isPlaceholderVisible,
                isSearchable,
                isSearchIconVisible,
                isSelectedLabelVisible,
                labels,
                limit,
                limitText,
                max,
                maxMenuHeight,
                maxMenuWidth,
                maxTriggerWidth,
                minMenuWidth,
                minTriggerWidth,
                model,
                multiple,
                noResultsText,
                optionHeight,
                options,
                placeholder,
                pointer,
                computedPopperOptions,
                removeSelection,
                scrollConfig,
                select,
                selectGroup,
                selectedLabel,
                selectedValue,
                setTriggerRef,
                shouldAnimateSearchEnterExit,
                shouldScrollToFocusedOption,
                showMaxElements,
                showNoResults,
                showSelectedValues,
                showSelectedValueTags,
                triggerWidth,
                updateInputValue,
                visibleSelectedValues
            }">
            <div
                :id="!!$formItem && $formItem.labelFor"
                class="pendo-multiselect"
                :class="{
                    'is-active': isFocused || isOpen,
                    'is-open': isOpen,
                    'is-disabled': isDisabled,
                    'is-error': invalid,
                    'pendo-multiselect--inline-label': !!labels.left || !!labels.right,
                    'pendo-multiselect--full-width': isFullWidth,
                    'pendo-multiselect--searchable': isSearchable,
                    'pendo-multiselect--aside': $slots.aside
                }"
                role="combobox"
                aria-autocomplete="list"
                aria-controls="pendo-multiselect-list"
                :aria-expanded="`${isOpen}`"
                :aria-labelledby="ariaLabelledBy"
                :aria-describedby="!!$formItem && $formItem.ariaDescribedBy"
                :aria-invalid="!!$formItem && !!$formItem.ariaDescribedBy"
                :aria-required="!!$formItem && $formItem.isRequired"
                v-bind="inheritedAttrs">
                <div
                    v-if="labels.top || labels.left || !!$slots.topLabel"
                    class="pendo-multiselect__label pendo-multiselect__label--top">
                    <slot name="topLabel">
                        {{ labels.top || labels.left }}
                    </slot>
                </div>
                <picker-popover
                    :is-open="isOpen"
                    :append-to-body="appendToBody"
                    :popper-options="computedPopperOptions"
                    :popover-class="{
                        'pendo-multiselect-menu': true,
                        'pendo-multiselect-menu--aside': $slots.aside,
                        'pendo-multiselect-menu--header': $slots.header,
                        'pendo-multiselect-menu--footer': $slots.footer
                    }"
                    :min-width="computedMenuWidth"
                    :max-width="$props.maxMenuWidth"
                    :max-height="$props.maxMenuHeight"
                    :full-width="isFullWidth"
                    @click-outside="handleClickOutside"
                    @hide="handleHide">
                    <slot
                        name="trigger"
                        v-bind="{
                            deactivate,
                            disabled: isDisabled,
                            handleBlur,
                            handleFocus,
                            handleKeydown,
                            handleTriggerClick,
                            isFocused,
                            isOpen,
                            isSearchable,
                            toggleMenu,
                            triggerSize
                        }">
                        <pendo-multiselect-trigger
                            v-bind="{
                                disabled: isDisabled,
                                invalid,
                                isFocused,
                                isFullWidth,
                                isOpen,
                                isSearchable,
                                loading,
                                maxTriggerWidth,
                                minTriggerWidth,
                                triggerSize
                            }"
                            :ref="setTriggerRef"
                            @blur="handleBlur"
                            @focus="handleFocus"
                            @click="handleTriggerClick"
                            @up="handleKeydown('up')"
                            @down="handleKeydown('down')"
                            @delete="handleKeydown('delete')"
                            @enter="handleKeydown('enter', $event)"
                            @space="handleKeydown('space', $event)"
                            @tab="handleKeydown('tab', $event)"
                            @esc="deactivate">
                            <pendo-multiselect-value
                                v-bind="{
                                    disabled: isDisabled,
                                    getOptionLabel,
                                    inputValue,
                                    isOpen,
                                    isPlaceholderVisible,
                                    isSelectedLabelVisible,
                                    limit,
                                    limitText,
                                    loading,
                                    model,
                                    placeholder,
                                    removeSelection,
                                    selectedLabel,
                                    selectedValue,
                                    selectedValuePointer,
                                    shouldAnimateSearchEnterExit:
                                        !!$slots.selectedLabel || !!$slots.placeholder
                                            ? false
                                            : shouldAnimateSearchEnterExit,
                                    showSelectedValues,
                                    showSelectedValueTags,
                                    toggleMenu,
                                    visibleSelectedValues
                                }">
                                <template #control>
                                    <slot
                                        v-if="isSearchable"
                                        name="control"
                                        v-bind="{
                                            activate,
                                            computedPlaceholder,
                                            deactivate,
                                            disabled: isDisabled,
                                            handleKeydown,
                                            id,
                                            inputValue,
                                            isFullWidth,
                                            isOpen,
                                            isSearchable,
                                            isSearchIconVisible,
                                            minTriggerWidth,
                                            multiple,
                                            placeholder,
                                            shouldAnimateSearchEnterExit:
                                                !!$slots.selectedLabel || !!$slots.placeholder
                                                    ? false
                                                    : shouldAnimateSearchEnterExit,
                                            taggable,
                                            triggerSize,
                                            triggerWidth,
                                            updateInputValue
                                        }">
                                        <pendo-multiselect-input
                                            ref="control"
                                            v-bind="{
                                                computedPlaceholder,
                                                disabled: isDisabled,
                                                id,
                                                inputValue,
                                                isFullWidth,
                                                isOpen,
                                                isSearchIconVisible,
                                                minTriggerWidth,
                                                shouldAnimateSearchEnterExit:
                                                    !!$slots.selectedLabel || !!$slots.placeholder
                                                        ? false
                                                        : shouldAnimateSearchEnterExit,
                                                showSelectedValueTags,
                                                triggerSize,
                                                triggerWidth,
                                                updateInputValue
                                            }"
                                            @up="handleKeydown('up')"
                                            @down="handleKeydown('down')"
                                            @delete="handleKeydown('delete')"
                                            @right="handleKeydown('right')"
                                            @left="handleKeydown('left')"
                                            @enter="handleKeydown('enter', $event)"
                                            @space="handleKeydown('space', $event)"
                                            @tab="handleKeydown('tab', $event)"
                                            @esc="deactivate" />
                                    </slot>
                                </template>
                                <template #tag="props">
                                    <slot
                                        name="tag"
                                        v-bind="props" />
                                </template>
                                <template #tagLabel="props">
                                    <slot
                                        name="tagLabel"
                                        v-bind="props" />
                                </template>
                                <template #selectedLabel="props">
                                    <slot
                                        name="selectedLabel"
                                        v-bind="props" />
                                </template>
                                <template #placeholder="props">
                                    <slot
                                        name="placeholder"
                                        v-bind="props" />
                                </template>
                            </pendo-multiselect-value>
                            <template #suffix="props">
                                <slot
                                    name="suffix"
                                    v-bind="props" />
                            </template>
                        </pendo-multiselect-trigger>
                    </slot>
                    <template #popper>
                        <div
                            v-if="$slots.aside"
                            class="pendo-multiselect__aside">
                            <slot
                                name="aside"
                                v-bind="{
                                    highlightedOption
                                }" />
                        </div>
                        <div
                            v-if="$slots.header"
                            class="pendo-multiselect__header">
                            <slot
                                name="header"
                                v-bind="{
                                    computedPlaceholder,
                                    deactivate,
                                    handleKeydown,
                                    inputValue,
                                    isSearchable,
                                    model,
                                    removeSelection,
                                    select,
                                    toggleMenu,
                                    updateInputValue
                                }" />
                        </div>
                        <slot
                            name="options"
                            v-bind="$attrs"
                            :is-open="isOpen"
                            :value="model"
                            :filtered-options="filteredOptions"
                            :flattened-options="flattenedOptions"
                            :select="select">
                            <pendo-multiselect-options
                                ref="list"
                                v-bind="{
                                    activate,
                                    allOptionsSelected,
                                    allSelectedText,
                                    allowEmptyOptions,
                                    computedMenuWidth,
                                    deactivate,
                                    disabled,
                                    filteredOptions,
                                    flattenedOptions,
                                    formatOptionLabel,
                                    getOptionLabel,
                                    groupDividers,
                                    groupLabelKey,
                                    groupOptionsKey,
                                    groupSelect,
                                    handleKeydown,
                                    hasGroups,
                                    hideSelectedOptions,
                                    inputValue,
                                    isEntireGroupSelected,
                                    isFullWidth,
                                    isOpen,
                                    isOptionSelected,
                                    isPlaceholderVisible,
                                    isSelectedLabelVisible,
                                    labelKey,
                                    limit,
                                    limitText,
                                    loading,
                                    max,
                                    maxMenuHeight,
                                    maxMenuWidth,
                                    minMenuWidth,
                                    model,
                                    multiple,
                                    name,
                                    noResultsText,
                                    optionHeight,
                                    options,
                                    placeholder,
                                    pointer,
                                    pointerSet,
                                    removeSelection,
                                    scrollConfig,
                                    select,
                                    selectedLabel,
                                    selectedValue,
                                    selectGroup,
                                    shouldScrollToFocusedOption,
                                    showMaxElements,
                                    showNoResults,
                                    showPointer,
                                    taggable,
                                    toggleMenu,
                                    updateInputValue,
                                    valueKey,
                                    visibleSelectedValues
                                }">
                                <template #_beforeList="props">
                                    <slot
                                        name="beforeList"
                                        v-bind="props" />
                                </template>
                                <template #_optionGroup="props">
                                    <slot
                                        name="optionGroup"
                                        v-bind="props" />
                                </template>
                                <template #_option="props">
                                    <slot
                                        name="option"
                                        v-bind="props" />
                                </template>
                                <template #_afterList="props">
                                    <slot
                                        name="afterList"
                                        v-bind="props" />
                                </template>
                                <template #_maxElements>
                                    <slot name="maxElements" />
                                </template>
                                <template #_noResult>
                                    <slot name="noResult" />
                                </template>
                                <template #_noData>
                                    <slot name="noData" />
                                </template>
                                <template #_allSelected>
                                    <slot name="allSelected" />
                                </template>
                            </pendo-multiselect-options>
                        </slot>
                        <div
                            v-if="$slots.footer"
                            class="pendo-multiselect__footer">
                            <slot
                                name="footer"
                                :toggle-menu="toggleMenu" />
                        </div>
                    </template>
                </picker-popover>
                <div
                    v-if="labels.bottom || labels.right || !!$slots.bottomLabel"
                    class="pendo-multiselect__label pendo-multiselect__label--bottom">
                    <slot name="bottomLabel">
                        {{ labels.bottom || labels.right }}
                    </slot>
                </div>
            </div>
        </template>
    </pendo-multiselect-core>
</template>

<script>
import PendoMultiselectCore from '@/components/multiselect/pendo-multiselect-core';
import PendoMultiselectOptions from '@/components/multiselect/pendo-multiselect-options';
import PendoMultiselectInput from '@/components/multiselect/pendo-multiselect-input';
import PendoMultiselectTrigger from '@/components/multiselect/pendo-multiselect-trigger';
import PendoMultiselectValue from '@/components/multiselect/pendo-multiselect-value';
// mixins use relative path to ensure doc generation works
import PendoMultiselectProps from './pendo-multiselect-props';
import PickerPopover from '@/utils/picker-popover';

export default {
    name: 'PendoMultiselect',
    components: {
        PendoMultiselectInput,
        PendoMultiselectCore,
        PendoMultiselectOptions,
        PendoMultiselectTrigger,
        PendoMultiselectValue,
        PickerPopover
    },
    mixins: [PendoMultiselectProps],
    compatConfig: {
        INSTANCE_ATTRS_CLASS_STYLE: false
    },
    inject: {
        $formItem: {
            default: ''
        }
    },
    inheritAttrs: false,
    computed: {
        ariaLabelledBy () {
            if (this.$formItem) {
                return this.$formItem.labelId;
            }

            return undefined;
        },
        inheritedAttrs () {
            const { scopeId } = this.$vnode;
            const { $attrs } = this;

            if (scopeId) {
                return {
                    ...$attrs,
                    [scopeId]: ''
                };
            }

            return $attrs;
        }
    },
    methods: {
        /**
         * @public
         */
        toggleMenu () {
            return this.$refs.core.toggleMenu();
        },
        /**
         * @public
         */
        pointerSet (index) {
            return this.$refs.core.pointerSet(index);
        },
        /**
         * @public
         */
        focusInput () {
            return this.$refs.control.$refs.input.focus();
        },
        /**
         * @public
         */
        updateInputValue (value) {
            return this.$refs.core.updateInputValue(value);
        },
        /**
         * @public
         */
        scrollHighlightedOptionIntoView (index) {
            return this.$refs.list.scrollHighlightedOptionIntoView(index);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect) {
    // top level wrapper must either explicitly set it's width or use an inline property
    // otherwise the click-outside handler may function different than expected
    display: inline-grid;

    @include modifier(full-width) {
        display: unset;
    }

    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-gray-110;

        @include modifier(top) {
            font-weight: 600;
            align-items: start;
        }
        @include modifier(bottom) {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    @include modifier(inline-label) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        grid-gap: 8px;

        @include element(label) {
            display: grid;
            align-items: center;
        }
    }
}

@include block(pendo-multiselect-menu) {
    @include modifier(aside) {
        .pendo-picker-popper__content {
            display: grid;
            grid-auto-flow: row;
        }

        .pendo-multiselect__aside {
            grid-row-start: 1;
            grid-row-end: -1;
        }

        &.pendo-multiselect-menu--header {
            .pendo-picker-popper__content {
                grid-template-rows: auto 1fr;
            }
        }

        &.pendo-multiselect-menu--footer {
            .pendo-picker-popper__content {
                grid-template-rows: 1fr auto;
            }
        }

        &.pendo-multiselect-menu--header.pendo-multiselect-menu--footer {
            .pendo-picker-popper__content {
                grid-template-rows: auto 1fr auto;
            }
        }

        &[x-placement$='start'] {
            .pendo-picker-popper__content {
                grid-template-columns: 1fr max-content;
            }

            .pendo-multiselect__aside {
                grid-column: 2;
                border-left: 1px solid $color-gray-30;
            }

            .pendo-multiselect__header,
            .pendo-multiselect__footer,
            .pendo-multiselect__content-wrapper {
                grid-column: 1;
            }
        }

        &[x-placement$='end'] {
            .pendo-picker-popper__content {
                grid-template-columns: max-content 1fr;
            }

            .pendo-multiselect__aside {
                grid-column: 1;
                border-right: 1px solid $color-gray-30;
            }

            .pendo-multiselect__header,
            .pendo-multiselect__footer,
            .pendo-multiselect__content-wrapper {
                grid-column: 2;
            }
        }
    }
}
</style>
