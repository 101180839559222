<template>
    <div
        class="pendo-color-picker"
        :class="{
            'pendo-color-picker--inline-label': !!inlineLabel,
            'pendo-color-picker--alpha': !disableAlpha,
            'pendo-color-picker--presets': !disablePresets,
            'is-open': isVisible
        }">
        <div
            v-if="!!topLabel"
            class="pendo-color-picker__label pendo-color-picker__label--top">
            {{ topLabel }}
        </div>
        <picker-popover
            ref="popover"
            :is-open="isVisible"
            :append-to-body="true"
            :popper-options="{
                placement: 'auto-start',
                class: 'pendo-color-picker__popover'
            }"
            @click-outside="hideColorPicker">
            <div
                v-pendo-analytics="{ type: 'components:color-picker', value: analyticsTagFromLabels }"
                :class="[
                    {
                        'is-active': isActive,
                        'is-disabled': disabled,
                        'is-focused': isFocused
                    }
                ]"
                :style="{ width: pickerTriggerWidth }"
                class="pendo-color-picker__trigger">
                <color-picker-swatch
                    ref="triggerSwatch"
                    class="pendo-color-picker__trigger-swatch"
                    :disabled="disabled"
                    :aria-expanded="isActive"
                    :aria-label="isVisible ? 'Close color picker' : 'Open color picker'"
                    aria-haspopup="true"
                    :color="activeColor"
                    :size="20"
                    tabindex="0"
                    @click="toggleColorPicker"
                    @keydown.enter.prevent="toggleColorPicker"
                    @keydown.space.prevent="toggleColorPicker" />
                <color-picker-input
                    class="pendo-color-picker__trigger-input"
                    :disabled="disabled"
                    aria-label="hex value"
                    :value="color.hex"
                    :maxlength="!disableAlpha ? 9 : 7"
                    value-key="hex"
                    type="text"
                    @focus="handleInputFocus"
                    @blur="handleInputBlur"
                    @change="handleInputChange" />
            </div>
            <template #popper>
                <div
                    ref="panel"
                    :class="{
                        'pendo-color-picker__panel--alpha': !disableAlpha,
                        'pendo-color-picker__panel--presets': !disablePresets,
                        'alpha-disabled': disableAlpha
                    }"
                    role="menu"
                    class="pendo-color-picker__panel"
                    tabindex="-1"
                    @keydown.esc="onEscKeydown">
                    <div class="panel-saturation">
                        <color-picker-saturation
                            :width="!disableAlpha ? 200 : 184"
                            :color="color"
                            @change="handleChildChange" />
                    </div>
                    <div class="panel-control-group">
                        <div class="panel-sliders">
                            <color-picker-slider
                                aria-label="hue"
                                value-key="h"
                                role="menuitem"
                                :color="color"
                                class="panel-hue"
                                @change="handleChildChange" />
                            <color-picker-slider
                                v-if="!disableAlpha"
                                aria-label="alpha"
                                role="menuitem"
                                value-key="alpha"
                                :color="color"
                                class="panel-alpha"
                                @change="handleChildChange" />
                        </div>
                        <color-picker-swatch
                            aria-hidden="true"
                            tabindex="-1"
                            :color="activeColor"
                            size="100%" />
                    </div>
                    <div
                        v-if="!disableFields"
                        class="panel-input-group">
                        <div class="panel-input-group--hex">
                            <color-picker-input
                                aria-label="hex value"
                                role="menuitem"
                                :value="color.hex"
                                :maxlength="!disableAlpha ? 9 : 7"
                                type="text"
                                value-key="hex"
                                @change="handleInputChange" />
                        </div>
                        <div class="panel-input-group--rgba">
                            <color-picker-input
                                aria-label="red value"
                                role="menuitem"
                                :value="color.r"
                                :max="255"
                                type="number"
                                value-key="r"
                                @change="handleInputChange" />
                            <color-picker-input
                                aria-label="green value"
                                role="menuitem"
                                :value="color.g"
                                :max="255"
                                type="number"
                                value-key="g"
                                @change="handleInputChange" />
                            <color-picker-input
                                aria-label="blue value"
                                role="menuitem"
                                :value="color.b"
                                :max="255"
                                type="number"
                                value-key="b"
                                @change="handleInputChange" />
                            <color-picker-input
                                v-if="!disableAlpha"
                                aria-label="alpha value"
                                role="menuitem"
                                :value="color.alpha * 100"
                                :max="100"
                                type="number"
                                value-key="alpha"
                                label="a"
                                @change="handleInputChange" />
                        </div>
                    </div>
                    <div
                        v-if="!disablePresets"
                        class="panel-presets-group"
                        role="menuitem">
                        <color-picker-presets
                            :value="color.hex"
                            :presets="presets"
                            :size="16"
                            @change="handlePresetClick" />
                    </div>
                </div>
            </template>
        </picker-popover>
        <div
            v-if="bottomLabel"
            class="pendo-color-picker__label pendo-color-picker__label--bottom">
            {{ bottomLabel }}
        </div>
    </div>
</template>

<script>
import labelsMixin from '@/mixins/labels';
import PickerPopover from '@/utils/picker-popover';
import ColorPickerInput from '@/components/color-picker/common/color-picker-input';
import ColorPickerSaturation from '@/components/color-picker/common/color-picker-saturation';
import ColorPickerSlider from '@/components/color-picker/common/color-picker-slider';
import ColorPickerPresets from '@/components/color-picker/common/pendo-color-picker-presets';
import ColorPickerSwatch from '@/components/color-picker/common/color-picker-swatch';

import { ref } from 'vue';
import { DEFAULT_PRESETS } from '@/components/color-picker/utils';
import { useFocusTrap } from '@/utils/use-focus-trap';
import { getColor, toRgbString, isValidHex, normalizeHex } from '@/utils/color';

export default {
    name: 'PendoColorPicker',
    components: {
        ColorPickerSwatch,
        ColorPickerSlider,
        ColorPickerInput,
        ColorPickerSaturation,
        ColorPickerPresets,
        PickerPopover
    },
    mixins: [labelsMixin],
    props: {
        /**
         * @ignore
         * starting value for color picker
         */
        initialColor: {
            type: [String, Object],
            default: '#000000'
        },
        /**
         * bound value
         */
        value: {
            type: [String, Object],
            default: null
        },
        /**
         * if specified, will emit associated value color object.
         * @values r, g, b, alpha, hex, hex8, hsv, hsl
         */
        valueKey: {
            type: String,
            default: null,
            validator: (valueKey) => ['r', 'g', 'b', 'alpha', 'hex', 'hex8', 'hsv', 'hsl'].includes(valueKey)
        },
        /**
         * disables user interaction
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * hides preset panel
         */
        disablePresets: {
            type: Boolean,
            default: false
        },
        /**
         * hides alpha choices and only allows 6 digit hex values to be entered
         */
        disableAlpha: {
            type: Boolean,
            default: false
        },
        /**
         * hides input fields in panel
         */
        disableFields: {
            type: Boolean,
            default: false
        },
        /**
         * Array of preset colors to show
         */
        presets: {
            type: Array,
            default: () => DEFAULT_PRESETS
        },
        /**
         * Convenience prop to set the input width
         */
        triggerWidth: {
            type: [Number, String],
            default: 132
        }
    },
    setup () {
        const panel = ref();
        const isFocusTrapActive = ref(false);

        useFocusTrap(panel, isFocusTrapActive);

        return {
            panel,
            isFocusTrapActive
        };
    },
    data () {
        return {
            isFocused: false,
            isVisible: false,
            internalColor: null
        };
    },
    computed: {
        color: {
            get () {
                return getColor(this.value ? this.value : this.internalColor);
            },
            set (color) {
                this.internalColor = color;
                /**
                 * Emitted when bound value changes
                 *
                 * @event input
                 * @property Any Current model value
                 */
                this.$emit('input', color[this.valueKey] || color);
                /**
                 * Emitted when bound value changes
                 *
                 * @event change
                 * @property Any Current model value
                 */
                this.$emit('change', color[this.valueKey] || color);
            }
        },
        pickerTriggerWidth () {
            if (typeof this.triggerWidth === 'string') {
                return this.triggerWidth;
            }

            return `${this.triggerWidth}px`;
        },
        isActive () {
            return this.isFocused || this.isVisible;
        },
        activeColor () {
            return toRgbString(this.color);
        }
    },
    watch: {
        isActive (val) {
            /**
             * Emitted when panel visibility or input focus changes
             *
             * @event active
             * @property Boolean Boolean indicating if active or not
             */
            this.$emit('active', val);
        }
    },
    created () {
        this.color = getColor(this.value ? this.value : this.initialColor);
    },
    methods: {
        updateColor (data) {
            this.color = getColor(data);
        },
        handlePresetClick (color) {
            this.updateColor({
                hex: color
            });
        },
        handleChildChange (data) {
            this.updateColor({
                alpha: this.color.alpha,
                ...data
            });
        },
        handleInputChange (data) {
            if (!data) {
                return;
            }
            const { hex, r, g, b, alpha } = data;

            if (hex && isValidHex(hex)) {
                this.updateColor({
                    hex: normalizeHex(hex)
                });
            } else if (r !== undefined || g !== undefined || b !== undefined || alpha !== undefined) {
                this.updateColor({
                    r: r !== undefined ? r : this.color.r,
                    g: g !== undefined ? g : this.color.g,
                    b: b !== undefined ? b : this.color.b,
                    alpha: alpha !== undefined ? alpha / 100 : this.color.alpha
                });
            }
        },
        handleInputFocus () {
            this.isFocused = true;
        },
        handleInputBlur () {
            this.isFocused = false;
        },
        toggleColorPicker () {
            if (this.isVisible) {
                this.hideColorPicker();
            } else {
                this.showColorPicker();
            }
        },
        onEscKeydown () {
            this.hideColorPicker();
        },
        async showColorPicker () {
            this.isVisible = true;
            /**
             * Emitted when color picker panel becomes visible
             *
             * @event show
             * @property Any Current model value
             */
            this.$emit('show', this.color[this.valueKey] || this.color);

            await this.$nextTick();
            this.isFocusTrapActive = true;
        },
        hideColorPicker () {
            this.isVisible = false;
            /**
             * Emitted when color picker panel is hidden
             *
             * @event hide
             * @property Any Current model value
             */
            this.$emit('hide', this.color[this.valueKey] || this.color);
            this.isFocusTrapActive = false;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-color-picker) {
    @include element(label) {
        @include font-base;
        @include font-family;
        display: grid;
        height: 24px;
        color: $color-gray-110;

        @include modifier(top) {
            font-weight: 600;
            align-items: start;
        }

        @include modifier(bottom) {
            color: $color-text-secondary;
            align-items: end;
        }
    }

    @include modifier(inline-label) {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(2, max-content);
        align-items: center;
        grid-gap: 8px;

        @include element(label) {
            display: grid;
            align-items: center;
        }
    }

    @include element(trigger) {
        border: 1px solid $color-gray-40;
        background-color: $color-white;
        border-radius: $border-radius-3;
        display: grid;
        grid-template-columns: 34px 1fr;
        justify-items: center;
        align-items: center;
        overflow: hidden;
        @include focus-ring(
            $style: 'base',
            $transitions: (
                border 300ms,
                background-color 300ms,
                color 300ms
            )
        );

        &:hover,
        &.is-active {
            border-color: $color-gray-100;
        }

        &.is-focused {
            // input elements that require text input show focus-visible in same instances as focus
            @include focus-ring($style: 'focused');
        }

        @include is(disabled) {
            background-color: $disabled-fill;
            color: $color-gray-50;

            &:hover {
                cursor: not-allowed;
                border-color: $disabled-border;
            }

            @include element(trigger-input) {
                input {
                    cursor: not-allowed;
                    background-color: $disabled-fill;
                    color: $color-gray-50;
                }
            }

            @include element(trigger-swatch) {
                cursor: not-allowed;
            }
        }
    }

    @include element(trigger-swatch) {
        cursor: pointer;
    }

    @include element(trigger-input) {
        input {
            text-align: left;
            text-transform: uppercase;
            background-color: $color-white;
            box-shadow: none;
            background-image: none;
            border: 0;
            outline: 0;
            padding: 0 8px 0 0;
            height: 34px;
            line-height: 34px;
            width: calc(100% - 16px);
            font-size: 14px;
            transition: border-color 150ms;
            -webkit-appearance: none;
            @include font-family;

            &:focus {
                box-shadow: none;
            }
        }

        label {
            display: none;
        }
    }

    @include element(panel) {
        z-index: 100;
        padding: 8px;
        width: auto;
        position: relative;
        max-width: 200px;
        box-sizing: border-box;
        background: $color-white;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.17);
        border-radius: $border-radius-3;

        &:active,
        &:focus {
            outline: none;
        }

        @include modifier(alpha) {
            max-width: 248px;
        }

        &[x-placement^='top'] {
            margin-bottom: 8px;
        }

        &[x-placement^='bottom'] {
            margin-top: 8px;
        }

        &[x-placement^='right'] {
            margin-left: 8px;
        }

        &[x-placement^='left'] {
            margin-right: 8px;
        }

        .panel-saturation {
            width: 100%;
            position: relative;
            overflow: hidden;
            user-select: none;
            margin: 0 auto;
        }

        .panel-control-group {
            display: grid;
            grid-template-columns: 1fr 24px;
            margin: 4px 0;
            grid-gap: 4px;

            .panel-sliders {
                grid-gap: 4px;
                height: 100%;
                display: grid;
                align-items: center;
                align-content: space-between;
            }
        }

        .panel-input-group {
            display: grid;
            grid-gap: 4px;
            align-items: center;
            grid-template-columns: repeat(auto-fill, minmax(56px, auto));

            &--hex {
                grid-column: span 1;
            }

            &--rgba {
                grid-column: span 2;
                grid-auto-flow: column;
                display: grid;
                grid-gap: 4px;
            }
        }

        .panel-presets-group {
            padding-top: 8px;
            margin-top: 4px;
            border-top: 1px solid $color-gray-30;
        }
    }
}
</style>
