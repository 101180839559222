<template>
    <div
        class="pendo-table__header"
        :style="$table.scrollStore.pageMode && { top: `${$table.scrollStore.stickyElementPositions.header}px` }">
        <table
            role="presentation"
            cellspacing="0"
            cellpadding="0"
            border="0"
            :style="{
                width: $table.bodyWidth === null ? $table.bodyWidth : `${$table.bodyWidth}px`
            }">
            <colgroup>
                <col
                    v-for="(column, columnIndex) in $table.tableColumns"
                    :key="columnIndex"
                    :name="column.id"
                    :width="getColgroupColRenderWidth(column, columnIndex)">
            </colgroup>
            <thead role="rowgroup">
                <tr
                    :class="headerRowClassName"
                    role="row">
                    <table-header-cell
                        v-for="(column, columnIndex) in $table.tableColumns"
                        ref="column"
                        :key="column.columnKey || columnIndex"
                        :column="column"
                        :column-index="columnIndex"
                        @resize="handleResizeMousedown" />
                </tr>
            </thead>
        </table>
        <div
            class="pendo-table__repair"
            :style="{
                width: `${$table.bodyWidth}px`
            }" />
    </div>
</template>

<script>
import clamp from 'lodash/clamp';
import isFunction from 'lodash/isFunction';
import { elementPositionInAncestor } from '@/utils/dom';
import TableHeaderCell from '@/components/table/table-header-cell';

export default {
    name: 'TableHeader',
    components: {
        TableHeaderCell
    },
    inject: ['$table'],
    data () {
        return {
            dragStore: {}
        };
    },
    computed: {
        headerRowClassName () {
            if (this.$table.headerRowClassName) {
                return isFunction(this.$table.headerRowClassName)
                    ? this.$table.headerRowClassName({ row: {} })
                    : this.$table.headerRowClassName;
            }

            return '';
        }
    },
    methods: {
        getColgroupColRenderWidth (column, index) {
            const lastColumnIndex = this.$table.tableColumns.length - 1;
            const isLastColumn = index === lastColumnIndex;

            if (isLastColumn && this.$table.overflowX) {
                return column.renderWidth + this.$table.verticalScrollbarWidth;
            }

            return column.renderWidth;
        },
        handleResizeMousedown (event, column, columnIndex) {
            const { target } = event;
            const { left, top } = elementPositionInAncestor(target, this.$table.$el);

            const tableScrollLeft = this.$table.scrollStore.scrollLeft;
            const tableClientRect = this.$table.$el.getBoundingClientRect();
            const tableRightBoundary = tableClientRect.right - tableClientRect.left;

            const columnEl = target.closest('th.pendo-table__column');
            const columnElPos = elementPositionInAncestor(columnEl, this.$el);
            const colScrollLeft = columnElPos.left - tableScrollLeft;

            this.dragStore = {
                minLeft: colScrollLeft + column.minWidth - 3,
                maxRight: column.fixed === 'left' ? tableRightBoundary - 96 : Infinity,
                posLeft: left + 2 - tableScrollLeft,
                posTop: top,
                clientX: event.clientX,
                dragLeft: 0,
                column,
                columnIndex
            };

            this.$el.classList.toggle('is-dragging');
            this.$table.$refs.resizeBar.style.top = `${top}px`;
            this.$table.$refs.resizeBar.style.display = 'block';

            document.addEventListener('mousemove', this.handleResizeMousemove);
            document.addEventListener('mouseup', this.handleResizeMouseup);

            this.handleResizeMousemove(event);
        },
        handleResizeMousemove (event) {
            event.preventDefault();

            const { clientX, posLeft, minLeft, posTop, maxRight } = this.dragStore;
            const offsetX = event.clientX - clientX;
            const left = posLeft + offsetX;
            const dragLeft = clamp(left, minLeft, maxRight);

            this.$table.$refs.resizeBar.style.left = `${dragLeft}px`;
            this.$table.$refs.resizeBar.style.top = `${posTop}px`;
            this.dragStore.dragLeft = dragLeft;
        },
        handleResizeMouseup (event) {
            event.preventDefault();
            document.removeEventListener('mousemove', this.handleResizeMousemove);
            document.removeEventListener('mouseup', this.handleResizeMouseup);

            const { posLeft, dragLeft, column, columnIndex } = this.dragStore;
            const width = column.renderWidth - (posLeft - dragLeft);

            this.$table.tableColumns[columnIndex].widthType = 'resize';
            this.$table.tableColumns[columnIndex].resizeWidth = Math.max(width, column.minWidth);

            this.$el.classList.toggle('is-dragging');
            this.$table.$refs.resizeBar.style.display = 'none';
            this.dragStore = {};

            requestAnimationFrame(() => {
                this.$table.refreshLayout();
                const column = this.$table.tableColumns[columnIndex];
                this.$table.$emit('column-resize', {
                    column: column.property,
                    width: column.resizeWidth
                });
            });
        }
    }
};
</script>
