<template>
    <div
        class="pendo-progress-circle"
        :class="{
            'is-complete': isComplete,
            'is-in-progress': isInProgress,
            'is-not-started': isNotStarted
        }"
        role="progressbar"
        :aria-valuenow="value"
        :style="{
            height: `${width}px`,
            width: `${width}px`
        }">
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100">
            <circle
                :cx="cx"
                :cy="cy"
                :r="outerRadius"
                class="pendo-progress-circle__outer-track"
                :fill="colors[isComplete ? 'complete' : 'default'].fill"
                :stroke="colors[isComplete ? 'complete' : 'default'].stroke"
                :stroke-width="relativeStrokeWidth" />
            <circle
                :cx="cx"
                :cy="cy"
                :r="innerRadius"
                :stroke="colors[isComplete ? 'complete' : 'default'].stroke"
                :fill="colors[isComplete ? 'complete' : 'default'].fill"
                :stroke-width="relativeStrokeWidth"
                class="pendo-progress-circle__inner-track" />
            <template v-if="isInProgress">
                <path
                    :d="outerProgressArc"
                    :stroke-width="relativeStrokeWidth"
                    :stroke="colors.progress.stroke"
                    :fill="colors.progress.fill"
                    class="pendo-progress-circle__outer-arc" />
                <path
                    :d="innerProgressArc"
                    :stroke-width="relativeStrokeWidth"
                    :stroke="colors.progress.stroke"
                    class="pendo-progress-circle__inner-arc" />
            </template>
            <circle
                :cx="cx"
                :cy="cy"
                :r="fillRadius"
                :fill="colors.center.fill"
                :stroke="colors.center.stroke"
                :stroke-width="relativeStrokeWidth"
                class="pendo-progress-circle__inner-fill" />
        </svg>
        <div
            v-if="$slots.default"
            class="pendo-progress-circle__text"
            :style="{
                maxHeight: `${maxFillSize}px`,
                maxWidth: `${maxFillSize}px`
            }">
            <slot :value="value" />
        </div>
    </div>
</template>

<script>
import clamp from 'lodash/clamp';
import {
    isNotStarted,
    isInProgress,
    isComplete,
    getRelativeThickness,
    getRelativeStrokeWidth,
    getTrickleProgress,
    queue
} from '@/components/progress/utils';

export default {
    name: 'CircleProgress',
    props: {
        /**
         * percentage value for current progress
         */
        value: {
            type: Number,
            default: 0,
            required: true,
            validator: (val) => val >= 0 && val <= 100
        },
        /**
         * thickness of progress path
         */
        thickness: {
            type: Number,
            default: 0
        },
        /**
         * width of progress border
         */
        strokeWidth: {
            type: Number,
            default: 1
        },
        /**
         * width of progress bar
         */
        width: {
            type: Number,
            default: 200
        },
        /**
         * color config for progress states
         */
        colors: {
            type: Object,
            default: () => ({
                center: {
                    fill: '#ffffff',
                    stroke: '#ffffff'
                },
                default: {
                    fill: '#DADCE5',
                    stroke: '#dadce5'
                },
                progress: {
                    fill: '#DB1111',
                    stroke: '#6A0000'
                },
                complete: {
                    fill: '#00C583',
                    stroke: '#005027'
                }
            })
        },
        /**
         * Automatically increment progress behavior by setting this to `true`. default: `false`
         */
        trickle: {
            type: Boolean,
            default: false
        },
        /**
         * Adjust how often to trickle/increment, in ms. default: `200`
         */
        trickleSpeed: {
            type: Number,
            default: 200
        },
        /**
         * Animation speed (in ms). default: `200`
         */
        speed: {
            type: Number,
            default: 200
        }
    },
    data () {
        return {
            cx: 50,
            cy: 50,
            internalValue: this.value,
            minInProgressValue: 2,
            maxInProgressValue: 99.4
        };
    },
    computed: {
        relativeStrokeWidth () {
            const { strokeWidth, width } = this;

            return getRelativeStrokeWidth({ strokeWidth, width });
        },
        relativeThickness () {
            const { thickness, width } = this;

            return getRelativeThickness({ thickness, width });
        },
        maxFillSize () {
            return this.width - this.fillRadius;
        },
        isInProgress () {
            return isInProgress(this.internalValue);
        },
        isComplete () {
            return isComplete(this.internalValue);
        },
        isNotStarted () {
            return isNotStarted(this.internalValue);
        },
        fillRadius () {
            return this.innerRadius - this.relativeStrokeWidth;
        },
        innerRadius () {
            return this.cx - this.relativeThickness;
        },
        outerRadius () {
            return this.cx - this.relativeStrokeWidth;
        },
        relativeCoords () {
            const value = this.internalValue / 100;
            const exprBase = -value * 2 * Math.PI + 0.5 * Math.PI;

            return {
                x: Math.cos(exprBase),
                y: -Math.sin(exprBase)
            };
        },
        outerProgressArc () {
            const path = [];
            path.push(`M 50 ${this.relativeStrokeWidth}`);
            path.push(`A ${this.outerRadius} ${this.outerRadius}`);
            path.push('0');
            path.push(this.internalValue > 50 ? '1' : '0');
            path.push('1');
            path.push(this.getArcCoords(this.outerRadius));
            path.push('L 50 50');
            path.push('z');

            return path.join(' ');
        },
        innerProgressArc () {
            const path = [];
            path.push(`M 50 ${this.relativeThickness}`);
            path.push(`A ${this.innerRadius} ${this.innerRadius}`);
            path.push('0');
            path.push(this.internalValue > 50 ? '1' : '0');
            path.push('1');
            path.push(this.getArcCoords(this.innerRadius));
            path.push('L 50 50');
            path.push('z');

            return path.join(' ');
        }
    },
    watch: {
        value () {
            if (!this.trickle) {
                this.internalValue = this.value;

                return;
            }

            const value = clamp(this.value, this.internalValue, 100);

            if (value === 100) {
                this.done();
            } else {
                this.increment(value);
            }
        }
    },
    beforeUnmount () {
        if (this.timer) {
            clearInterval(this.timer);
        }
    },
    methods: {
        getArcCoords (radius) {
            const coordX = Math.round((this.relativeCoords.x * radius + 50) * 100) / 100;
            const coordY = Math.round((this.relativeCoords.y * radius + 50) * 100) / 100;

            return `${coordX} ${coordY}`;
        },
        increment (amount) {
            if (this.isNotStarted) {
                this.start();
            }

            if (this.isComplete) {
                return;
            }

            if (typeof amount !== 'number') {
                amount = this.internalValue + getTrickleProgress(this.internalValue);
            }

            amount = clamp(amount, 0, this.maxInProgressValue);
            this.set(amount);
        },
        done (force) {
            if (!force && !this.internalValue) {
                return;
            }

            this.increment(30 + 50 * Math.random());
            this.set(100);
        },
        start () {
            if (this.isNotStarted) {
                this.set(0);
            }

            if (this.trickle) {
                this.timer = setInterval(() => {
                    this.increment();
                    if (this.isComplete) {
                        clearInterval(this.timer);
                    }
                }, this.trickleSpeed);
            }
        },
        set (value) {
            value = clamp(value, this.minInProgressValue, 100);

            queue((next) => {
                if (value === 1) {
                    setTimeout(() => {
                        setTimeout(() => {
                            this.internalValue = 100;
                            next();
                        }, this.speed);
                    }, this.speed);
                } else {
                    this.internalValue = value;
                    setTimeout(next, this.speed);
                }
            });
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-progress-circle) {
    position: relative;
    line-height: 1;

    @include element(text) {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        width: 100%;
        text-align: center;
        margin: auto;
        transform: translate(0, -50%);
    }
}
</style>
