<template>
    <div class="pendo-table__multiselect-cell">
        <div
            v-if="!isOpen"
            ref="facadeTrigger"
            tabindex="0"
            :class="triggerClasses"
            role="button"
            @click="toggleMultiselect"
            @keydown.space="toggleMultiselect"
            @keydown.enter="toggleMultiselect">
            <slot
                name="trigger"
                :selected-option="value">
                {{ value[labelKey] }}
            </slot>
            <div class="pendo-multiselect__suffix">
                <pendo-icon
                    v-if="!loading"
                    type="chevron-down"
                    size="14"
                    class="pendo-multiselect__caret" />
                <pendo-loading-indicator
                    v-if="loading"
                    :spinner-props="{
                        color: '#6a6c75'
                    }"
                    type="material"
                    size="small" />
            </div>
        </div>
        <pendo-multiselect
            v-if="isOpen"
            ref="multiselectCell"
            :value="value"
            :loading="loading"
            :searchable="false"
            :options="options"
            :full-width="true"
            :min-menu-width="minMenuWidth"
            :option-height="optionHeight"
            :group-options-key="groupOptionsKey"
            :group-label-key="groupLabelKey"
            :value-key="valueKey"
            :label-key="labelKey"
            trigger-size="mini"
            @select="$emit('select', { row, value: $event })"
            @closed="isOpen = false">
            <template
                #header="{
                    deactivate,
                    handleKeydown,
                    select,
                    updateInputValue
                }">
                <div class="pendo-multiselect-cell-picker-popper__header">
                    <pendo-input
                        ref="searchInput"
                        placeholder="Search"
                        @keydown.up="handleKeydown('up')"
                        @keydown.down="handleKeydown('down')"
                        @keydown.delete.self="handleKeydown('delete')"
                        @keydown.enter.stop.self="handleKeydown('enter', $event)"
                        @keydown.tab="handleKeydown('tab')"
                        @keydown.esc="deactivate"
                        @input="updateInputValue">
                        <template #prefix>
                            <pendo-icon
                                class="pendo-multiselect__search-icon"
                                type="search"
                                size="14" />
                        </template>
                    </pendo-input>
                </div>
                <slot
                    name="stickyOption"
                    :select="select" />
            </template>
            <template #option="{ option }">
                <slot
                    name="option"
                    :option="option" />
            </template>

            <template #selectedLabel="{ option }">
                <slot
                    name="selectedLabel"
                    :option="option" />
            </template>
            <template #footer="{ toggleMenu }">
                <slot
                    name="footer"
                    :toggle-menu="toggleMenu" />
            </template>
        </pendo-multiselect>
    </div>
</template>

<script>
import PendoIcon from '@/components/icon/pendo-icon';
import PendoLoadingIndicator from '@/components/loading-indicator/pendo-loading-indicator';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoInput from '@/components/input/pendo-input';

export default {
    name: 'MultiselectCell',
    components: {
        PendoIcon,
        PendoInput,
        PendoLoadingIndicator,
        PendoMultiselect
    },
    inject: ['$table'],
    props: {
        row: {
            type: Object,
            required: true
        },
        value: {
            type: [Object, String, Number],
            required: true
        },
        options: {
            type: Array,
            default: () => []
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelKey: {
            type: String,
            default: 'label'
        },
        groupOptionsKey: {
            type: String,
            default: undefined
        },
        groupLabelKey: {
            type: String,
            default: undefined
        },
        valueKey: {
            type: String,
            default: undefined
        },
        minMenuWidth: {
            type: String,
            default: '200'
        },
        optionHeight: {
            type: Number,
            default: 26
        }
    },
    data () {
        return {
            isOpen: false
        };
    },
    computed: {
        triggerClasses () {
            return [
                'pendo-multiselect__trigger',
                'pendo-multiselect__trigger--mini',
                {
                    'is-disabled': this.loading || this.disabled
                }
            ];
        }
    },
    watch: {
        '$table.scrollStore.scrollActive': function (value) {
            if (value) {
                this.isOpen = false;
            }
        }
    },
    methods: {
        async toggleMultiselect () {
            if (this.loading || this.disabled) {
                return;
            }

            this.isOpen = true;
            await this.$nextTick();

            if (this.$refs.multiselectCell) {
                this.$refs.multiselectCell.toggleMenu();
            }
            await this.$nextTick();
            this.$refs.searchInput.focus();
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-table) {
    @include element(multiselect-cell) {
        .pendo-multiselect__trigger {
            border: none;
            background: none;
            font-variant-numeric: tabular-nums;
            line-height: 16px;
            justify-content: space-between;
            padding-left: 0px;

            &:after {
                content: '';
                border-bottom: 1px solid transparent;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }

            &.is-active,
            &.is-open {
                &:after {
                    border-color: $color-link-hover;
                }
            }

            &:hover,
            &:focus {
                &:after {
                    border-color: $color-link-hover;
                }
            }
        }
    }
}

// since these styles are for the picker-popper that is appeneded to the body, they are scoped differently
@include block(pendo-multiselect-cell-picker-popper) {
    @include element(header) {
        padding: 16px;
        border-bottom: 1px solid $color-gray-30;
    }
}
</style>
