<template>
    <footer class="pendo-page-footer">
        <slot />
    </footer>
</template>

<script>
export default {
    name: 'PendoPageFooter'
};
</script>

<style lang="scss">
@include block(pendo-page-footer) {
    min-height: 82px;
    padding: 0 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    grid-row: 2;
}
</style>
