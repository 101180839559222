<template>
    <div
        class="pendo-multiselect-option--icon"
        v-bind="$attrs"
        v-on="$listeners">
        <pendo-icon
            v-if="icon"
            v-bind="icon" />
        <span
            ref="label"
            v-pendo-tooltip="{
                content: label,
                multiline: true,
                trigger: 'manual',
                offset: 0,
                show: showOverflowTooltip
            }"
            class="pendo-multiselect-option__label"
            @mouseenter="onOptionMouseenter"
            @mouseleave="onOptionMouseleave">
            {{ label }}
        </span>
    </div>
</template>

<script>
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoIcon from '@/components/icon/pendo-icon';
import { shouldAttachOverflowTooltip } from '@/utils/dom';

export default {
    name: 'PendoIconOption',
    components: {
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inject: ['multiselect'],
    props: {
        option: {
            type: [Object, String],
            required: true
        }
    },
    data () {
        return {
            showOverflowTooltip: false,
            tooltipTimeout: null
        };
    },
    computed: {
        label () {
            return this.multiselect.getOptionLabel(this.option);
        },
        icon () {
            if (this.option.icon) {
                return {
                    size: 14,
                    ...this.option.icon
                };
            }

            let type;
            // support grabbing icon from id or kind to ease with support of icons in appengine segment builder
            if (['feature', 'page', 'trackType', 'guide', 'guideElement', 'poll'].includes(this.option.id)) {
                type = this.option.id;
            } else if (['resourceCenter'].includes(this.option.id)) {
                type = 'guide';
            } else if (['feedbackRequest', 'idea'].includes(this.option.id)) {
                type = 'lightbulb';
            } else if (['visitor', 'account', 'parentAccount'].includes(this.option.kind)) {
                type = this.option.kind;
            } else if (['ios', 'android', 'web', 'extension'].includes(this.option.platform)) {
                type = this.generateAppTypeIcon(this.option.platform);
            }

            if (type) {
                return {
                    size: 14,
                    type
                };
            }

            return null;
        }
    },
    methods: {
        onOptionMouseenter () {
            const showOverflowTooltip = shouldAttachOverflowTooltip(this.label, this.$refs.label);

            if (showOverflowTooltip) {
                clearTimeout(this.tooltipTimeout);
                this.tooltipTimeout = setTimeout(() => {
                    this.showOverflowTooltip = true;
                }, 300);
            }
        },
        onOptionMouseleave () {
            clearTimeout(this.tooltipTimeout);
            this.showOverflowTooltip = false;
        },
        generateAppTypeIcon (platform) {
            if (platform === 'web') {
                return 'monitor';
            }

            if (platform === 'extension') {
                return 'puzzle-piece';
            }

            return platform;
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-multiselect-option) {
    @include modifier(icon) {
        @include font-base;
        line-height: 26px;
        user-select: none;
        height: 100%;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: auto 1fr;
        align-items: center;
        overflow: hidden;

        @include element(label) {
            @include ellipsis;
        }
    }
}
</style>
