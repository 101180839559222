<template>
    <pendo-card
        class="pendo-theme-grid"
        :title="title"
        :disabled="disabled">
        <slot name="empty" />
        <pendo-chooser-grid
            :value="[]"
            :disabled="disabled"
            :items="formattedThemes"
            :filters="filters"
            @select="selectTheme"
            @visible-items-change="onVisibleItemsChange">
            <template #media="{ item }">
                <div class="pendo-theme-grid__app">
                    <pendo-app-icon
                        :app="item.app"
                        size="16"
                        class="themes-droplet" />
                    <div class="pendo-theme-grid__app-name">
                        {{ item.app && item.app.displayName }}
                    </div>
                </div>
                <pendo-theme-swatch
                    v-if="item.swatch"
                    :swatch="item.swatch" />
            </template>
            <template #overlay="{ item, select }">
                <div
                    v-if="item.allowActions"
                    class="pendo-theme-grid__actions">
                    <pendo-icon
                        v-if="item.allowActions.duplicate"
                        v-pendo-tooltip="'Duplicate Theme'"
                        class="pendo-theme-grid__duplicate-theme"
                        type="copy"
                        stroke="#BABCC5"
                        size="18"
                        @click="promptConfirmDuplicate(item)" />
                    <pendo-icon
                        v-if="item.allowActions.delete"
                        v-pendo-tooltip="'Delete Theme'"
                        class="pendo-theme-grid__delete-theme"
                        type="trash-2"
                        stroke="#BABCC5"
                        size="18"
                        @click="promptConfirmDelete(item)" />
                </div>
                <pendo-button
                    theme="p2-dark"
                    type="secondary"
                    class="pendo-theme-grid__edit-theme"
                    label="Edit Theme"
                    @click="select(item)" />
            </template>
            <template #title="{ item }">
                <div>{{ item.title }}</div>
            </template>
            <template #subtitle="{ item }">
                <div
                    v-if="item.createdByUser"
                    v-pendo-tooltip="{
                        content: `Created by: ${item.createdByUser.username}`,
                        delay: { show: 300, hide: 0 }
                    }"
                    class="pendo-theme-grid__created-by">
                    Created by: {{ item.createdByUser.username }}
                </div>
                <div
                    v-if="item.lastUpdatedByUser"
                    v-pendo-tooltip="{
                        content: `Last updated by: ${item.lastUpdatedByUser.username}`,
                        delay: { show: 300, hide: 0 }
                    }"
                    class="pendo-theme-grid__last-updated-by">
                    Last updated by: {{ item.lastUpdatedByUser.username }}
                </div>
            </template>
        </pendo-chooser-grid>
        <pendo-modal
            :visible="showModal"
            append-to-body
            :show-close="true"
            type="confirmation"
            :title="modalConfig.title"
            :confirm-button-config="modalConfig.confirmButtonConfig"
            @close="resetPendingActionState"
            @cancel="resetPendingActionState"
            @confirm="confirmPendingAction">
            <slot
                name="pendingActionModal"
                :theme="pendingTheme"
                :action="pendingAction">
                <template v-if="pendingTheme && pendingAction === 'delete'">
                    Are you sure you want to delete <strong>{{ pendingTheme.name }}</strong>? Any guides with this theme applied will not be affected and appear as a Custom Theme. Reminder:
                    You can apply other themes to guides from within the Designer.
                </template>
                <template v-if="pendingTheme && pendingAction === 'duplicate'">
                    <div class="pendo-theme-grid-modal">
                        <div>
                            This theme will be duplicated to the
                            <strong>{{ pendingTheme.app.displayName }}</strong> application.
                        </div>
                        <div class="pendo-theme-grid-modal__app-selector">
                            <pendo-multiselect
                                v-if="formattedApps.length > 1"
                                v-model="pendingTheme.app"
                                full-width
                                label-key="displayName"
                                :labels="{ top: 'Duplicate to App:' }"
                                :allow-empty="false"
                                :options="formattedApps">
                                <template #selectedLabel="{ option }">
                                    <pendo-icon-option :option="option" />
                                </template>
                                <template #option="{ option }">
                                    <pendo-icon-option :option="option" />
                                </template>
                            </pendo-multiselect>
                        </div>
                        <div class="pendo-theme-grid-modal__name-input">
                            <pendo-input
                                v-model="pendingTheme.name"
                                :labels="{ top: 'Theme Name' }" />
                        </div>
                    </div>
                </template>
            </slot>
        </pendo-modal>
    </pendo-card>
</template>

<script>
import get from 'lodash/get';
import DOMPurify from 'dompurify';
import PendoButton from '@/components/button/pendo-button';
import PendoCard from '@/components/card/pendo-card';
import PendoChooserGrid from '@/components/chooser-grid/pendo-chooser-grid';
import PendoAppIcon from '@/composites/app-icon/pendo-app-icon';
import PendoInput from '@/components/input/pendo-input';
import PendoModal from '@/components/modal/pendo-modal';
import PendoTooltip from '@/directives/tooltip/pendo-tooltip';
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';
import PendoIconOption from '@/components/multiselect/option-types/pendo-icon-option';
import PendoThemeSwatch from '@/composites/themes/theme-swatch/pendo-theme-swatch';
import PendoIcon from '@/components/icon/pendo-icon';

export default {
    name: 'PendoThemeGrid',
    components: {
        PendoCard,
        PendoChooserGrid,
        PendoButton,
        PendoAppIcon,
        PendoIconOption,
        PendoInput,
        PendoModal,
        PendoMultiselect,
        PendoThemeSwatch,
        PendoIcon
    },
    directives: {
        PendoTooltip
    },
    inheritAttrs: false,
    props: {
        title: {
            type: String,
            default: null
        },
        themes: {
            type: Array,
            required: true
        },
        apps: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        allowActions: {
            type: Object,
            default: () => ({})
        },
        filters: {
            type: [Object, Array],
            default: () => [
                {
                    prop: ['title', 'subtitle'],
                    value: ''
                }
            ]
        }
    },
    data () {
        return {
            showModal: false,
            pendingTheme: null,
            pendingAction: null
        };
    },
    computed: {
        modalConfig () {
            if (!this.pendingAction) {
                return {};
            }

            return {
                delete: {
                    title: 'Delete Theme',
                    confirmButtonConfig: {
                        type: 'danger',
                        label: 'Delete Theme'
                    }
                },
                duplicate: {
                    title: 'Duplicate Theme',
                    confirmButtonConfig: {
                        label: 'Duplicate Theme'
                    }
                }
            }[this.pendingAction];
        },
        formattedApps () {
            const { apps, pendingTheme } = this;
            if (!apps) {
                return [];
            }

            return apps.filter((app) => app.platform === pendingTheme.app.platform);
        },
        formattedThemes () {
            const { loading, themes, disabled, apps } = this;
            if (loading && !themes.length) {
                return Array(6).fill({ disabled: true, loading: true });
            }

            return themes.map((theme) => {
                let app = {};
                const { allowActions = {}, tags, buildingBlocks, appId, displayTags, ...rest } = theme;

                if (apps && apps.length) {
                    app = apps.find(({ id }) => id === appId) || {};
                }
                const swatch = this.buildSwatch(buildingBlocks);

                return {
                    ...rest,
                    app: {
                        id: appId,
                        displayName: app.displayName || app.name,
                        platform: (app && app.platform) || '',
                        faviconB64: app.faviconB64
                    },
                    allowActions: {
                        duplicate: this.allowActions.duplicate || allowActions.duplicate,
                        delete:
                            (this.allowActions.delete || allowActions.delete) && !tags.toLowerCase().includes('default')
                    },
                    buildingBlocks,
                    title: DOMPurify.sanitize(theme.name),
                    subtitle: `Created By: ${theme.createdByUser && theme.createdByUser.username}`,
                    disabled: loading || theme.loading || disabled,
                    loading: loading || theme.loading,
                    swatch,
                    tags: displayTags || []
                };
            });
        }
    },
    methods: {
        buildSwatch (theme) {
            const container = get(theme, 'container.background', '#FFFFFF');
            const backdrop = get(theme, 'backdrop.background', '#FFFFFF');
            const primaryButton = get(theme, 'primaryButton.background', '#FFFFFF');
            const secondaryButton = get(theme, 'secondaryButton.background', '#FFFFFF');
            const title = get(theme, 'title.fontColor', '#FFFFFF');

            return [container, backdrop, primaryButton, secondaryButton, title];
        },
        resetPendingActionState () {
            this.pendingAction = null;
            this.pendingTheme = null;
            this.showModal = false;
        },
        promptConfirmDelete (theme) {
            this.pendingAction = 'delete';
            this.pendingTheme = theme;
            this.showModal = true;
        },
        promptConfirmDuplicate (theme) {
            const { app, archive, attributes, buildingBlocks, name } = theme;

            this.pendingAction = 'duplicate';
            this.pendingTheme = {
                archive,
                attributes,
                buildingBlocks,
                tags: 'custom',
                name: `Copy of ${name}`,
                originalName: `Copy of ${name}`,
                app
            };

            this.showModal = true;
        },
        confirmPendingAction () {
            return {
                delete: this.deleteTheme,
                duplicate: this.duplicateTheme
            }[this.pendingAction]();
        },
        selectTheme (selected) {
            const themeToEmit = this.themes.find((theme) => theme.id === selected.id);
            /**
             * Emitted when user selects a theme
             *
             * @event select-theme
             * @property {Event} $event - DOM Event
             */
            this.$emit('select-theme', themeToEmit);
        },
        deleteTheme () {
            /**
             * Emitted when user confirms delete modal action
             *
             * @event delete-theme
             * @property {Event} $event - DOM Event
             */
            this.$emit('delete-theme', this.pendingTheme);
            this.resetPendingActionState();
        },
        duplicateTheme () {
            /**
             * Emitted when user duplicates a theme
             *
             * @event duplicate-theme
             * @property {Event} $event - DOM Event
             */
            const { archive, attributes, buildingBlocks, tags = 'custom', name, originalName, app } = this.pendingTheme;

            const newTheme = {
                archive,
                attributes,
                buildingBlocks,
                tags,
                name: name || originalName,
                appId: app.id
            };

            this.$emit('duplicate-theme', newTheme);
            this.resetPendingActionState();
        },
        onVisibleItemsChange (visibleItemsChange) {
            this.$emit('visible-items-change', visibleItemsChange);
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-theme-grid) {
    min-width: 310px;

    .pendo-card__body {
        padding: 0;
        position: relative;
    }

    @include element(app) {
        display: grid;
        grid-gap: 8px;
        grid-auto-flow: column;
        grid-template-columns: 16px 1fr;
        align-self: end;
        align-items: center;
    }

    @include element(app-name) {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.5px;
    }

    @include element(actions) {
        grid-column: span 3;
        grid-row: 1;
        display: grid;
        grid-auto-flow: column;
        justify-self: end;
        padding: 0 8px;
        grid-gap: 8px;

        .pendo-icon + .pendo-icon {
            margin-left: 0;
        }
    }

    @include element(edit-theme) {
        grid-column: 2;
        grid-row: 2;
        z-index: 1;
    }

    @include element((duplicate-theme, delete-theme)) {
        svg {
            transition: stroke 200ms;
        }

        &:hover {
            cursor: pointer;

            svg {
                stroke: $color-white;
            }
        }

        .pendo-icon {
            display: grid;
            justify-content: center;
        }
    }

    .pendo-chooser-grid__items {
        justify-content: start;
    }

    .pendo-chooser-grid__item {
        .pendo-media-card__overlay-actions {
            display: grid;
            grid-template-columns: 36px 1fr 36px;
            grid-template-rows: 36px 1fr 36px;
            align-content: center;
            justify-items: center;
        }

        .pendo-media-card__content {
            height: auto;
            width: auto;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: $color-gray-10;
            display: grid;
            grid-template-rows: 1fr 36px;
            grid-gap: 16px;
            padding: 16px;
        }

        .pendo-media-card__body {
            grid-template-rows: minmax(23px, auto) minmax(18px, auto);
        }

        .pendo-media-card__subtitle {
            > div {
                display: grid;
                grid-gap: 6px;
            }

            .pendo-theme-grid__created-by,
            .pendo-theme-grid__last-updated-by {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

@include block(pendo-theme-grid-modal) {
    display: grid;
    grid-gap: 8px;
}
</style>
