<template>
    <div class="pendo-guide-category__inline-multiselect">
        <multiselect-cell
            :row="row"
            :value="selectedGuideCategory"
            :options="options"
            :loading="loading"
            min-menu-width="250"
            label-key="name"
            group-options-key="items"
            group-label-key="name"
            value-key="id"
            @select="$emit('select', $event)">
            <template #trigger>
                <div class="pendo-guide-category__trigger">
                    {{ selectedGuideCategory.name }}
                </div>
            </template>
            <template
                v-if="emptyOption"
                #stickyOption="{ select }">
                <div
                    :class="[
                        'pendo-multiselect__option',
                        'pendo-guide-category__sticky-option',
                        {
                            'is-selected': selectedGuideCategory.name === emptyOption.name
                        }
                    ]"
                    @click="select(emptyOption)">
                    {{ emptyOption.name }}
                </div>
            </template>
            <template #option="{ option }">
                <div class="pendo-guide-category__option">
                    {{ option.name }}
                </div>
            </template>
        </multiselect-cell>
    </div>
</template>

<script>
import MultiselectCell from '@/components/table/cell-types/multiselect-cell';

export default {
    name: 'PendoGuideCategoryInlineMultiselect',
    components: {
        MultiselectCell
    },
    props: {
        /**
         * the entity/item on which the guide category lives
         */
        row: {
            type: Object,
            required: true
        },
        /**
         * list of options
         */
        options: {
            type: Array,
            required: true
        },
        /**
         * flag for managing loading state
         */
        loading: {
            type: Boolean,
            default: false
        },
        /**
         * the selected guide category
         */
        selectedGuideCategory: {
            type: Object,
            default: null
        },
        /**
         * option used to populate the sticky-option at the
         * top of the list
         */
        emptyOption: {
            type: Object,
            default: null
        }
    }
};
</script>

<style lang="scss">
@include block(pendo-guide-category) {
    @include element(inline-multiselect) {
        .pendo-table__group {
            cursor: pointer;
        }
    }

    @include element(trigger) {
        @include ellipsis;
    }

    @include element(sticky-option) {
        display: flex;
        align-items: center;
        height: 36px;
        border-bottom: 1px solid $color-gray-30;

        &:hover {
            background-color: $color-gray-20;
        }

        @include is(selected) {
            background-color: $color-gray-30;
        }
    }

    @include element(option) {
        display: grid;
        align-items: center;
        justify-content: start;
        grid-auto-flow: column;
        height: 100%;
        grid-gap: 8px;
    }

    @include element(label) {
        @include ellipsis;
    }
}

@include block(pendo-multiselect) {
    @include element(trigger) {
        @include modifier(mini) {
            .pendo-multiselect__placeholder,
            .pendo-multiselect__selected {
                font-size: 14px;
            }
        }
    }
}
</style>
