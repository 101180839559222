<template>
    <pendo-multiselect
        class="keyword-select"
        :style="{ '--min-width': `${minWidth}px` }"
        :value="value"
        :options="[]"
        multiple
        taggable
        :max="max"
        :close-on-select="false"
        :placeholder="placeholder"
        :append-to-body="false"
        :is-valid-new-option="isValidNewOption"
        @input="onInput"
        @tag="onTag">
        <template #suffix>
            &nbsp;
        </template>
    </pendo-multiselect>
</template>

<script>
import PendoMultiselect from '@/components/multiselect/pendo-multiselect';

export default {
    name: 'PendoKeywordSelect',
    components: {
        PendoMultiselect
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Type keywords followed by ⏎ Enter'
        },
        max: {
            type: Number,
            default: 0
        },
        isValidNewOption: {
            type: Function,
            default: () => true
        },
        minWidth: {
            type: Number,
            default: 237
        }
    },
    emits: ['input'],
    methods: {
        onInput (value) {
            this.$emit('input', value);
        },
        onTag (text) {
            this.$emit('input', [...this.value, text]);
        }
    }
};
</script>

<style lang="scss" scoped>
.keyword-select {
    ::v-deep .pendo-picker-popper__content-wrapper {
        display: none !important;
    }

    ::v-deep .pendo-multiselect__placeholder {
        min-width: var(--min-width);
    }

    ::v-deep .pendo-multiselect__input {
        min-width: var(--min-width);
    }

    ::v-deep .pendo-multiselect__trigger {
        padding: 0 8px;
    }
}
</style>
