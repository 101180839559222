<template>
    <div class="pendo-column-chooser">
        <pendo-modal
            :title="title"
            height="auto"
            max-height="85%"
            :append-to-body="appendToBody"
            :visible.sync="isVisible"
            :confirm-button-config="confirmButtonConfig"
            :cancel-button-config="cancelButtonConfig"
            @open="initialize"
            @confirm="handleConfirm"
            @cancel="handleCancel"
            @closed="onModalClosed">
            <div
                v-if="!!$slots.before"
                class="pendo-column-chooser__before">
                <slot name="before" />
            </div>
            <div class="pendo-column-chooser__description">
                {{ description }}
            </div>
            <pendo-column-reorder
                v-if="renderColumnReorder"
                :value="model"
                :available-columns="availableColumns"
                :disable-first="disableFirst"
                :disabled="disabled"
                :value-key="valueKey"
                :label-key="labelKey"
                :placeholder="placeholder"
                :add-button-label="addButtonLabel"
                :all-selected-text="allSelectedText"
                @columns-change="updateColumns">
                <template
                    v-if="$slots.multiselect"
                    #multiselect="props">
                    <slot
                        name="multiselect"
                        v-bind="props" />
                </template>
            </pendo-column-reorder>
            <template
                v-if="$slots.footer"
                #footer>
                <slot
                    :model="model"
                    :confirm="handleConfirm"
                    :cancel="handleCancel"
                    name="footer" />
            </template>
        </pendo-modal>
    </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import PendoModal from '@/components/modal/pendo-modal';
import PendoColumnReorder from '@/components/table/column-reorder/pendo-column-reorder';

export default {
    name: 'PendoColumnChooser',
    components: {
        PendoModal,
        PendoColumnReorder
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Array,
            default: () => []
        },
        availableColumns: {
            type: Array,
            default: () => []
        },
        disableFirst: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        valueKey: {
            type: String,
            default: 'prop'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
        placeholder: {
            type: String
        },
        title: {
            type: String,
            default: 'Manage Columns'
        },
        description: {
            type: String,
            default: 'Add, reorder or remove columns to customize your table.'
        },
        addButtonLabel: {
            type: String
        },
        appendToBody: {
            type: Boolean,
            default: true
        },
        confirmButtonConfig: {
            type: Object,
            default: () => ({ label: 'Save' })
        },
        cancelButtonConfig: {
            type: Object,
            default: () => ({ label: 'Cancel' })
        },
        allSelectedText: {
            type: String
        }
    },
    data () {
        return {
            model: [],
            renderColumnReorder: false
        };
    },
    computed: {
        isVisible: {
            get () {
                return this.visible;
            },
            set (val) {
                this.$emit('update:visible', val);
                if (val) {
                    this.$emit('open');
                } else {
                    this.$emit('close');
                }
            }
        }
    },
    methods: {
        onModalClosed () {
            this.renderColumnReorder = false;
        },
        initialize () {
            this.model = [...new Set([...this.columns])];
            // wait for modal to render before trying to render column chooser
            // to prevent initialization issues with draggable behavior
            setTimeout(() => {
                this.renderColumnReorder = true;
                this.updateScroll();
            }, 0);
        },
        handleConfirm () {
            this.$emit(
                'confirm',
                this.model.filter((column) => Object.keys(column).length)
            );
            this.isVisible = false;
        },
        handleCancel () {
            this.$emit('cancel');
            this.isVisible = false;
        },
        updateScroll () {
            this.$nextTick(() => {
                const modalBody = document.querySelector('.pendo-modal__body');
                if (modalBody) {
                    modalBody.scrollTop = modalBody.scrollHeight - modalBody.clientHeight;
                }
            });
        },
        updateColumns (value) {
            this.model = value;
        }
    }
};
</script>
<style lang="scss">
@include block(pendo-column-chooser) {
    @include element((before, description)) {
        margin-bottom: 16px;
    }
}
</style>
